<h2 mat-dialog-title>
    Site Keys
    <button
        style="float: right"
        (click)="toggleEditRule()"
        *ngIf="isSupport"
        class="btn btn-light btn-sm ripple text-capitalize radius-0"
    >
        Edit
        <i class="fa fa-edit ml-1"></i>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup">
        <fieldset>
            <div class="row">
                <div class="col-12 col-md-6" *ngFor="let key of formControlKeyParams">
                    <section class="form-group">
                        <gl-input [label]="key | titlecase" [readonly]="readonly" [formControlName]="key"> </gl-input>
                    </section>
                </div>
            </div>
        </fieldset>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between" *ngIf="isSupport">
    <button
        type="button"
        class="btn btn-outline-secondary ripple py-1 text-left float-left text-capitalize"
        mat-dialog-close
    >
        Cancel
    </button>

    <button
        type="button"
        class="btn btn-success ripple text-left text-capitalize py-1"
        [disabled]="readonly"
        (click)="submit($event)"
    >
        Submit
    </button>
</mat-dialog-actions>

