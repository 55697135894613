import { get } from 'lodash';

export const sampleObject = {
    prevDaySales: 0,
    activeStaffCount: 0,
    prevDayTransactionCt: 0,
    siteName: '',
    sitePhone: '',
    siteAddress: {
        addressLine: [''],
        siteCity: '',
        siteState: '',
        siteZipCode: '',
        siteCountry: '',
    },
};

export function getLocationData(obj = sampleObject) {
    let hasCityInfo: boolean = false;
    const objSample = Object.assign({}, sampleObject, {
        siteName: get(obj, 'altName', get(obj, 'name', '')),
        sitePhone: get(obj, 'phone', ''),
        siteAddress: {
            addressLine: [get(obj, 'address', '')],
            siteCity: get(obj, 'city', ''),
            siteState: get(obj, 'state', ''),
            siteZipCode: get(obj, 'zip', ''),
            siteCountry: get(obj, 'country', ''),
        },
    });

    const address = ['siteCity', 'siteState', 'siteZipCode', 'siteCountry'].forEach((k) => {
        if (!hasCityInfo) hasCityInfo = !!objSample.siteAddress[k];
    });
    objSample['hasCityInfo'] = hasCityInfo;
    return objSample;
}
