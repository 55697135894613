/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortableDirective } from './sortable.directive';
import { SortableComponent } from './sortable.component';
import { SortableService } from './sortable.service';

@NgModule({
    declarations: [SortableDirective, SortableComponent],
    imports: [CommonModule],
    exports: [SortableDirective, SortableComponent],
    providers: [SortableService],
    entryComponents: [],
})
export class SortableModule {
    constructor() {}
}
