import { Component, EventEmitter } from '@angular/core';
import { IAppDrawerComponent } from './components/app-drawer/app-drawer.component';

@Component({
    selector: 'site-header-placeholder',
    template: ``,
})
export class EmptyComponent implements IAppDrawerComponent {
    onModuleClicked: EventEmitter<any> = new EventEmitter<any>();
}
