import { AbstractControl } from '@angular/forms';
import { findIndex, each } from 'lodash';

export function emailFormatValidator(control: AbstractControl): any {
    if (control.value) {
        const results: any[] = [];
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
        each(
            control.value
                .replace(/\/n/g, ' ')
                .trim()
                .split(/[\s,]+/),
            (email: string) => {
                results.push({ isPassed: pattern.test(email.toLowerCase()) });
            }
        );
        return findIndex(results, (o) => o.isPassed === false) < 0 ? null : { emailFormat: true };
    }
}
