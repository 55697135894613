import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { get } from 'lodash';
import { Users } from './users_response';
import { UserSuscriptionResponse } from './user_subscription_response';
import { WelcomeResetResponse } from './welcome_reset_credentials';
import { UpdateUserResponse } from './update_user_response';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {}

    public getUsers = (aggregate?, startRow: number = 0, rowCount: number = 60): Observable<any> => {
        const options = {};

        if (aggregate && aggregate.length)
            Object.assign(options, {
                params: {
                    aggregate: JSON.stringify(aggregate),
                },
            });

        return this.http.get<Array<Users>>(`${environment.API_URL}/users/${startRow}/${rowCount}`, options);
    };

    public getSubscriptions(user_id?: string): Observable<any> {
        const url = [`${environment.API_URL}/user/subscriptions`, user_id].filter((a) => a).join('/');
        return this.http.get(url);
    }

    public postSubscriptions(user_id: string, params): Observable<Array<UserSuscriptionResponse>> {
        if (!user_id) return of([]);
        return this.http.post<Array<UserSuscriptionResponse>>(
            `${environment.API_URL}/user/subscriptions/${user_id}`,
            params
        );
    }

    public getUsersByEmail(emailAddress: string): Observable<any> {
        return this.http.get(`${environment.API_URL}/users/email?email=${encodeURIComponent(emailAddress)}`);
    }

    public getUser(user_id: string) {
        return this.http.get(`${environment.API_URL}/user/${user_id}`).pipe(
            map((d) => d || {}),
            mergeMap((d: any) => {
                d.confirmEmail = get(d, 'email', '');
                return of(d);
            })
        );
    }

    public addNewUser(params) {
        params.domain = 'user';
        return this.http.post(`${environment.API_URL}/user`, params).pipe(map((d) => d || {}));
    }

    public updateUser(params) {
        // TODO: CEN-857
        const user_id = get(params, '_id', null);
        params.domain = 'user';

        return this.http
            .put<UpdateUserResponse>(`${environment.API_URL}/user${user_id ? '/' + user_id : ''}`, params)
            .pipe(map((d) => d || {}));
    }

    public passwordReset(user_id): Observable<string> {
        return this.http.get<string>(`${environment.API_URL}/user/passwordReset/${user_id}`);
    }

    public welcomeEmailReset(site_id: string, user_id: string, resetpass: boolean = true) {
        return this.http.get<WelcomeResetResponse>(
            `${environment.API_URL}/user/welcomeReset/${site_id}/${user_id}?resetpass=${resetpass}`
        );
    }
}

