<div class="editable-input" style="position: relative">
    <div class="row">
        <div class="col-12 justify-content-center">
            <gl-timepicker id="timeEntered" [(ngModel)]="params" [ngModelOptions]="{ standalone: true }"></gl-timepicker>
        </div>
    </div>
    <div class="editable-buttons">
        <button type="submit" class="editable-submit py-1" (click)="submit($event)">OK</button>
        <button type="button" class="editable-cancel py-1" (click)="close($event)">Cancel</button>
    </div>
</div>

