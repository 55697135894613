import { ComponentFactoryResolver, ComponentRef, Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ProgressBarComponent } from './progress-bar.component';
import { Subscription } from 'rxjs';
import { FileUploadingEmitter, UploadService } from '../services/upload.service';

@Directive({
    selector: '[dzProgressBar]',
})
export class DzProgressBarDirective implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    private componentRef!: ComponentRef<ProgressBarComponent> | null;

    constructor(
        private uploadService: UploadService,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            FileUploadingEmitter.Instance.onUploading.subscribe(({ percent, total_files }) => {
                this.uploadService.onUploading.emit({ percent, total_files });
                if (!this.componentRef) {
                    this.componentRef = this.showProgressBar();
                    const instance = this.componentRef.instance;
                    instance.setProgressPercent(percent);
                    instance.files_count = total_files;
                    const next = instance.onComplete.subscribe((d) => setTimeout((d) => this.destroy(next), 500));
                } else {
                    this.componentRef.instance.setProgressPercent(percent || 0);
                    this.componentRef.instance.files_count = total_files || 0;
                }
            })
        );
        this.subscriptions.add(
            FileUploadingEmitter.Instance.onError.subscribe((d) => {
                this.uploadService.onError.emit(d);
                this.componentRef?.instance.setProgressErrorStatus();
            })
        );
        this.subscriptions.add(
            FileUploadingEmitter.Instance.onComplete.subscribe((d) => {
                this.uploadService.onComplete.emit(d);
                this.componentRef = null;
            })
        );
    }

    private destroy(subscription) {
        if (this.componentRef) this.componentRef.destroy();
        this.componentRef = null;
        subscription.unsubscribe();
    }

    private showProgressBar() {
        const factory = this.componentFactoryResolver.resolveComponentFactory(ProgressBarComponent);
        this.viewContainer.clear();
        return this.viewContainer.createComponent(factory);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
