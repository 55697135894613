import { get } from 'lodash';
import { FormControl } from '@angular/forms';
import { EventEmitter } from '@angular/core';

export function formValidate(formGroup) {
    if (formGroup.invalid) {
        Object.keys(formGroup.controls).forEach((k) => {
            if (formGroup.controls[k].value) return;
            markAsDirty(formGroup.controls[k]);
        });
        return false;
    }
    return true;
}

export function markAsDirty(controls) {
    controls.markAsDirty({ onlySelf: true });
    controls.markAsTouched({ onlySelf: true });
    controls.updateValueAndValidity({
        emitEvent: true,
    });
}

export function getFormGroupRawValue(formGroup, key) {
    return get(formGroup.getRawValue(), key);
}

export function buildFormGroupController(formGroup, params) {
    if (!params) return;
    Object.keys(params).forEach((k) => {
        const control = formGroup.controls[k];
        if (control) control.setValue(params[k]);
        else formGroup.addControl(k, new FormControl(params[k]));
    });
}

class FormValidationEmitter {
    private static _instance: FormValidationEmitter;
    public detectChanges: EventEmitter<any> = new EventEmitter();

    constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

export function FormValidityCheck(showDirty: boolean = false) {
    const _form = FormValidationEmitter.Instance.detectChanges;
    setTimeout((d) => _form.emit(showDirty));
    return _form;
}
