import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

export type typeOptions = {
    headers?: HttpHeaders | { [header: string]: string | string[] };
    observe?: 'body';
    params?: HttpParams | { [param: string]: string | string[] };
    reportProgress?: boolean;
    responseType?: 'arraybuffer';
    withCredentials?: boolean;
};

export abstract class UploadLoader {
    abstract getHttpRequest(formData: any, options: typeOptions): Observable<any> | HttpRequest<any>;
}
/**
 * This loader is just a placeholder that does nothing, in case you don't need a loader at all
 */
@Injectable()
export class UploadFakeLoader extends UploadLoader {
    getHttpRequest(formData: any, options: typeOptions): Observable<any> {
        return of({});
    }
}
