import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../app.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { get } from 'lodash';
import { AuthService, AUTH_SERVICE } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { PingResponse } from './ping_response';

@Injectable()
export class LoggedInGuard implements CanActivate {
    private queryParams: any = {};
    private navigationExtras: NavigationExtras = {
        queryParams: this.queryParams,
        queryParamsHandling: 'merge',
    };

    constructor(
        private router: Router,
        private http: HttpClient,
        private appState: AppState,
        @Inject(AUTH_SERVICE) private authService: AuthService
    ) {}

    /**
     * On Initial route to application
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {any}
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.queryParams = route.queryParams;
        const query: string[] = [];
        Object.keys(this.queryParams).forEach((k) => {
            if (['username', 'password'].includes(k)) query.push(`${k}=${this.queryParams[k]}`);
        });

        return this.http
            .get<any>(`${environment.API_URL}/ping`, {
                params: {
                    suppress: 'notification',
                },
            })
            .pipe(
                catchError((e) => {
                    this.router.navigate(['/signon'], this.navigationExtras);
                    return throwError(e);
                }),
                map((d: PingResponse) => {
                    this.appState.set('USERNAME', get(d, 'username', ''));
                    const ispartner = d.ispartner || false;
                    const issupport = d.issupport || ispartner;
                    const issubscriber = d.issubscriber || issupport;

                    this.appState.set('issubscriber', issubscriber);

                    if (d.hasOwnProperty('partner')) this.appState.set('PARTNER', get(d, 'partner', ''));
                    if (d.hasOwnProperty('access_level')) this.authService.AccessLevel = d.access_level;

                    return true;
                })
            );
    }
}
