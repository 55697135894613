import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    TemplateRef,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgContentInjectionComponent } from './ng-content-injection.component';

export type Content<T> = string | TemplateRef<T> | Type<T> | HTMLElement;

export class ngContentInjector {
    OnDestroyed: EventEmitter<any> = new EventEmitter<any>();

    private static _instance: ngContentInjector;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Directive({
    selector: '[ngcontent-injector]',
})
export class NgContentInjectionDirective<T> implements OnDestroy {
    @HostBinding('style.z-index') zIndex: number = 10;

    @Input('ngcontent-injector') ngContentInjector!: Content<T>;
    @Input('yOffset') yOffset = 0;
    @Input('xOffset') xOffset = 0;

    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClose: EventEmitter<MouseEvent> = new EventEmitter();

    public showDropdown: boolean = false;
    public fired: boolean = false;

    private subscriptions: Subscription = new Subscription();

    private ComponentRef!: ComponentRef<any>;

    constructor(
        private renderer: Renderer2,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    @HostListener('click', ['$event.target'])
    public onClickElement(e: HTMLElement) {
        if (this.fired) {
            if (this.showDropdown) {
                this.destroyComponentRef(e);
                return;
            }
            return;
        }

        this.fired = true;
        this.showDropdown = true;

        const factory = this.componentFactoryResolver.resolveComponentFactory(NgContentInjectionComponent);
        const componentRef = this.viewContainer.createComponent(factory);
        const element = componentRef.location.nativeElement;
        this.renderer.appendChild(document.body, element);
        this.ComponentRef = componentRef;

        if (this.ngContentInjector) componentRef.instance.resolveNgContent(this.ngContentInjector);

        componentRef.instance.buildMenu(e, {
            y: Number(this.yOffset),
            x: Number(this.xOffset),
        });

        this.subscriptions.add(
            ngContentInjector.Instance.OnDestroyed.subscribe((d) => {
                this.destroyComponentRef(e);
            })
        );
        this.subscriptions.add(
            componentRef.instance.onClose.subscribe((d) => {
                this.destroyComponentRef(e);
            })
        );
    }

    private destroyComponentRef(e?) {
        this.showDropdown = false;
        this.fired = false;
        if (this.ComponentRef) this.ComponentRef.destroy();
        this.ComponentRef = undefined as any;
    }

    ngOnDestroy(): void {
        this.destroyComponentRef();
    }
}
