import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationsComponent } from './integrations.component';
import { NewSiteRootEntryResolver } from '../location-data/root.entry.resolver';
import { ReactiveFormsModule } from '@angular/forms';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { PointyModule } from './modals/pointy/pointy.module';
import { ToggleButtonModule } from '../../components/toggleButton/toggle-button.module';
import { IntegrationService } from './services/integration.service';
import { SubmitModule } from 'src/app/components/submit/submit.module';
import { CheckboxModule } from 'src/app/components/checkbox/checkbox.module';
import { MdDatepickerModule } from 'src/app/components/md-daterange/md.datepicker.module';
import { PosSystemModules } from 'src/app/components/pos-system/posSystem.modules';
import { XeditorModule } from 'src/app/components/xEditor/xeditor.module';

@NgModule({
    declarations: [IntegrationsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        CheckboxModule,
        PosSystemModules,
        InputModule,
        ToggleButtonModule,
        XeditorModule,
        SubmitModule,
        MdDatepickerModule,
        PointyModule,
    ],
    exports: [IntegrationsComponent],
    providers: [NewSiteRootEntryResolver, IntegrationService],
    entryComponents: [IntegrationsComponent],
})
export class IntegrationsModule {
    static entry = IntegrationsComponent;
}
