import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { noop } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'info-aggrid',
    template: `
        <a class="cursor-pointer text-warning ripple" (click)="openModal($event)">
            <i class="fa fa-info" #info></i>
        </a>
    `,
})
export class AgInfoModalComponent implements AgRendererComponent {
    public bsModalRef!: BsModalRef;
    public filename: any = '';

    private params: any;
    private gridApi: any;
    private modalRef: any;
    private modalRendererFramework: any;
    private modalRendererFrameworkConfigs: any = {
        ignoreBackdropClick: true,
    };

    private broadcast = noop;
    private subscriptions: Subscription = new Subscription();

    constructor(private modalService: BsModalService) {}

    openModal(e) {
        this.modalRef = this.modalService.show(
            this.modalRendererFramework,
            Object.assign({}, this.modalRendererFrameworkConfigs, {
                initialState: {
                    params: this.params,
                    gridApi: this.gridApi,
                },
            })
        );
    }

    decline(): void {
        this.modalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh(params: any): any {
        return true;
    }

    agInit(params: any): void {
        this.params = params.data || {};
        this.gridApi = params.api;
        if (params.hasOwnProperty('modalRendererFramework')) this.modalRendererFramework = params.modalRendererFramework;
        if (params.hasOwnProperty('modalRendererFrameworkConfigs'))
            Object.assign(this.modalRendererFrameworkConfigs, params.modalRendererFrameworkConfigs);
    }
}
