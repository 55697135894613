export const SIDEBAR_ROUTES = [
    {
        path: '/accounts',
        name: 'Accounts',
        showui: false,
        permission: ['UI.ACCOUNTS'],
        children: [
            {
                path: '/invoices',
                name: 'Billing and Invoices',
                permission: ['UI.ACCOUNTS.INVOICES', 'API.BILLING.INVOICE'],
            },
            {
                path: '/csv-files',
                name: 'csvFiles',
                permission: ['UI.FILES.CSV'],
                children: [
                    {
                        path: '/upload',
                        name: 'uploadCSVFiles',
                        permission: ['UI.BILLING.UPLOAD', 'API.BILLING.INVOICE'],
                        showui: false,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        path: '/accounts/invoices',
        name: 'Billing and Invoices',
        queryParamsHandling: 'merge',
        faIcon: 'fa-receipt',
        open: true,
        sitemap: false,
        permission: ['UI.ACCOUNTS.INVOICES', 'API.BILLING.INVOICE'],
    },
    {
        path: '/accounts/csv-files',
        name: 'CSV Files',
        faIcon: 'fa-file-archive',
        queryParamsHandling: 'merge',
        routerLinkActiveOptions: { exact: false },
        permission: ['UI.FILES.CSV'],
        sitemap: false,
        children: [
            {
                path: '/accounts/csv-files/upload',
                name: 'Upload CSV File',
                faIcon: 'fa-file-upload',
                queryParamsHandling: 'merge',
                permission: ['UI.BILLING.UPLOAD', 'API.BILLING.INVOICE'],
                showui: false,
                children: [],
            },
        ],
    },
];
