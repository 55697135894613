import { Injectable } from '@angular/core';
import { AuthService, AUTH_SERVICE } from '../auth/auth.service';

@Injectable()
export class AccessLevelsRulesOverride extends AuthService {
    public set AccessLevel(access_level: any | any[]) {
        super['AccessLevel'] = Array.isArray(access_level) ? access_level : [access_level];
    }
}

export const AuthServiceProvider: any = [
    AccessLevelsRulesOverride,
    {
        provide: AUTH_SERVICE,
        useClass: AccessLevelsRulesOverride,
        deps: [],
    },
];
