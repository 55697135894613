<div class="apps-drawer">
    <ng-template #pageitems let-item="item" let-index="index" let-isLast="isLast">
        <button
            class="flex-item draggable"
            [attr.aria-disabled]="item?.disabled"
            [attr.data-name]="item?.translate"
            [ngClass]="{ active: item?.active }"
            (click)="moduleClicked($event, item)"
        >
            <div class="icon">
                <i class="fa" [ngClass]="item.faIcon"></i>
            </div>
            <div class="description">{{ item.name }}</div>
        </button>
    </ng-template>

    <sortable [enabled]="draggability" (onSortCompleted)="onSortComplete($event)">
        <div class="app-header">
            Heartland Applications
        </div>

        <div class="flex-container wrap active-components" sortable=".draggable" data-container="active" *ngIf="!loading">
            <ng-container *ngFor="let item of APPLICATIONS; let index = index; last as isLast">
                <ng-container
                    *ngTemplateOutlet="pageitems; context: { item: item, isLast: isLast, index: index }"
                ></ng-container>
            </ng-container>
        </div>
        <div class="flex-container wrap active-components" *ngIf="loading">
            <div class="flex-item placeholder"></div>
            <div class="flex-item placeholder"></div>
            <div class="flex-item placeholder"></div>
        </div>
    </sortable>
</div>

