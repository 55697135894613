import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RangePipe } from 'src/app/pipes/range.pipe';

@Component({
    selector: 'gl-timepicker[formControlName],gl-timepicker[formControl],gl-timepicker[ngModel]',
    styleUrls: ['./mtr-datepicker.scss', './timepicker.component.scss'],
    templateUrl: './timepicker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true,
        },
    ],
})
export class TimepickerComponent implements OnInit, ControlValueAccessor {
    public _model: string = '';
    public _disabled: boolean = false;
    public _custom_id: number = Math.ceil(new Date().valueOf() + Math.random());
    public am_id = `amtype_${this._custom_id}`;
    public pm_id = `pmtype_${this._custom_id}`;

    public hrsModel: string = '12';
    public $hrsModel: string = '12';
    public minModel: string = '00';
    public $minModel: string = '00';
    public ampm: string = 'AM';

    public invalid: boolean = true;
    public valid: boolean = false;
    public dirty: boolean = false;

    @ViewChild('inputSliderHrs', { static: true }) inputSliderHrs!: ElementRef;
    @ViewChild('mtContentHrs', { static: true }) mtContentHrs!: ElementRef;
    @ViewChild('inputHrs', { static: true }) inputHrs!: ElementRef;
    @ViewChild('inputSliderMin', { static: true }) inputSliderMin!: ElementRef;
    @ViewChild('mtContentMin', { static: true }) mtContentMin!: ElementRef;
    @ViewChild('inputMin', { static: true }) inputMin!: ElementRef;

    @ViewChild('radioAm', { static: true }) radioAm!: ElementRef;
    @ViewChild('radioPm', { static: true }) radioPm!: ElementRef;

    @Input() required: boolean = false;
    @Input() redraw: any = false;
    @Input() placeholder: string = '';

    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    @Input() set ngValue(v: any) {
        this._model = v;
    }

    @Input() set disabled(v: boolean) {
        this._disabled = v !== false;
    }

    private arrowTimeout: any[] = [];
    private defaultHrs: string[] = [];
    private defaultMin: string[] = [];

    private focusedInput!: string;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        const range = new RangePipe();
        this.defaultHrs = range.transform(12).map((f) => `0${f}`.slice(-2));
        this.defaultMin = [0].concat(range.transform(59)).map((f) => `0${f}`.slice(-2));
    }

    @HostListener('keydown.tab', ['$event'])
    handleKeyboardEvent(e: KeyboardEvent) {
        e.preventDefault();
        const eName = this.focusedInput === 'inputHrs' ? 'inputMin' : 'inputHrs';
        this.setInputFocus(eName);
    }

    ngOnInit(): void {
        //content
        this.renderer.listen(this.MtContentHrsLayoutElement, 'click', (e) => this.setInputFocus('inputHrs'));
        this.renderer.listen(this.InputHrsLayoutElement, 'blur', (e) => this.setInputBlur('inputHrs'));
        //input
        this.renderer.listen(this.MtContentMinLayoutElement, 'click', (e) => this.setInputFocus('inputMin'));
        this.renderer.listen(this.InputMinLayoutElement, 'blur', (e) => this.setInputBlur('inputMin'));
    }

    onModelChange(eName: string) {
        const model = eName === 'inputHrs' ? this.hrsModel : this.minModel;
        if (eName === 'inputHrs') this.$hrsModel = model;
        else this.$minModel = model;
        this.updateModel();
    }

    onAMPMChange(e, rad) {
        this.focusedInput = rad === 'AM' ? 'radioAm' : 'radioPm';
        this.ampm = e;
        this.updateModel();
    }

    private setInputBlur(eName: string) {
        if (this._disabled) return;

        const e: any = { ...this.getInputElement(eName) };
        let model = `0${eName === 'inputHrs' ? this.hrsModel : this.minModel}`.slice(-2);
        const oldValue = eName === 'inputHrs' ? this.$hrsModel : this.$minModel;

        // Validate the value
        if (this.validateValue(eName, model) === false) {
            model = oldValue;
            e.input.focus();
        }
        if (eName === 'inputHrs') this.hrsModel = model;
        else this.minModel = model;

        this.renderer.removeStyle(e.input, 'display');
        this.onModelChange(eName);
    }

    private setInputFocus(eName: string) {
        if (this._disabled) return;
        const e: any = { ...this.getInputElement(eName) };
        this.focusedInput = eName;
        this.renderer.setStyle(e.input, 'display', 'block');
        e.input.focus();
    }

    minClick(e, dir: string) {
        const indexInArray = this.getIndexArray('inputMin', dir);
        this.minModel = this.defaultMin[indexInArray];
        this.onModelChange('inputMin');
        this.updateModel();
    }

    private getIndexArray(obj, dir) {
        this.addClickClassEvent(obj);
        if (this.arrowTimeout[obj]) window.clearTimeout(this.arrowTimeout[obj]);
        this.arrowTimeout[obj] = setTimeout(() => this.removeClickClassEvent(obj), 500);

        const _m = obj === 'inputHrs' ? this.hrsModel : this.minModel;
        const _d = obj === 'inputHrs' ? this.defaultHrs : this.defaultMin;

        let indexInArray = _d.indexOf(_m);
        if (dir === 'up') {
            indexInArray++;
            if (indexInArray >= _d.length) indexInArray = 0;
        } else {
            indexInArray--;
            if (indexInArray < 0) indexInArray = _d.length - 1;
        }

        return indexInArray;
    }

    hrsClick(e, dir: string) {
        if (this._disabled) return;
        const indexInArray = this.getIndexArray('inputHrs', dir);
        this.hrsModel = this.defaultHrs[indexInArray];
        this.onModelChange('inputHrs');
    }

    private addClickClassEvent(eName) {
        const e: any = { ...this.getInputElement(eName) };

        this.renderer.addClass(e.content, 'mtr-active');
        this.renderer.addClass(e.input, 'arrow-click');
    }

    private removeClickClassEvent(eName) {
        const e: any = { ...this.getInputElement(eName) };
        this.renderer.removeClass(e.content, 'mtr-active');
        this.renderer.removeClass(e.input, 'arrow-click');
    }

    private validateValue(type, value) {
        const defaultValues = type === 'inputHrs' ? this.defaultHrs : this.defaultMin;
        return defaultValues.indexOf(value) > -1 ? true : false;
    }

    private getInputElement(eName: string): any {
        const content = eName === 'inputHrs' ? this.MtContentHrsLayoutElement : this.MtContentMinLayoutElement;
        const input = eName === 'inputHrs' ? this.InputHrsLayoutElement : this.InputMinLayoutElement;
        return {
            content,
            input,
        };
    }

    updateModel() {
        const hrs = this.hrsModel.toString();
        this.ngValue = `${hrs.padStart(2, '0')}:${this.minModel} ${this.ampm.toUpperCase()}`;
        this.change.emit(this.ngValue);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    get ngValue() {
        return this._model;
    }

    get disabled() {
        return this._disabled;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: any): void {
        if (obj && obj !== this.ngValue) {
            this.ngValue = obj;
            const time = this.ngValue.split(':').map((t) => parseFloat(t));
            this.ampm = this.ngValue.trim().slice(-2).toUpperCase();
            this.hrsModel = `0${time[0]}`.slice(-2);
            this.minModel = `0${time[1]}`.slice(-2);
        }
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};

    get LayoutElement(): any {
        return this.elementRef.nativeElement as Element as HTMLElement;
    }

    get InputHrsLayoutElement(): any {
        return this.inputHrs.nativeElement as Element as HTMLElement;
    }

    get InputMinLayoutElement(): any {
        return this.inputMin.nativeElement as Element as HTMLElement;
    }

    get MtContentHrsLayoutElement(): any {
        return this.mtContentHrs.nativeElement as Element as HTMLElement;
    }

    get MtContentMinLayoutElement(): any {
        return this.mtContentMin.nativeElement as Element as HTMLElement;
    }
}
