import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm',
    templateUrl: './confirm.component.html',
})
export class ConfirmComponent {
    public onCancel!: (e: MouseEvent) => {};
    public onConfirm!: (e: MouseEvent) => {};
    public messageString: string = '';
    public confirmString: string = '';
    public comparableString: string = '';
    public headerClass: string = 'bg-info';

    constructor(private modalRef: BsModalRef) {}

    decline(e): void {
        this.onCancel(e);
        this.modalRef.hide();
    }

    confirm(e): void {
        if (this.confirmString.trim() !== this.comparableString.trim()) return;
        this.onConfirm(e);
        this.modalRef.hide();
    }
}
