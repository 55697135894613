import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AppState } from '../../app.service';

@Injectable()
export class PartnerGuard implements CanActivate {
    constructor(private appState: AppState) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.appState.set('PARTNER', 'hrpos');

        return true;
    }
}
