import { EventEmitter } from '@angular/core';

export class EditLocationNotifier {
    private static _instance: EditLocationNotifier;
    public onHide: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}
