import { Observable } from 'rxjs';
import { Http } from '../http.client';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class DropzoneUploaderObservableWorker {

    public workUnit(files: any): Observable<any> {
        const useDefaultSrc: string = files?.useDefaultSrc || '/dropzone/upload';
        const useDefaultTransform: string = files?.useDefaultTransform || '';

        const file: File = files.file;
        const customFormData = files.body;
        const options = Object.assign({}, files.options);

        // add custom form data
        const formData = new FormData();
        for (const key in customFormData) formData.append(key, customFormData[key]);
        formData.append('uploader', file);

        const http = new Http();

        return http.post(useDefaultSrc, formData, options).pipe(
            map((d) => {
                return useDefaultTransform ? eval(`(${decodeURI(useDefaultTransform)})`)(d) : d;
            }),
        );
    }
}
