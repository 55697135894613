import { get } from 'lodash';

export function groupBy(list, keyGetter, prefix = '') {
    const _str = keyGetter.split('.');
    const _def = _str[_str.length - 1];
    if (!Array.isArray(list)) return list;
    return list.reduce((rv, x) => {
        (rv[`${prefix}${get(x, keyGetter, _def)}`] = rv[`${prefix}${get(x, keyGetter, _def)}`] || []).push(x);
        return rv;
    }, {});
}
