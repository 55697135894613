import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KeysComponent } from './keys.component';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { ReactiveFormsModule } from '@angular/forms';

import { EditlocationService } from '../../../modals/editlocation/editlocation.service';
import { MatDialogModule } from '@angular/material/dialog';
import { PermissionBadgeModule } from 'src/app/components/permissionBadge/permission-badge.module';

@NgModule({
    declarations: [KeysComponent],
    imports: [CommonModule, ReactiveFormsModule, InputModule, MatDialogModule, PermissionBadgeModule],
    exports: [KeysComponent, MatDialogModule],
    providers: [EditlocationService],
    entryComponents: [KeysComponent],
})
export class KeysModule {}
