<div class="site-group" *ngIf="sitedata?.name || multiSite">
    <div class="d-flex flex-row h-100 align-items-center align-content-center">
        <div class="site-icon">
            <i class="fa fa-map-marker-alt"></i>
        </div>
        <div class="d-flex align-content-center flex-column">
            <div
                [ngClass]="{ 'flex-column': sitecity.length }"
                class="site-description d-flex align-items-center align-content-center"
            >
                <div class="site_name truncate" [title]="sitedata?.name" [innerHTML]="sitedata?.name || 'Loading ...'"></div>
                <div
                    class="site_city truncate text-capitalize"
                    *ngIf="sitecity.length"
                    [innerHTML]="sitecity.join(', &nbsp;')"
                ></div>
            </div>
            <div class="site_selection" *ngIf="multiSite">
                <span [ngcontent-injector]="siteSelectionGridComponent" [yOffset]="7" [xOffset]="138">Site Selection</span>
            </div>
        </div>
    </div>
</div>

