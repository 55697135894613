import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Timezones } from './timezone_response';

@Injectable()
export class TimezoneService {
    constructor(private http: HttpClient) {}

    getTimezones(): Observable<any> {
        return this.http.get<Array<Timezones>>(`${environment.API_URL}/timezones`);
    }
}

export function getTimezone() {
    return moment.tz.guess();
}
