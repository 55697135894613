<nav class="navbar flex-nowrap p-0">
    <div class="container-fluid primary-header" style="flex-wrap: nowrap">
        <div class="navbar-left-container justify-content-start flex-row d-flex">
            <a class="navbar-brand clearfix">
                <div class="clearfix d-flex align-content-center align-items-center">
                    <div class="brand-logo outline-0 cursor-pointer" routerLink="/"></div>
                    <span class="ml-2 d-none d-sm-block" [innerHTML]="appTitle"></span>
                </div>
            </a>
        </div>
        <ng-content></ng-content>
    </div>
</nav>
