import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';

export interface IAppDrawerComponent {
    onModuleClicked: EventEmitter<any>;
}

@Component({
    selector: 'app-drawer-animate',
    templateUrl: `./app-drawer.component.html`,
})
export class AppDrawerComponent implements OnChanges, OnInit, OnDestroy {
    @Input() on: boolean = false;
    @Output() toggled: EventEmitter<boolean> = new EventEmitter();

    subscriptions: Subscription = new Subscription();
    onTransitionend: EventEmitter<boolean> = new EventEmitter();

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    get LayoutElement() {
        return this.elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.triggerClass();
    }

    triggerClass(status = this.on) {
        this.on = status;
        if (this.on) this.renderer.addClass(this.LayoutElement, 'open');
        else this.renderer.removeClass(this.LayoutElement, `open`);
        this.toggled.emit(this.on);
    }

    animateOut() {
        this.on = false;
        this.renderer.removeClass(this.LayoutElement, `open`);
    }

    ngOnInit(): void {
        const _mouseEvents = fromEvent(document, 'click');

        this.subscriptions.add(
            this.renderer.listen(this.LayoutElement, 'transitionend', () => {
                if (this.on)
                    this.subscriptions.add(
                        _mouseEvents.subscribe((e: any) => {
                            if (!this.LayoutElement.contains(e.target)) {
                                this.animateOut();
                                this.toggled.emit(false);
                            }
                        })
                    );

                this.onTransitionend.emit(this.on);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
