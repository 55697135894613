import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XeditorComponent } from './xeditor.component';
import { EditorComponent } from './editor/editor.component';
import { FormsModule } from '@angular/forms';
import { XEditorInputComponent } from './editor/input.component';

@NgModule({
    declarations: [XeditorComponent, XEditorInputComponent, EditorComponent],
    imports: [CommonModule, FormsModule],
    exports: [XeditorComponent, FormsModule],
    entryComponents: [XeditorComponent, XEditorInputComponent, EditorComponent],
})
export class XeditorModule {
    constructor() {}
}
