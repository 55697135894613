import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PointyComponent } from './pointy.component';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HoursOfOperationsModule } from '../../../hoursOfOperations/hoursofoperations.module';
import { TimepickerModule } from 'src/app/components/timepicker/timepicker.module';

@NgModule({
    declarations: [PointyComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TimepickerModule,
        HoursOfOperationsModule,
        ModalModule.forRoot(),
        InputModule,
    ],
    exports: [PointyComponent],
    entryComponents: [PointyComponent],
})
export class PointyModule {}
