import { NgModule } from '@angular/core';
import { TextXeditorComponent } from './text.xeditor.component';
import { CommonModule } from '@angular/common';
import { HoursXeditorComponent } from './hours.xeditor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimepickerModule } from 'src/app/components/timepicker/timepicker.module';

@NgModule({
    declarations: [TextXeditorComponent, HoursXeditorComponent],
    imports: [CommonModule, TimepickerModule, FormsModule, ReactiveFormsModule],
    exports: [TextXeditorComponent, HoursXeditorComponent, TimepickerModule, FormsModule, ReactiveFormsModule],
    entryComponents: [TextXeditorComponent, HoursXeditorComponent],
})
export class XEditorComponentsModule {}
