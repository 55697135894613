/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypedaheadDirective } from './typedahead.directive';
import { TypedaheadComponent } from './typedahead.component';

@NgModule({
    declarations: [TypedaheadComponent, TypedaheadDirective],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [TypedaheadComponent, TypedaheadDirective],
})
export class TypedaheadModule {}
