import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
    Renderer2,
    ElementRef,
    AfterContentInit,
} from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';
import { XEditorInputComponent } from './input.component';
import { get } from 'lodash';
import { WindowRef } from '../../window/window.service';
import { WindowEvents } from '../../window/WindowRef.service';

@Component({
    selector: 'xe-editor',
    templateUrl: './editor.component.html',
    styleUrls: [`./editor.component.scss`],
})
export class EditorComponent implements OnInit, OnDestroy, AfterContentInit {
    @ViewChild('tipArrow', { static: true }) private tipArrow!: ElementRef;
    @ViewChild('xeditable', { read: ViewContainerRef, static: true })
    private modelContainer!: ViewContainerRef;
    @ViewChild('editorBody', { read: ViewContainerRef, static: true })
    private editorBody!: ViewContainerRef;
    @Input() bodyRendererFramework: any = XEditorInputComponent;

    public options: any = {
        params: {},
    };
    public _model: string = '';

    private modelComponentRef!: ComponentRef<any>;
    private renderTimeout;
    private event: any;
    private subscriptions: Subscription = new Subscription();
    private bodyFramework: any;

    constructor(
        protected windowRef: WindowRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2
    ) {}

    public CloseDialogBox(e: MouseEvent) {
        this.modelComponentRef.destroy();
    }

    ngOnInit(): void {
        const _mouseEvents = fromEvent(document, 'click');
        const previewComponent = this.componentFactoryResolver.resolveComponentFactory(this.bodyRendererFramework);
        this.editorBody.clear();

        this.bodyFramework = this.editorBody.createComponent(previewComponent);

        this.buildMethods();

        this.subscriptions.add(WindowEvents().OnScrollEvent.subscribe((e) => this.CloseDialogBox(this.event)));
        this.subscriptions.add(WindowEvents().OnResizeEvent.subscribe((e) => this.CloseDialogBox(this.event)));
        this.subscriptions.add(
            _mouseEvents.subscribe((e: any) => {
                if (!this.LayoutElement.contains(e.target)) this.CloseDialogBox(this.event);
            })
        );
        clearTimeout(this.renderTimeout);
    }

    ngAfterContentInit(): void {
        this.LayoutElement.style.left = '-9999999999px';
        this.renderTimeout = setTimeout(() => this.RenderModelContent(1), 0);
    }

    ngOnDestroy(): void {
        clearTimeout(this.renderTimeout);
        this.subscriptions.unsubscribe();
    }

    submit(e) {
        if (!this._model) return;
        this.options.success(null, this._model);
        this.CloseDialogBox(e);
    }

    close = (e) => this.CloseDialogBox(e);

    InitModel(modelComponentRef: ComponentRef<EditorComponent>, event: MouseEvent, config: any) {
        this.modelComponentRef = modelComponentRef;
        this.LayoutElement.style.visibility = 'hidden';

        this.options = config;

        this.event = event;

        this.buildMethods();

        document.body.appendChild(this.LayoutElement.parentNode as any);

        clearTimeout(this.renderTimeout);
    }

    private buildMethods() {
        if (!this.bodyFramework) return;
        if (this.bodyFramework.instance.hasOwnProperty('_model')) this.bodyFramework.instance._model = this._model;
        if (this.bodyFramework.instance.hasOwnProperty('type')) this.bodyFramework.instance.type = this.options.type;

        if (this.bodyFramework.instance.hasOwnProperty('change'))
            this.subscriptions.add(this.bodyFramework.instance.change.subscribe((d) => (this._model = d)));

        if (this.bodyFramework.instance.hasOwnProperty('onSubmit'))
            this.subscriptions.add(
                this.bodyFramework.instance.onSubmit.subscribe((d) => {
                    this._model = d;
                    this.submit(this.event);
                })
            );

        if (this.bodyFramework.instance.hasOwnProperty('onClose'))
            this.subscriptions.add(this.bodyFramework.instance.onClose.subscribe((d) => this.CloseDialogBox(this.event)));

        if (this.options.hasOwnProperty('params')) this.bodyFramework.instance.params = this.options.params;
        else this.bodyFramework.instance.params = this._model;

        if (typeof this.bodyFramework.instance.onInit === 'function') this.bodyFramework.instance.onInit();
        if (typeof this.bodyFramework.instance.close === 'function') this.bodyFramework.instance.close = this.close;
    }

    private RenderModelContent(idx) {
        this.LayoutElement.style.left = '0px';

        const position = get(this.options, 'position', false);
        const spacer = get(this.options, 'offset', 5);

        // element that is clicked on
        const target: any = this.event.target;
        const rect1 = target.getBoundingClientRect();

        // element that we are displaying
        const rect2 = this.LayoutElement.getBoundingClientRect();
        const window = this.windowRef.nativeWindow;

        // get window screen properties
        const cs = window.getComputedStyle(this.LayoutElement, null);
        const csWd = Number(cs.getPropertyValue('width').replace(/px/g, ''));
        const csHt = Number(cs.getPropertyValue('height').replace(/px/g, ''));

        const eM = rect2.width / 2;

        // the opening element, space taken at the end
        const boxPositionEnd = rect1.left + eM;

        // outside of the right side of the window
        let outRight = boxPositionEnd > window.innerWidth;
        const outLeft = rect1.left - eM < 0;

        // the opening element, space taken at the end
        let boxPositionBottom = rect1.top + rect1.height + rect2.height + spacer;

        // outside of the bottom of the window
        let outBottom = boxPositionBottom > window.innerHeight;

        if (position) {
            switch (position) {
                case 'top':
                    outBottom = true;
                    outRight = false;
                    break;
                case 'bottom':
                    outBottom = false;
                    outRight = false;
                    break;
                case 'left':
                    outRight = true;
                    break;
            }
        }

        const boxPositionTop = rect1.top - rect2.height - spacer;

        // arrow width
        const arrowWidth = 0;

        // position top left corner of the box opening
        boxPositionBottom = boxPositionBottom - rect2.height;
        let y = outBottom ? boxPositionTop : boxPositionBottom;
        const x = outRight
            ? rect1.left - csWd - spacer
            : outLeft
            ? rect1.left - spacer
            : rect1.left + rect1.width / 2 + arrowWidth - eM;
        y = outRight ? y + csHt / 2 + rect1.height / 2 + spacer : y + spacer;

        this.LayoutElement.style.left = x + 'px';
        this.LayoutElement.style.top = y + 'px';

        this.LayoutElement.style.visibility = 'visible';

        const arrowTip = outRight ? 'tip-arrow-right' : outBottom ? 'tip-arrow-bottom' : 'tip-arrow-top';
        this.renderer.addClass(this.tipArrow.nativeElement, arrowTip);

        if (outLeft) {
            const left = rect1.width / 2 + spacer;
            this.renderer.setStyle(this.tipArrow.nativeElement, 'left', `${left}px`);
        }
    }

    private get LayoutElement(): HTMLElement {
        return this.modelContainer.element.nativeElement as HTMLElement;
    }
}
