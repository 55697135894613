export const POS_SYSTEMS = [
    {
        name: 'Heartland Restaurant',
        value: 'hrpos',
    },
    {
        name: 'Dinerware',
        value: 'dinerware',
    },
    {
        name: 'Digital Dining',
        value: 'digitaldining',
    },
    {
        name: 'pcAmerica',
        value: 'pcamerica',
    },
    {
        name: 'LiquorPOS',
        value: 'liquorpos',
    },
    {
        name: 'Guest List',
        value: 'guestlist',
    },
    {
        name: 'Heartland Restaurant',
        value: 'hrpos',
    },
    {
        name: 'Heartland Retail',
        value: 'retail',
    },
    {
        name: '--Select POS System--',
        value: '',
    },
];
