import { Inject, Injectable, InjectionToken } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';

export const USE_DEFAULT_SRC_TRANSFORM = new InjectionToken<string>('USE_DEFAULT_SRC_TRANSFORM');
export const USE_DEFAULT_SRC = new InjectionToken<string>('USE_DEFAULT_SRC');

import { EventEmitter } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { DropzoneUploaderObservableWorker } from './dropzone.uploader.observable.worker';

export class FileUploadingEmitter {
    private static _instance: FileUploadingEmitter;
    public onUploading: EventEmitter<any> = new EventEmitter<any>();
    public onError: EventEmitter<any> = new EventEmitter<any>();
    public onComplete: EventEmitter<any> = new EventEmitter<any>();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class UploadService {
    onUploading = new EventEmitter<{ percent: number; total_files: number }>();
    onComplete: EventEmitter<any> = new EventEmitter<any>();
    onError: EventEmitter<any> = new EventEmitter<any>();

    private file_handler = {};

    constructor(
        @Inject(USE_DEFAULT_SRC) private useDefaultSrc: string = '',
        @Inject(USE_DEFAULT_SRC_TRANSFORM)
        private useDefaultTransform: (data: any) => any,
        private dropzoneUploaderObservableWorker: DropzoneUploaderObservableWorker
    ) {}

    private setPercentage(file) {
        if (file === undefined) return;
        if (file?.filename === undefined) return;
        if (this.file_handler[file.filename] === undefined) this.file_handler[file.filename] = {};
        this.file_handler[file.filename].percent = file?.percent || 0;
        const total_files = Object.keys(this.file_handler).length;
        const percentages = Object.keys(this.file_handler).map((a) => this.file_handler[a]['percent'] || 0);
        return percentages.reduce((a, b) => a + b, 0) / total_files;
    }

    public upload(files: any[], body: any = {}, options: any = {}) {
        files = (Array.isArray(files) ? files : [files]).map((file) => {
            const _hadler = {
                useDefaultSrc: this.useDefaultSrc,
                useDefaultTransform: encodeURI(this.useDefaultTransform.toString()),
                file,
                body,
                options,
            };

            return _hadler;
        });

        const filesCompleted: Observable<any>[] | Observable<unknown> = files.length
            ? files.map((file) => {
                  return new Observable((obs) => {
                      FileUploadingEmitter.Instance.onUploading.emit({
                          percent: 0,
                          total_files: 0,
                      });
                      this.dropzoneUploaderObservableWorker.workUnit(file).subscribe({
                          next: (data) => {
                              obs.next(data);
                              const handler = {
                                  percent: this.setPercentage(data),
                                  total_files: Object.keys(this.file_handler).length,
                              };
                              FileUploadingEmitter.Instance.onUploading.emit(handler);
                          },
                          error: (err) => {
                              obs.error(err);
                              FileUploadingEmitter.Instance.onUploading.emit(err);
                          },
                          complete: () => {
                              obs.complete();
                              FileUploadingEmitter.Instance.onUploading.emit({
                                  percent: 100,
                              });
                          },
                      });
                  });
              })
            : [of({ percent: 100 })];

        return combineLatest(filesCompleted).pipe(catchError((err) => throwError(err)));
    }
}
