import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgContentInjectionComponent } from './ng-content-injection.component';
import { NgContentInjectionDirective } from './ng-content-injection.directive';
import { NgContentInjectionService } from './ng-content-injection.service';

@NgModule({
    declarations: [NgContentInjectionComponent, NgContentInjectionDirective],
    imports: [CommonModule],
    exports: [NgContentInjectionDirective],
    providers: [NgContentInjectionService],
    entryComponents: [NgContentInjectionComponent],
})
export class NgContentInjectionModule {}
