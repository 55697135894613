import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { EditlocationService } from '../modals/editlocation/editlocation.service';
import { SiteSelectionGridComponent } from './site-selection-grid/site.selection.grid.component';
import { getLocationData } from '../../constants/site.information.object.sample';
import { $_GET } from '../../utilities/$_GET';
import { AppState } from '../../app.service';
import { ActivatedRoute } from '@angular/router';
import { ValidateUser } from 'src/app/auth/validate.user.service';

@Component({
    selector: 'site-header',
    templateUrl: `./site.header.component.html`,
})
export class SiteHeaderComponent {
    public siteSelectionGridComponent = SiteSelectionGridComponent;

    public showDropdownArrow: boolean = false;
    public showDropdown: boolean = false;
    public multiSite: boolean = false;
    public hasCityInfo: boolean = false;
    public canEdit: boolean = false;
    public canViewSiteInfo: boolean = false;

    public sitedata: any = {
        name: 'Loading ...',
    };
    public sitecity: any[] = [];
    public dropDownSelection = [];

    private subscriptions: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private validateUser: ValidateUser,
        private appState: AppState,
        private editlocationService: EditlocationService
    ) {}

    get SiteID() {
        return $_GET('cssite');
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.data.subscribe(({ LocationResolver }) => {
                this.buildLocationData(LocationResolver);
            })
        );
        const issubscriber = this.appState.get('issubscriber');
        const isSupport = this.validateUser.hasRole(['USER.SUPPORT']);

        this.multiSite = isSupport || issubscriber;

        const hasEDIT = this.validateUser.hasRole(['API.LOCATION.EDIT']);
        const hasSiteInfo = this.validateUser.hasRole(['API.SITE']);

        this.canEdit = hasEDIT;
        this.canViewSiteInfo = hasSiteInfo;
        this.showDropdownArrow = hasEDIT || hasSiteInfo;
    }

    editLocation() {
        this.editlocationService.openEditLocation(this.SiteID);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private buildLocationData(d: any) {
        const sampleObject = getLocationData(d);
        this.hasCityInfo = sampleObject['hasCityInfo'];
        this.sitedata = Object.assign({}, sampleObject, d);
        if (this.sitedata.city) this.sitecity.push(d.city);
        if (this.sitedata.country) this.sitecity.push(d.country);
    }
}
