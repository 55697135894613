import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';

import { GridOptions } from 'ag-grid-community';
import { NavigationExtras, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LocationDataSourceService } from '../../../services/location.data.source.service';
import { LocationService } from '../../../services/location.service';
import { NgContentInjectionService } from '../../../components/ngContentInjection/ng-content-injection.service';
import { JwtService } from 'src/app/services/local_storage.service';
import { AdminInfiniteGridClass, GridEvents } from 'src/app/components/agGridDatasource/admin.infinite.grid.class';
import { WindowEvents } from 'src/app/components/window/WindowRef.service';

@Component({
    selector: 'site-selection-grid',
    templateUrl: './site.selection.grid.component.html',
})
export class SiteSelectionGridComponent extends AdminInfiniteGridClass implements OnInit, OnDestroy {
    @Input('query') queryFilterObj: any = {};
    @Output('onGridReady') isReady: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('class.md-whiteframe-2dp') whiteframe = true;

    public filterParams: any = {
        site_id: {
            type: 'string',
            value: '',
        },
        name: {
            type: 'string',
            value: '',
        },
    };

    public gridOptions: GridOptions = {
        rowDeselection: true,
        suppressMovableColumns: false,

        rowBuffer: 20,
        rowHeight: 30,
        onBodyScroll: (e: any) => GridEvents().OnScrollEvent.emit(e),
        onRowClicked: (e: any) => this.onSelectionChanged(e),
        overlayLoadingTemplate: 'Loading...',
        overlayNoRowsTemplate: 'No Rows To Show',
    };

    public selectedRows: any[] = [];
    public typedaheadDatasets;

    constructor(
        private router: Router,
        private ngContentInjectionService: NgContentInjectionService,
        public dataSource: LocationDataSourceService,
        private locationService: LocationService,
        private jwtService: JwtService
    ) {
        super(dataSource);
    }

    onSelectionChanged(e) {
        this.selectedRows = this.gridApi.getSelectedRows();
    }

    onClearFilter(e) {
        Object.keys(this.filterParams).forEach((k) => (this.filterParams[k].value = ''));
        if (!this.gridApi) return;
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
    }

    onColumnResized(event) {
        if (event.finished && this.gridApi) this.gridApi.resetRowHeights();
    }

    private updateQuery() {
        if (Object.keys(this.queryFilterObj).length && this.gridApi) this.gridApi.setFilterModel(this.queryFilterObj);
    }

    onSearch(e: any) {
        this.gridApi.setFilterModel(e);
    }

    ngOnInit(): void {
        this.typedaheadDatasets = Object.assign(
            {},
            {
                name: 'name',
                async: true,
                source: (q, processSync, processAsync) => {
                    const $match = {};
                    $match['name'] = { $regex: q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), $options: 'i' };
                    const query = [{ $match }];

                    return of(query as any)
                        .pipe(
                            debounceTime(250),
                            distinctUntilChanged(),
                            switchMap((query) => this.locationService.getLocations(query, 0, 10))
                        )
                        .subscribe((users) => users.forEach((u) => processAsync([u['name']])));
                },
                minLength: 3,
            }
        );

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.subscriptions.add(
            WindowEvents().OnResizeEvent.subscribe((data) => {
                if (this.gridApi) {
                    this.gridApi.sizeColumnsToFit();
                    this.gridApi.doLayout();
                }
            })
        );
        this.subscriptions.add(
            this.onGridReady.subscribe((params) => {
                this.isReady.emit(params);
                this.updateQuery();
            })
        );

        this.setGridOptions();
        this.columnDefs = this.getColumnDefs();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    changeSite(e) {
        const site = this.selectedRows[0];
        this.ngContentInjectionService.hide();
        const isCSSiteId = this.jwtService.getCurrentSiteId() === site.site_id;

        const navigationExtras: NavigationExtras = {
            // relativeTo: this.route,
        };

        if (!isCSSiteId)
            Object.assign(navigationExtras, {
                queryParams: {
                    name: site.name,
                    cssite: site.site_id,
                    partner: site.partner_id,
                },
            });

        this.router.urlUpdateStrategy = 'eager';
        this.router.navigate([location.pathname], navigationExtras);
    }

    getColumnDefs(): any[] {
        const columnDefs: any[] = [
            {
                headerName: 'Site ID',
                headerClass: 'text-capitalize text-right',
                field: 'site_id',
                minWidth: 50,
                maxWidth: 80,
                suppressMenu: true,
                filter: false,
                cellClass: 'cssite_id text-right',
            },
            {
                headerName: 'Site Name',
                headerClass: 'text-capitalize',
                field: 'name',
                suppressMenu: true,
                filter: false,
                cellClass: 'location_name',
            },
        ];

        return columnDefs;
    }

    closeMe(e) {}
}
