import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PayrollService } from './containers/payroll/services/payroll.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PosAppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { EditLocationModules } from './containers/modals/editlocation/editlocation.modules';
import { SiteHeaderModule } from './containers/site-header/site.header.module';
import { HttpClientModuleInterceptorProvider } from './http.interceptor';
import { SiteHeaderComponent } from './containers/site-header/site.header.component';
import { AppsDrawerComponent } from './components/apps-drawer/apps.drawer.component';
import { JwtService } from './services/local_storage.service';
import { DataSourceService } from './components/agGridDatasource/data.source.service';
import { LocationService } from './services/location.service';
import { UserAppDataService } from './services/user.data.service';
import { RefreshGridNotification } from './services/refresh.grid.notification';
import { TimezoneService } from './services/timezone.service';
import { AuthServiceProvider } from './services/auth.rules.override';
import { LocationsResolver } from './resolvers/locations.resolver';
import { LocationResolver } from './resolvers/location.resolver';
import { UserDataResolver } from './resolvers/user.data.resolver';
import { RangePipe } from './pipes/range.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GroupByPipe } from './pipes/groupBy.pipe';
import { LayoutModule } from './layout/layout.module';
import { LayoutServices } from './layout/layout.services';
import { LoggedInGuard } from './guards/logged-in.guard';
import { AuthModule } from './auth/auth.module';
import { PERMISSIONGuard } from './auth/PERMISSION.guard';
import { DropzoneModule } from './components/Dropzone/dropzone.module';
import { NgContentInjectionModule } from './components/ngContentInjection/ng-content-injection.module';
import { ToasterModule } from './components/toast/toast.module';
import { AppState } from './app.service';
import { CollapsibleModule } from './components/collapsible/collapsible.module';
import { SortableModule } from './components/sortable/sortable.module';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { AuthService } from './auth/auth.service';
import { InputModule } from './components/Inputs/input.module';
import { HeaderModule } from './layout/components/header/header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    bootstrap: [PosAppComponent],
    imports: [
        AuthModule,
        BrowserAnimationsModule,
        CommonModule,
        CollapsibleModule,
        DropzoneModule,
        EditLocationModules,
        FormsModule,
        HeaderModule,
        HttpClientModule,
        InputModule,
        LayoutModule.withComponents({
            appDrawer: AppsDrawerComponent,
            siteHeader: SiteHeaderComponent,
        }),
        ModalModule.forRoot(),
        NgContentInjectionModule,
        ReactiveFormsModule,
        RouterModule.forRoot(ROUTES, {
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
        }),
        SiteHeaderModule,
        SortableModule,
        ToasterModule,
    ],
    declarations: [PosAppComponent, RangePipe, FilterPipe, GroupByPipe, AppsDrawerComponent, LoginComponent],
    exports: [NgContentInjectionModule, RouterModule, ToasterModule, LayoutModule],
    providers: [
        ...AuthServiceProvider,
        AppState,
        LoggedInGuard,
        LocationsResolver,
        LocationResolver,
        UserDataResolver,
        HttpClientModuleInterceptorProvider,
        DataSourceService,
        LocationService,
        UserAppDataService,
        RefreshGridNotification,
        TimezoneService,
        PERMISSIONGuard,
        PayrollService,
        JwtService,
        LayoutServices,
        LoginService,
        AuthService,
    ],
})
export class AppModule {}
