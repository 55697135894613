import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    NavigationExtras,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ValidateUser } from './validate.user.service';

@Injectable()
export class PERMISSIONGuard implements CanActivate {
    constructor(private route: ActivatedRoute, private router: Router, private validateUser: ValidateUser) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const permissions: any[] = route.data.PERMISSIONGuard;
        const permissionRules: any = route.data.PERMISSIONGuardRedirect;

        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
            skipLocationChange: true,
            relativeTo: this.route,
        };

        const allowed = this.validateUser.hasRole(permissions);
        let reroute = ['/oops/401'];

        if (permissionRules && !allowed) {
            let hasReroute = false;

            Object.keys(permissionRules).forEach((p: string) => {
                let navigate = permissionRules[p];
                let _navigationExtras = {};

                if (permissionRules[p].hasOwnProperty('navigate')) navigate = permissionRules[p].navigate;
                if (permissionRules[p].hasOwnProperty('navigationExtras'))
                    Object.assign(_navigationExtras, permissionRules[p].navigationExtras);

                if (!hasReroute) {
                    hasReroute = this.validateUser.hasRole(Array.isArray(p) ? p : [p]);
                    reroute = Array.isArray(navigate) ? navigate : ['/' + navigate];
                    Object.assign(navigationExtras, _navigationExtras);
                }
            });
        }

        if (!allowed) {
            this.router.navigate(reroute, navigationExtras);
        }
        return allowed;
    }
}
