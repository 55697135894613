import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'xeditor-input[formControlName],xeditor-input[formControl],xeditor-input[ngModel]',
    templateUrl: './input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => XEditorInputComponent),
            multi: true,
        },
    ],
})
export class XEditorInputComponent implements ControlValueAccessor {
    public _model: string = '';
    public _disabled!: boolean;

    public invalid: boolean = true;
    public valid: boolean = false;
    public dirty: boolean = false;

    @ViewChild('inputModel', { static: true }) inputModel!: ElementRef;
    @ViewChild('ctrl', { static: true }) ctrl!: AbstractControlDirective;

    @Input() placeholder: string = '';
    @Input() type: string = 'text';
    @Input() id: string = '';
    @Input() name: string = '';

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

    @Input() set ngValue(v: any) {
        this._model = v;
    }

    @Input() set disabled(v: boolean) {
        this._disabled = v !== false;
    }

    private subscriptions: Subscription = new Subscription();

    constructor(private elementRef: ElementRef) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    submit(e?) {
        if (!this._model) return;
        this.onSubmit.emit(this._model);
    }

    close(e?) {
        this.onClose.emit(true);
    }

    modelChanged(e) {
        if (e.hasOwnProperty('stopPropagation')) e.stopPropagation();
        this.dirty = true;

        this.change.emit(this.ngValue);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    get ngValue() {
        return this._model;
    }

    get disabled() {
        return this._disabled;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: any): void {
        if (obj !== this.ngValue) this.ngValue = obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};

    get LayoutElement(): any {
        return this.elementRef.nativeElement as Element as HTMLElement;
    }
}
