import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDirective } from './permission.directive';
import { ValidateUser } from './validate.user.service';
import { AuthService } from './auth.service';

@NgModule({
    declarations: [PermissionDirective],
    imports: [CommonModule],
    exports: [PermissionDirective],
    providers: [ValidateUser, AuthService],
})
export class AuthModule {}
