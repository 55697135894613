import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterEmitterService } from './toaster.service';
import { TastyComponent } from './tasty.component';
import { delay } from 'rxjs/operators';

type Content<T> = string | TemplateRef<T> | Type<T> | HTMLElement;

@Component({
    selector: 'toast',
    styles: [
        `
            :host {
                position: absolute;
                top: 60px;
                right: 10px;
                width: 300px;
            }
        `,
    ],
    template: ` <div #toast></div>
        <div #vc></div>`,
})
export class ToastComponent {
    @ViewChild('toast', { read: ViewContainerRef, static: true })
    private toast!: ViewContainerRef;
    @ViewChild('vc', { read: ViewContainerRef, static: true })
    private vc!: ViewContainerRef;

    private subscriptions: Subscription = new Subscription();
    private componentRef;

    constructor(
        private elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        const toaster = ToasterEmitterService.Instance;

        this.subscriptions.add(
            toaster.onShow.subscribe((data) => {
                const factory = this.componentFactoryResolver.resolveComponentFactory(TastyComponent);
                const ngContent = this.resolveNgContent(data.message);

                const componentRef = this.toast.createComponent(factory, undefined, undefined, ngContent);

                this.componentRef = componentRef;

                toaster.onShowLocation.emit(this.elementRef);

                componentRef.hostView.detectChanges();
                const instance = componentRef.instance;
                instance.setType(data.type);
                instance.title = data.title;
                instance.options = data.options;
                instance.reInit();
                instance.subscriptions.add(
                    instance.onCloseEmitter.pipe(delay(1)).subscribe((e) => {
                        componentRef.destroy();
                    })
                );
            })
        );
    }

    private isHTML(str) {
        const a = document.createElement('div');
        a.innerHTML = str;
        for (let c = a.childNodes, i = c.length; i--; ) {
            if (c[i].nodeType === 1) return true;
        }

        return false;
    }

    resolveNgContent<T>(content: Content<T>) {
        const ishtml = this.isHTML(content);
        if (!ishtml && typeof content === 'string') {
            const element = document.createTextNode(content);
            return [[element]];
        } else if (ishtml) {
            const a: any = document.createElement('div');
            a.innerHTML = content;

            if (a instanceof HTMLElement) content = a;
        }

        if (content instanceof HTMLElement) {
            return [[content]];
        }

        if (content instanceof TemplateRef) {
            const viewRef: any = this.viewContainerRef.createEmbeddedView(content as any);
            return [viewRef.rootNodes];
        }

        const factory = this.componentFactoryResolver.resolveComponentFactory(content as any);
        this.vc.clear();
        const componentRef = this.vc.createComponent(factory);
        return [[componentRef.location.nativeElement]];
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
