import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { saveAs } from 'file-saver';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'NewLocationToast',
    template: `
        <span [innerHTML]="string"></span>
        <div class="text-right">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="download($event)">
                <i class="fa fa-download"></i>
                Download
            </button>
        </div>
    `,
})
export class NewLocationToastComponent implements OnDestroy, OnInit {
    params: any;
    string: string = ``;
    onDestroy: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document) {}

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'new-location-toast');
        this.onDestroy.emit(true);
    }

    ngOnInit(): void {
        this.renderer.addClass(this.document.body, 'new-location-toast');
    }

    buildNotification() {
        this.string = `Name: ${this.params.name}<br />
Username: ${this.params.username}<br />
Password: ${this.params.password}
`;
    }

    download(e): void {
        const blob = new Blob([this.string.replace(/\<br \/\>/g, '')], {
            type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, `${this.params.name}.txt`);
        this.onDestroy.emit(true);
    }

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
