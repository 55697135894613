import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Inject,
    InjectionToken,
    Input,
    Output,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_RANGE_DATE_SELECTION_MODEL_PROVIDER, MatDatepicker } from '@angular/material/datepicker';
import { FormControl, FormGroup } from '@angular/forms';
import { resolveNgContent } from './resolveNgContent';
import { HeaderInjectorComponent } from './header-injector.component';

export interface CalendarOptions {
    cancelLabel?: string;
    applyLabel?: string;
    minDate?: any;
    maxDate?: any;
    startDate?: any;
    endDate?: any;
    componentRenderer?: any;
}

export const MAT_DIALOG_REF = new InjectionToken<MatDialogRef<DaterangeComponent>>('MAT_DIALOG_REF');

@Component({
    selector: 'material-datepicker',
    styleUrls: ['./daterange.component.scss'],
    templateUrl: `./daterange.component.html`,
    providers: [MAT_RANGE_DATE_SELECTION_MODEL_PROVIDER],
})
export class DaterangeComponent {
    @ViewChild('vc', { read: ViewContainerRef, static: true })
    private vc!: ViewContainerRef;

    @Input() options: any = {};
    @Input() startDate!: Date;
    @Input() endDate!: Date;
    @Input() minDate!: Date;
    @Input() maxDate!: Date;
    @Input() componentRenderer: any;
    @Input() cancelLabel: string = 'Cancel';
    @Input() applyLabel: string = 'Apply';
    @Input() required: boolean = false;
    @Input() label = '';
    @Input() datepickerFilter;

    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();
    @Output() onShow: EventEmitter<any> = new EventEmitter();

    public popup: boolean = false;
    public dialogRef!: MatDialogRef<DaterangeComponent>;

    @ViewChild('datepicker', { static: true }) datepicker!: MatDatepicker<Date>;
    public campaignOne: FormGroup = new FormGroup({
        start: new FormControl(new Date()),
        end: new FormControl(new Date()),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: MatDialog,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    datepickerOpened(e) {
        this.onShow.emit(null);
        if (this.popup) this.datepicker.close = () => null;
    }

    closeDatepicker(e) {
        this.onCancel.emit(null);
        this.setDatepicker(e);
    }

    setDatepicker(e) {
        const rawValue = this.campaignOne.getRawValue();
        const params = {
            startDate: rawValue['start'] || new Date(),
            endDate: rawValue['end'] || rawValue['start'],
        };

        this.change.emit(params);
        if (this.popup && this.dialogRef) this.dialogRef.close(params);
        else this.datepicker.close();
    }

    ngAfterViewInit(): void {
        if (this.popup) this.setPopup();

        if (this.options.hasOwnProperty('componentRenderer')) {
            const componentRenderer = this.options['componentRenderer'];
            delete this.options['componentRenderer'];
            this.vc.clear();
            const factory = this.componentFactoryResolver.resolveComponentFactory(HeaderInjectorComponent);
            const ngContent = resolveNgContent.apply(this, [componentRenderer]);
            this.vc.createComponent(factory, undefined, undefined, ngContent);
        }
    }

    private setPopup() {
        try {
            this.datepicker.open();
        } catch (e) {}

        const backdrop: HTMLElement = document.querySelector('.' + (this.datepicker as any)._backdropHarnessClass) as any;
        const element: ElementRef = (this.datepicker as any)._componentRef.location;

        if (backdrop) backdrop.remove();
        this.renderer.appendChild(this.LayoutElement, element.nativeElement);
    }

    ngOnInit(): void {
        if (Object.keys(this.data).length) Object.keys(this.data).forEach((k) => (this[k] = this.data[k]));
        if (Object.keys(this.options).length) Object.keys(this.options).forEach((k) => (this[k] = this.options[k]));

        if (this.options.hasOwnProperty('startDate')) {
            this.campaignOne.controls['start'].setValue(new Date(this.options['startDate']));
        }

        if (this.options.hasOwnProperty('endDate')) {
            this.campaignOne.controls['end'].setValue(new Date(this.options['endDate']));
        }
    }

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
