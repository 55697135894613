import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'toggle-button[formControlName],toggle-button[formControl],toggle-button[ngModel]',
    template: ` <div class="btn-group btn-toggle-group" [ngClass]="{ 'cursor-disabled': readonly }">
        <button
            type="button"
            #labelOnBtn
            [disabled]="readonly"
            [attr.data-label-txt]="LabelOnTxt"
            (click)="onStatusChange(LabelOnValue)"
            [ngClass]="{ 'btn-success': _checked, 'btn-light': !_checked }"
            class="btn ripple py-1"
            data-test="toggle-on-btn"
        >
            {{ LabelOnTxt }}
        </button>
        <button
            type="button"
            #labelOffBtn
            [disabled]="readonly"
            [attr.data-label-txt]="LabelOffTxt"
            (click)="onStatusChange(LabelOffValue)"
            [ngClass]="{ 'btn-success': !_checked, 'btn-light': _checked }"
            class="btn ripple py-1"
            data-test="toggle-off-btn"
        >
            {{ LabelOffTxt }}
        </button>
    </div>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleButtonComponent),
            multi: true,
        },
    ],
})
export class ToggleButtonComponent implements ControlValueAccessor {
    @ViewChild('labelOnBtn') labelOnBtn!: ElementRef;
    @ViewChild('labelOffBtn') labelOffBtn!: ElementRef;

    @Input() LabelOffTxt: string = 'Off';
    @Input() LabelOnTxt: string = 'On';

    @Input() readonly: boolean = false;
    public LabelOnValue: any = true;
    public LabelOffValue: any = false;

    public _checked: boolean = false;

    @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() set checked(v) {
        this._checked = v;
    }

    onStatusChange(status: boolean) {
        this.checked = status;

        this.change.emit(this.checked);
        this.onChangeCallback(this.checked);
        this.onTouchedCallback(this.checked);
    }

    get checked() {
        return this._checked;
    }

    get disabled() {
        return this.readonly;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: boolean): void {
        this.checked = !!obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};
}

