/**
 * Created by Ramor11 on 3/31/2017.
 */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangeComponent, MAT_DIALOG_REF } from './daterange.component';
import { DaterangeDirective } from './daterange.directive';
import { DateRangeService } from './daterange.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderInjectorComponent } from './header-injector.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    declarations: [HeaderInjectorComponent, DaterangeComponent, DaterangeDirective],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: 'never',
        }),
        MatFormFieldModule,
    ],
    exports: [DaterangeDirective, DaterangeComponent, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule],
    providers: [
        DateRangeService,
        {
            provide: MAT_DIALOG_DATA,
            useValue: {},
        },
        {
            provide: MAT_DIALOG_REF,
            useValue: {},
        },
    ],
    entryComponents: [DaterangeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class MdDatepickerModule {}
