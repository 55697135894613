import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Directive } from './select2.directive';
import { Select2Component } from './select2.component';

@NgModule({
    declarations: [Select2Directive, Select2Component],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [FormsModule, ReactiveFormsModule, Select2Directive, Select2Component],
    entryComponents: [Select2Component],
})
export class Select2Module {
    constructor() {}
}
