<div class="editable-input" style="position: relative;">
  <input type="{{type}}"
         [(ngModel)]="_model"
         [ngModelOptions]="{standalone:true}"
         (ngModelChange)="modelChanged($event)"
         style="padding-right: 24px;">
  <span class="editable-clear-x fas fa-times" style="display: none;"></span>
</div>
<div class="editable-buttons">
  <button type="submit" class="editable-submit" (click)="submit($event)">ok</button>
  <button type="button" class="editable-cancel" (click)="close($event)">cancel</button>
</div>
