import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ProgressbarEmitterService } from './progressbar.service';

@Component({
    selector: 'md-progressbar',
    styleUrls: ['./progressbar.component.scss'],
    template: ` <div class="linear-progress-material" #progress>
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
    @Input() currentState: boolean = false;
    @ViewChild('progress', { static: true }) progress!: ElementRef;

    private status: boolean = false;
    private progressbar: ProgressbarEmitterService = ProgressbarEmitterService.Instance;

    constructor(private renderer: Renderer2) {}

    broadcastStatus() {
        if (this.status === this.currentState) return;
        this.status = this.currentState;
        this.renderer[this.status ? 'addClass' : 'removeClass'](this.LayoutElement, 'start');
        setTimeout(() => this.progressbar.onStateStatus.emit(this.status), 100);
    }

    get LayoutElement(): HTMLElement {
        return this.progress.nativeElement;
    }

    ngOnInit(): void {
        this.broadcastStatus();
    }
}
