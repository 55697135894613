<span [ngSwitch]="url">
  <span *ngSwitchCase="null">
    <md-progressbar [currentState]="true"></md-progressbar>
  </span>
  <span *ngSwitchDefault>
    <iframe
      #iframe
      class="iframe"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      scrolling="none"
      [src]="url">
    </iframe>
  </span>
</span>
