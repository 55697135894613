/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMess, InputComponent, InputPrefix, InputSuffix } from './input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TypedaheadModule } from '../typedahead/typedahead.module';

@NgModule({
    declarations: [InputComponent, InputPrefix, InputSuffix, ErrorMess],
    imports: [
        CommonModule,
        FormsModule,
        TypedaheadModule,
        MatInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: 'never',
        }),
    ],
    exports: [
        InputComponent,
        InputPrefix,
        InputSuffix,
        ErrorMess,
        FormsModule,
        TypedaheadModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
    ],
    entryComponents: [InputComponent],
})
export class InputModule {
    constructor() {}
}
