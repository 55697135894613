import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'circle-toggle',
    styleUrls: ['./circles.component.scss'],
    template: `
        <div class="circle-icon">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
        </div>
    `,
})
export class CirclesComponent implements OnChanges {
    @Input() status: boolean = false;

    @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding('class.open') open: boolean = false;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    @HostListener('click', ['$event'])
    onClick(e) {
        e.preventDefault();
        this.open = !this.open;
        this.onToggle.emit(this.open);
    }

    get LayoutElement() {
        return this.elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.status) {
            const cv = changes.status.currentValue;
            const pv = changes.status.previousValue || false;
            if (cv !== pv) this.open = cv;
        }
    }
}
