/**
 * Created by ramor11 on 5/16/2017.
 */
import { Injectable } from '@angular/core';
import { IWindowObjectReference, WindowEvents, WindowRefService } from './WindowRef.service';
import { fromEvent, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface IWindowReference extends IWindowObjectReference {
    reffer: string;
    name: string;
    windowRef: any;
    isFrame?: boolean;
}

@Injectable()
export class WindowRef {
    public onResize: Subject<any> = new Subject();

    public printMousePos: Subject<any> = new Subject();

    private instant = WindowRefService.Instance;

    /**
     * Extends WindowClass, which will listen for LogOut and beforeUnload events to close any open PostMessage Windows
     * @param authService
     */
    constructor() {
        this.instant.nativeWindow.addEventListener('beforeunload', () => this.instant.CloseAllWindowReferences(), false);

        fromEvent(window, 'resize')
            .pipe(delay(1))
            .subscribe((e) => {
                WindowEvents().OnResizeEvent.emit(e);
                this.onResize.next(e);
            });

        fromEvent(window, 'orientationchange')
            .pipe(delay(1))
            .subscribe((e) => {
                WindowEvents().OnResizeEvent.emit(e);
                this.onResize.next(e);
            });

        fromEvent(document, 'click').subscribe((e) => this.printMousePos.next(e));
    }

    get nativeWindow(): any {
        return this.instant.nativeWindow;
    }
}
