import { TemplateRef, Type } from '@angular/core';

type Content<T> = string | TemplateRef<T> | Type<T> | HTMLElement;

function isHTML(str) {
    const a = document.createElement('div');
    a.innerHTML = str;
    for (let c = a.childNodes, i = c.length; i--; ) {
        if (c[i].nodeType === 1) return true;
    }

    return false;
}

export function resolveNgContent<T>(this: any, content: Content<T>) {
    const ishtml = isHTML(content);
    if (!ishtml && typeof content === 'string') {
        const element = document.createTextNode(content);
        return [[element]];
    } else if (ishtml) {
        const a: any = document.createElement('div');
        a.innerHTML = content;

        if (a instanceof HTMLElement) content = a;
    }

    if (content instanceof HTMLElement) {
        return [[content]];
    }

    if (content instanceof TemplateRef) {
        const viewRef: any = this.viewContainerRef.createEmbeddedView(content as any);
        return [viewRef.rootNodes];
    }

    /** Otherwise it's a component */
    const factory = this.componentFactoryResolver.resolveComponentFactory(content as any);
    this.vc.clear();
    const componentRef = this.vc.createComponent(factory);

    return [[componentRef.location.nativeElement]];
}
