import { Subject } from 'rxjs';

export class Http {
    private HttpRequest(callback: (err: any, res: any) => any): XMLHttpRequest {
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                let responseText = xhr.responseText;
                try {
                    responseText = JSON.parse(xhr.responseText);
                } catch (e) {}
                if (xhr.status !== 200) {
                    callback(
                        {
                            status: xhr.status,
                            error: responseText,
                        },
                        null
                    );
                } else callback(null, responseText);
            }
        };
        return xhr;
    }

    private setHeaders(xhr: XMLHttpRequest, headers = {}) {
        Object.keys(headers).forEach((k) => xhr.setRequestHeader(k, headers[k]));
    }

    post(
        url,
        body,
        options = {
            headers: {},
        }
    ): Subject<any> {
        const subject = new Subject<any>();
        const xhttp: XMLHttpRequest = this.HttpRequest((err, res: any) => {
            if (!err) subject.next(res);
            else subject.error(err);
            subject.complete();
        });
        xhttp.open('POST', url, true);
        if (options.headers) this.setHeaders(xhttp, options.headers);
        xhttp.send(body);
        return subject;
    }
}
