import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { ISortElements, SortableDirective } from './sortable.directive';
import { Sortable, Plugins } from '@shopify/draggable';
import { SortableService } from './sortable.service';
import { get } from 'lodash';

@Component({
    selector: 'sortable',
    template: '<ng-content></ng-content>',
})
export class SortableComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @ContentChildren(SortableDirective, { descendants: true }) sortable!: QueryList<SortableDirective>;

    @Input() enabled: boolean = true;
    @Input() options: any = {};
    @Output() onDragStart: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDragStop: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSortCompleted: EventEmitter<ISortElements> = new EventEmitter<ISortElements>();
    @HostBinding('class.is-sorting') isDragging: boolean = this.enabled;

    private Items: HTMLElement[] = [];
    private $ortable = Sortable;

    constructor(private elementRef: ElementRef, private sortableService: SortableService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        const previousValue = get(changes, 'enabled.previousValue', undefined);
        if (changes.enabled && typeof previousValue === 'boolean') {
            this.ngAfterViewInit();
        }
    }

    private get Sortables() {
        return this.LayoutElement.querySelectorAll('[sortable]');
    }

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    ngAfterViewInit() {
        this.Items = [];
        const sortItems = this.sortable.toArray();
        sortItems.forEach((i) => {
            i.addClassName();
            i.ngOnDestroy();
            this.Items.push(i.LayoutElement);
        });

        this.enabled ? this.setSortConstructor(sortItems) : this.ngOnDestroy();
    }

    private setSortConstructor(sortItems) {
        this.isDragging = this.enabled;
        const _options = Object.assign(
            {},
            {
                draggable: `.is-sortable`,
                mirror: {
                    constrainDimensions: true,
                },
                plugins: [Plugins.ResizeMirror],
            },
            this.options
        );

        this.$ortable = new Sortable(this.Items, _options);

        // --- Draggable events --- //
        this.sortableService.setDragEvents(
            this.$ortable,
            (sortObject) => {
                setTimeout((d) => this.onSortCompleted.emit(sortObject));
            },
            this
        );
    }

    ngOnDestroy(): void {
        try {
            if (this.$ortable) this.$ortable.destroy();
        } catch (e) {}
    }
}
