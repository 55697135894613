import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LocationResolver } from './resolvers/location.resolver';
import { InvoiceModule } from './containers/invoice/invoice.module';
import { RootEntryModule } from './containers/location-data/root.entry.module';
import { LoginComponent } from './login/login.component';

export const ROUTES: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [LoggedInGuard],
        resolve: {
            LocationResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./containers/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
            },
            {
                path: 'payroll',
                loadChildren: () => import('./containers/payroll/payroll.module').then((mod) => mod.PayrollModule),
            },
            {
                path: 'capi',
                loadChildren: () => import('./containers/capi/capi.module').then((mod) => mod.CapiModule),
            },
            {
                path: 'accounts',
                loadChildren: () => import('./containers/accounts/accounts.module').then((mod) => mod.AccountsModule),
            },
        ],
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'invoice/:transactionNumber/:customerId',
        canActivate: [LoggedInGuard],
        loadChildren: () => InvoiceModule,
    },
    {
        path: 'new-site',
        loadChildren: () => RootEntryModule,
    },
    {
        path: 'oops',
        loadChildren: () => import('./errorPages/error.pages.module').then((mod) => mod.ErrorPagesModule),
    },
    {
        path: 'signon',
        component: LoginComponent,
    },
    { path: '**', redirectTo: 'oops/404', pathMatch: 'full' },
];
