import { EventEmitter, Injectable } from '@angular/core';

class RefreshGridNotificationEmitter {
    private static _instance: RefreshGridNotificationEmitter;

    onChangesEmit: EventEmitter<any> = new EventEmitter<any>();
    onSilentChangesEmit: EventEmitter<any> = new EventEmitter<any>();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class RefreshGridNotification {
    private instance: RefreshGridNotificationEmitter;

    constructor() {
        this.instance = RefreshGridNotificationEmitter.Instance;
    }

    emitSilentChanges(e) {
        this.instance.onSilentChangesEmit.emit(e);
    }

    get onSilentChanges() {
        return this.instance.onSilentChangesEmit;
    }

    emitChanges(e?) {
        this.instance.onChangesEmit.emit(e);
    }

    get onChanges() {
        return this.instance.onChangesEmit;
    }
}
