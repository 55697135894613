import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/components/toast/toaster.service';
import { get } from 'lodash';
import { JwtService } from 'src/app/services/local_storage.service';

@Injectable()
export class UrlHelperService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private toasterService: ToasterService,
        private jwtService: JwtService
    ) {}

    getBlob(url: string): Observable<any> {
        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string | null = null;
            this.http
                .get(url, {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Authorization', `Bearer ${this.jwtService.getJwt()}`),
                })
                .subscribe(
                    (m) => {
                        objectUrl = URL.createObjectURL(m);
                        observer.next(objectUrl);
                    },
                    (err) => {
                        switch (err.status) {
                            case 403:
                            case 404:
                                this.router.navigate([err.status], {
                                    skipLocationChange: true,
                                    queryParamsHandling: 'merge',
                                });
                                break;
                            default:
                                const title = get(err, 'error.title', 'Server Error');
                                const msg = get(err, 'error.detail', title) || title;
                                this.toasterService.error('Error', msg, {
                                    delay: 5000,
                                });
                                break;
                        }
                    }
                );

            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}
