import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
export type Content<T> = string | TemplateRef<T> | Type<T>;
declare const $: any;

@Component({
    selector: 'tasty',
    styleUrls: [`./tasty.component.scss`],
    templateUrl: `./tasty.component.html`,
})
export class TastyComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('toasty', { static: true }) toasty!: ElementRef;

    public type: string = 'tasty-success';
    public title: string = '';
    public template!: Content<any>;
    public options: any = {};
    public time: number = 0;
    public timeSting: string = 'seconds';
    public onCloseEmitter: EventEmitter<any> = new EventEmitter<any>();

    public iconDisplay = 'fa-check-circle';
    public subscriptions: Subscription = new Subscription();

    private timeEvent;

    ngOnInit(): void {
        const started = Date.now();

        //emit value every 1s
        const source = interval(1000 * 30);

        this.subscriptions.add(
            source.subscribe((val) => {
                const elapsed = Date.now() - started;
                let minutes = 0;
                let hours = 0;
                const seconds = Math.floor(elapsed / 1000);
                this.time = seconds;
                if (seconds >= 60) {
                    minutes = Math.floor(seconds / 60);
                    this.timeSting = 'minutes';
                    this.time = minutes;
                }
                if (minutes >= 60) {
                    hours = Math.floor(minutes / 60);
                    this.timeSting = 'hours';
                    this.time = hours;
                }
            })
        );
        this.reInit();
    }

    onClose(e) {
        this.onCloseEmitter.emit(e);
    }

    setType(type) {
        this.type = `tasty-${type}`;

        switch (type) {
            case 'info':
                this.iconDisplay = 'fa-info-circle';
                break;
            case 'warning':
                this.iconDisplay = 'fa-radiation';
                break;
            case 'danger':
            case 'error':
                this.type = `tasty-danger`;
                this.iconDisplay = 'fa-exclamation-circle';
                break;
            default:
                this.iconDisplay = 'fa-check-circle';
        }
    }

    get LayoutElement(): HTMLElement {
        return this.toasty.nativeElement;
    }

    reInit() {
        clearTimeout(this.timeEvent);

        if (this.options.hasOwnProperty('delay')) {
            this.timeEvent = setTimeout(() => {
                this.onCloseEmitter.emit(this.LayoutElement);
            }, this.options.delay);
        }

        if (this.options.hasOwnProperty('onClose')) {
            const isFunc: boolean = typeof this.options.onClose === 'function';
            if (isFunc) {
                this.options.onClose().subscribe((d) => {
                    if (d) {
                        this.onCloseEmitter.emit(this.LayoutElement);
                    }
                });
            }
        }
    }

    ngAfterContentInit(): void {
        this.reInit();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
