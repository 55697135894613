import { Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { APPLICATIONS } from './apps.component.list';
import { NavigationExtras, Router } from '@angular/router';

import { sortBy, get, uniqBy } from 'lodash';
import { UserAppDataService } from '../../services/user.data.service';
import { map } from 'rxjs/operators';
import { AppState } from '../../app.service';
import { IAppDrawerComponent } from 'src/app/layout/components/app-drawer/app-drawer.component';
import { ValidateUser } from 'src/app/auth/validate.user.service';

@Component({
    selector: 'apps-drawer',
    templateUrl: `./apps.drawer.component.html`,
})
export class AppsDrawerComponent implements OnInit, OnDestroy, IAppDrawerComponent {
    @Output() onModuleClicked: EventEmitter<any> = new EventEmitter();

    @HostBinding('class.is-draggabilly') draggability: boolean = false;

    public loading: boolean = true;
    public APPLICATIONS: any[] = [];

    subscriptions: Subscription = new Subscription();

    constructor(
        private userData: UserAppDataService,
        private validateUser: ValidateUser,
        private elementRef: ElementRef,
        private appState: AppState,
        private router: Router
    ) {}

    get LayoutElement() {
        return this.elementRef.nativeElement;
    }

    moduleClicked(e, a) {
        e.preventDefault();
        if (this.draggability) return;
        if (!a.disabled) {
            this.onModuleClicked.emit(a);
            setTimeout(() => {
                const navigationExtras: NavigationExtras = {
                    queryParamsHandling: 'merge',
                };
                this.router.navigate([a.path], navigationExtras);
            }, 500);
        }
    }

    ngOnInit(): void {
        this.loading = true;

        const disabled_applications: any[] = [];
        this.APPLICATIONS = APPLICATIONS
            .filter((a) => !get(a, 'hide', false))
            .map((application) => {
                application.disabled = application.hasOwnProperty('disabled')
                    ? application.disabled
                    : !this.validateUser.hasRole(application.permission);

                (application as any).active = location.pathname.indexOf(application.path) > -1;

                return application;
            })
            .filter((a: any) => {
                if (!a.disabled) {
                    return a;
                } else disabled_applications.push(a);
            });

        this.loading = false;
    }

    editableGrid(e) {
        e.preventDefault();
        e.stopPropagation();
        this.draggability = !this.draggability;
    }

    onSortComplete(e: any) {
        const position = {};

        const containers = this.LayoutElement.querySelectorAll('.flex-container');
        const _primary: any[] = [];
        const _secondary: any[] = [];

        Array.from(containers[0].children).forEach((e: any, index) =>
            _primary.push({
                name: e.dataset.name,
                position: index,
            })
        );

        Array.from(containers[1].children).forEach((e: any, index) =>
            _secondary.push({
                name: e.dataset.name,
                position: index,
            })
        );

        position['primary'] = sortBy(uniqBy(_primary, 'name'), 'position');
        position['secondary'] = sortBy(uniqBy(_secondary, 'name'), 'position');

        this.userData
            .putUserdata({
                appComponentsConfig: position,
            })
            .pipe(map((d) => get(d, '0', {})))
            .subscribe((d) => {
                if (d.appComponentsConfig) this.appState.set('USER_APP_CONFIG', d);
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
