import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { PointyComponent } from './modals/pointy/pointy.component';
import { get } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { getTimefromString } from '../../utilities/getTimefromString';
import { ActivatedRoute } from '@angular/router';
import { TextXeditorComponent } from '../xEditor/text.xeditor.component';
import { IntegrationService } from './services/integration.service';
import { Integrators } from '../../constants/integrators';
import { DateRangeService } from '../../components/md-daterange/daterange.service';
import { ToasterService } from 'src/app/components/toast/toaster.service';
import * as moment from 'moment';
import { expandObject, flattenObject } from '../../utilities/flattenObject';

@Component({
    selector: 'integrated-applications',
    styleUrls: ['./integrations.component.scss'],
    templateUrl: `./integrations.component.html`,
})
export class IntegrationsComponent {
    @Input() readonly: boolean = false;

    @Input() site_id!: string;
    @Input() partner_id!: string;
    @Input() opening_hours: any = {};
    @Input() isNewSite: boolean = true;
    @Input() allowEdit: boolean = false;
    @Input() integrators: any = {};
    @Input() showIntegrators: any = Object.keys(Integrators).map((a) => a);
    @Input() showPayrollConnectorEdit: boolean = false;
    @Input() pointyEmbedHoursOfOperations: boolean = true;
    @Input() isSupport: boolean = false;
    @Input() jwtToken;

    public formGroup: FormGroup = new FormGroup(
        (() => {
            const _integrators = {};
            Object.keys(Integrators).forEach((k) => (_integrators[`${k}.active`] = new FormControl(false)));
            return Object.assign(
                {},
                {
                    'pointy.retailer_auth_key': new FormControl(''),
                    'pointy.sendTime': new FormControl('04:00 AM'),
                    'mobileManager.rollUpModifierPrice': new FormControl(true),
                },
                _integrators
            );
        })()
    );

    @Output() onChanges: EventEmitter<any> = new EventEmitter();
    @Output() onFormGroupChanges: EventEmitter<any> = new EventEmitter();
    @Output() onPayrollConnectorChange: EventEmitter<any> = new EventEmitter();
    @Output() onPayrollConnectorEdit: EventEmitter<any> = new EventEmitter();

    public showPayrolConnector: boolean = true;
    public allowMobileManager: boolean = true;
    private triggerOpeningHrsModal: boolean = false;

    public localBodyRendererFramework: any = TextXeditorComponent;
    private mobileManagerPartnerId = ['hrpos', 'springboard'];

    public pointyDescription = {
        text: 'Pointy',
        description: 'Default time that the POS is going to send the inventory data.',
    };

    public checkmateDescription = {
        text: 'Checkmate',
        description: `Select day of week to display different operating hrs.<br> <code>Default</code>: Every Day.<br /> <code>`,
    };

    public payrollConnectorDescription = {
        text: 'Payroll Connector',
        description: `Select day of week to display different operating hrs.<br> <code>Default</code>: Every Day.<br /> <code>`,
    };

    private subscriptions = new Subscription();
    private $onTimedEvent;

    constructor(
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private integrationService: IntegrationService,
        private toasty: ToasterService,
        private datepickerService: DateRangeService
    ) {}

    triggerMobileManager(e) {
        const site_id = this.site_id;
        const options = {};
        const rawData = this.formGroup.getRawValue();

        this.datepickerService.show(options).subscribe(({ startDate, endDate }) => {
            startDate = moment(startDate).format('MM-DD-YYYY');
            endDate = moment(endDate).format('MM-DD-YYYY');

            const rollUpModifierPrice = get(rawData, 'mobileManager.rollUpModifierPrice', true);
            const mmData = {
                startDate,
                endDate,
                site_id,
                rollUpModifierPrice,
            };

            this.integrationService.sendMMData(mmData, this.jwtToken).subscribe(
                (d) => {
                    this.toasty.success('Success', 'Mobile Manager Send Data');
                },
                (error) => {
                    this.toasty.error('Error', 'Failed to Send Data');
                }
            );
        });
    }

    payrollConnectorChange(e) {
        this.onModelChange(e);
        this.onPayrollConnectorChange.emit(e);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.data.subscribe(({ NewSiteRootEntryResolver }) => {
                if (NewSiteRootEntryResolver) {
                    this.buildFormGroup(NewSiteRootEntryResolver);
                }
            })
        );
        this.subscriptions.add(
            this.integrationService.getChangeDetection().subscribe((d) => {
                if (d['integrators']) {
                    this.buildIntegrationFormGroup(d['integrators']);
                }
            })
        );
    }

    private triggerOperningHrs() {
        this.triggerOpeningHrsModal = !this.opening_hours;
        if (!this.triggerOpeningHrsModal) return;

        const opening_hours = this.opening_hours;
        const pointy = this.formGroup.controls['pointy.active'].value;

        if (pointy && this.pointyEmbedHoursOfOperations) this.editPointy(null);
        else if (opening_hours && Object.keys(opening_hours).length) {
            this.formGroup.addControl('opening_hours', new FormControl(opening_hours, {}));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { previousValue, currentValue } = get(changes, 'integrators', {});
        this.buildIntegrationFormGroup(currentValue, previousValue);
        this.buildFormGroup({ opening_hours: this.opening_hours });
    }

    editPointy(e): void {
        const pointy = this.formGroup.controls['pointy.active'].value;

        if (!pointy) return;
        this.triggerOpeningHrsModal = true;
        const opening_hours = get(this.formGroup, 'controls.opening_hours.value', this.opening_hours);
        const retailer_auth_key = this.formGroup.controls['pointy.retailer_auth_key'].value;
        const sendTime = this.formGroup.controls['pointy.sendTime'].value;

        const componentRef: any = this.modalService.show(PointyComponent, {
            ignoreBackdropClick: true,
            class: 'pointy-modal' + (this.pointyEmbedHoursOfOperations ? ' modal-90-percent' : ''),
            initialState: {
                isNewSite: this.isNewSite,
                pointyEmbedHoursOfOperations: this.pointyEmbedHoursOfOperations,
            },
        });

        componentRef.content.buildFormData({
            retailer_auth_key,
            opening_hours,
            sendTime,
        });

        this.subscriptions.add(
            componentRef.content.onClose.subscribe((d) => {
                this.triggerOpeningHrsModal = false;
                Object.keys(d).forEach((key) => {
                    switch (key) {
                        case 'retailer_auth_key':
                            const retailer_auth_key = get(d, 'retailer_auth_key', '');
                            this.formGroup.controls['pointy.retailer_auth_key'].setValue(retailer_auth_key);

                            break;
                        case 'sendTime':
                            const sendTime = get(d, 'sendTime', '04:00 AM');
                            this.formGroup.controls['pointy.sendTime'].setValue(sendTime);
                            break;
                        default:
                            const ctrl = this.formGroup.controls[key];
                            if (ctrl) ctrl.setValue(d[key]);
                            else this.formGroup.addControl(key, new FormControl(d[key], []));
                            break;
                    }
                });

                this.opening_hours = d['opening_hours'] || this.opening_hours;
                this.onModelChange();
            })
        );
    }

    clickRollUpModifierPrice(e) {
        if (this.readonly) return;
        const checkValue = this.formGroup.controls['mobileManager.rollUpModifierPrice'].value;
        this.formGroup.controls['mobileManager.rollUpModifierPrice'].setValue(!checkValue);
    }

    buildFormGroup(params) {
        Object.keys(params).forEach((k) => {
            const value = get(params, k, '');
            const ctrl = this.formGroup.controls[k];
            if (ctrl) ctrl.setValue(value);
            else this.formGroup.addControl(k, new FormControl(value));
        });
    }

    /**
     * Should only run on initial load, or changes from the NgOnChange events
     * @param currentValue
     * @param previousValue
     * @private
     */
    private buildIntegrationFormGroup(currentValue = {}, previousValue = {}) {
        const hadPrevious = !!Object.keys(previousValue).length;
        const hasCurrent = !!Object.keys(currentValue).length;

        const prev = flattenObject(previousValue)['objectKeys'];
        const curr = flattenObject(currentValue)['objectKeys'];

        if (hadPrevious && !hasCurrent) this.buildFormGroup(prev);

        this.buildFormGroup(curr);
        this.triggerOperningHrs();

        this.showPayrolConnector =
            this.showIntegrators.includes('payrollConnector') || this.showIntegrators.includes('payroll');
        this.allowMobileManager = this.mobileManagerPartnerId.includes(this.partner_id);
    }

    private formatSendTime(time) {
        const _t: any = getTimefromString(time);
        return [_t.hours, _t.minutes].map((n) => `0${n}`.slice(-2)).join(':');
    }

    editPayrollConnector(e) {
        this.onPayrollConnectorEdit.emit(e);
    }

    private getRawData() {
        const rawData = this.formGroup.getRawValue();
        const sendTime = rawData['pointy.sendTime'] ? this.formatSendTime(rawData['pointy.sendTime']) : '04:00 AM';

        rawData['pointy.sendTime'] = sendTime;
        rawData['pointy.retailer_auth_key'] = rawData['pointy.retailer_auth_key'] || '';

        let hasPointyError = this.isNewSite ? false : rawData['pointy.active'].active;
        if (rawData['pointy.retailer_auth_key'] && rawData['pointy.sendTime']) hasPointyError = false;

        const integrators = expandObject(rawData);

        delete integrators['opening_hours'];
        delete integrators['pointy']['opening_hours'];

        return {
            integrators,
            opening_hours: this.opening_hours,
            hasPointyError,
        };
    }

    public onModelChange(e?) {
        this.onChanges.emit(this.getRawData());
    }

    onPointyChange(e) {
        this.triggerOperningHrs();
        this.onModelChange();
    }
}
