import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Type,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EditorComponent } from './editor/editor.component';
import { Observable } from 'rxjs';

class EditorContainers {
    private static _instance: EditorContainers;

    public xEditors: any = [];

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Component({
    selector: 'x-editor',
    template: `
        <a (click)="openEditor($event)" #elementRef class="editable editable-click">
            <span [innerHtml]="_model"></span>
            <ng-content></ng-content>
        </a>
    `,
    styleUrls: [`./xeditor.component.scss`],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => XeditorComponent),
            multi: true,
        },
    ],
})
export class XeditorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    @Input() readonly: boolean = false;

    @ViewChild('elementRef', { static: true }) elementRef!: ElementRef;

    @Input('config') config: any = {};
    @Input('mode') mode: string = 'popup';
    @Input('type') type: string = 'text';
    @Input('title') title: string = '';
    @Input('maxlength') maxlength: number = 99;
    @Input('required') required: boolean = false;
    @Input() bodyRendererFramework!: ComponentRef<Type<any>>;

    public _model: string = '';
    public _disabled!: boolean;

    public invalid: boolean = true;
    public valid: boolean = false;
    public dirty: boolean = false;

    private componentRef: any;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    @Input() set ngValue(v: any) {
        this._model = v ? v.toString() : v;
    }

    @Input() set disabled(v: boolean) {
        this._disabled = v !== false;
    }

    private subscriptions: Subscription = new Subscription();

    constructor(private viewContainer: ViewContainerRef, private component: ComponentFactoryResolver) {}

    get ngValue() {
        return this._model;
    }

    get disabled() {
        return this._disabled;
    }

    ngOnChanges(changes: SimpleChanges): void {
        ['type', 'title', 'maxlength', 'required'].forEach((k) => {
            if (this[k] && typeof this[k] !== 'undefined') this.config[k] = this[k];
        });
    }

    ngOnInit(): void {
        const config = {
            mode: 'popup',
            url: '',
            success: (response, newValue) => {
                this.ngValue = newValue;
                this.change.emit(this.ngValue);
                this.onChangeCallback(this.ngValue);
                this.onTouchedCallback(this.ngValue);
            },
            openEditor: null,
        } as any;

        Object.assign(config, this.config);
        if (config.openEditor instanceof Observable)
            this.subscriptions.add(config.openEditor.subscribe((e: MouseEvent) => this.openEditor(e)));

        this.config = config;
    }

    openEditor(e) {
        if (this.readonly) return;

        const xEditor = EditorContainers.Instance;
        if (xEditor.xEditors.length) {
            for (let i = 0; i < xEditor.xEditors.length; i++) xEditor.xEditors[i].destroy();
        }

        e.stopPropagation();

        const ComponentFactory = this.component.resolveComponentFactory(EditorComponent);
        this.viewContainer.clear();

        const componentRef = this.viewContainer.createComponent(ComponentFactory);
        this.componentRef = componentRef.instance;
        this.componentRef._model = this.ngValue;
        this.componentRef.options = this.config;

        if (this.config.hasOwnProperty('bodyRendererFramework')) {
            this.componentRef.bodyRendererFramework = this.config.bodyRendererFramework;
        } else if (this.bodyRendererFramework) {
            this.componentRef.bodyRendererFramework = this.bodyRendererFramework;
        }

        this.componentRef.InitModel(componentRef, e, this.config);

        xEditor.xEditors.push(componentRef);
    }

    ngOnDestroy(): void {
        const xEditor = EditorContainers.Instance;
        if (xEditor.xEditors.length) for (let i = 0; i < xEditor.xEditors.length; i++) xEditor.xEditors[i].destroy();
        this.subscriptions.unsubscribe();
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: any): void {
        if (obj !== this.ngValue) this.ngValue = obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};

    get LayoutElement(): any {
        return this.elementRef.nativeElement as Element as HTMLElement;
    }
}
