export const SIDEBAR_ROUTES = [
    {
        path: `/capi/admin-privileges`,
        name: 'Admin Privileges',
        queryParamsHandling: 'merge',
        routerLinkActiveOptions: { exact: false },
        faIcon: 'fa-users-cog',
        permission: ['UI.CAPI'],
        children: [
            {
                path: 'admin-privileges',
                name: 'Dashboard',
                queryParamsHandling: 'merge',
                permission: ['UI.CAPI.DASHBOARD'],
                showui: false,
            },
            {
                path: ['admin-privileges', 'locations'],
                name: 'All Locations',
                queryParamsHandling: 'merge',
                permission: ['UI.LOCATIONS'],
                children: [
                    {
                        path: '/',
                        name: 'Dashboard',
                        permission: ['UI.LOCATIONS'],
                        queryParamsHandling: '',
                        showui: false,
                    },
                    {
                        path: '/:cssite',
                        name: 'Location Details',
                        stringParse: ['cssite'],
                        queryParamsHandling: 'merge',
                        showui: false,
                        permission: ['UI.LOCATION', 'API.LOCATION.EDIT'],
                    },
                ],
            },
            {
                path: ['admin-privileges', 'add-location'],
                name: 'Add Location',
                queryParamsHandling: 'merge',
                permission: ['UI.LOCATION.ADD'],
            },
            {
                path: ['admin-privileges', 'users'],
                name: 'All Users',
                queryParamsHandling: 'merge',
                permission: ['UI.USERS'],
                children: [
                    {
                        path: '/',
                        name: 'Dashboard',
                        queryParamsHandling: 'merge',
                        permission: ['UI.USERS'],
                        showui: false,
                    },
                    {
                        path: '/admin-privileges/users/edit/:user_id',
                        name: 'Edit User',
                        stringParse: ['user_id'],
                        queryParamsHandling: 'merge',
                        showui: false,
                        permission: ['API.USER.EDIT'],
                    },
                ],
            },
            {
                path: '/admin-privileges/users/edit/:user_id',
                name: 'Edit User',
                stringParse: ['user_id'],
                queryParamsHandling: 'merge',
                sitemap: false,
                showui: false,
                permission: ['API.USER.EDIT'],
            },
            {
                path: '/admin-privileges/site-id/:cssite',
                name: 'Add User',
                stringParse: ['cssite'],
                queryParamsHandling: 'merge',
                sitemap: false,
                showui: false,
                permission: ['UI.USERS'],
            },
            {
                path: ['admin-privileges', 'add-user'],
                name: 'Add User',
                queryParamsHandling: 'merge',
                permission: ['UI.USER.ADD'],
            },
        ],
    },
    {
        path: '/capi/file-management',
        name: 'File Management',
        queryParamsHandling: 'merge',
        faIcon: 'fa-file-upload',
        permission: ['UI.FILEMANAGEMENT', 'FILEUPLOAD'],
    },
    {
        path: '/capi/integration',
        name: 'Integrations',
        faIcon: 'fa-th-large',
        active: false,
        permission: ['UI.CAPI.INTEGRATIONS'],
    },
    {
        path: '/capi/roles',
        name: 'Roles',
        faIcon: 'fa-key',
        permission: ['UI.PERMISSIONS'],
    },
];
