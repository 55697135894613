<div class="py-3 d-flex flex-column h-100">
    <div class="container-fluid">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3 border-bottom">
            <h4>Add Location</h4>
            <div class="btn-toolbar mb-2"></div>
        </div>
    </div>

    <div class="container-md flex-grow-1 w-100 mb-5">
        <div class="widget">
            <header>
                <i class="fa fa-map-marked-alt"></i>
                <span> Add New Location </span>
            </header>

            <div class="widget-body p-0 pos-relative">
                <fieldset>
                    <location-form
                        [pointyEmbedHoursOfOperations]="false"
                        [readonly]="false"
                        [isNewSite]="true"
                        (onChanges)="onChanges($event)"
                    ></location-form>
                </fieldset>
                <fieldset>
                    <hoursofoperations [sendTime]="sendTime" (onChanges)="hrsOfOperationChanges($event)"></hoursofoperations>
                </fieldset>
            </div>
        </div>
    </div>

    <footer class="container-fluid border-top py-3">
        <div class="container-md d-flex justify-content-end">
            <span
                *ngIf="isExistingUser"
                data-balloon-visible
                [attr.data-balloon]="'You are editing an existing user'"
                data-balloon-pos="left"
            ></span>
            <button
                type="button"
                class="btn btn-outline-success ripple text-left text-capitalize py-1"
                (click)="onSaveChange($event)"
            >
                <i class="fa fa-save mr-2"></i>
                Save
            </button>
        </div>
    </footer>
</div>

