import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { POS_SYSTEMS } from './pos.systems.constants';
import { PosSystemsValueClass } from './pos_system';
import { AppState } from '../../app.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'pos-system[formControlName], pos-system[formControl], pos-system[ngModel]',
    templateUrl: './posSystem.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PosSystemComponent),
            multi: true,
        },
    ],
})
export class PosSystemComponent {
    public _model: string = '';

    @Input() allowempty: boolean = false;
    @Input() readonly: boolean = false;
    @Input() label: any = '';
    @Input() required: boolean = true;

    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public ngModelCtrl!: AbstractControl;
    public POSSystem: Array<PosSystemsValueClass> = POS_SYSTEMS;

    public access_level = this.authService.AccessLevel;
    public hasValue: boolean = true;

    constructor(private appState: AppState, private authService: AuthService) {}

    set ngValue(v: any) {
        this._model = v;
        this.hasValueCheck();
    }

    private hasValueCheck() {
        let hasValue = !!this.POSSystem.filter((a) => {
            a.value === this._model;
        }).length;
        if (!hasValue && !this.readonly) hasValue = true;
        this.hasValue = hasValue;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.hasValueCheck();
    }

    get ngValue() {
        return this._model;
    }

    ngAfterContentInit(): void {
        if (this.allowempty) return;
        let PARTNERID = this.appState.get('PARTNER');
        let POS = this.POSSystem.find((p) => p.value === PARTNERID);
        if (!POS) POS = this.POSSystem.find((p) => p.name === PARTNERID);

        if (POS) PARTNERID = POS['value'];
        if (!this.ngValue && PARTNERID !== this.ngValue) {
            this.modelChanged(PARTNERID);
        }
    }

    modelChanged(e) {
        this.ngValue = e;
        if (this.ngModelCtrl) this.ngModelCtrl?.setValue(this.ngValue);
        this.change.emit(this.ngValue);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        if (obj !== this.ngValue) this.ngValue = obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};
}
