import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailFormatValidator } from '../../../validators/email.validators';

import * as states from '../us_states.json';
import * as canada from '../canada_states.json';
import * as country from '../country.json';
import { Subscription } from 'rxjs';
import { get, set, merge } from 'lodash';

import { POS_SYSTEMS } from '../../../components/pos-system/pos.systems.constants';
import { ActivatedRoute } from '@angular/router';
import { KeysComponent } from './keys/keys.component';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { formValidate, FormValidityCheck } from '../../../services/form.validation.service';
import { getTimezone, TimezoneService } from '../../../services/timezone.service';

export class DefaultFormGroup {
    public static get Instance() {
        return {
            name: new FormControl('', [Validators.required]),
            salesforceID: new FormControl('', []),
            partner_id: new FormControl('guestlist', [Validators.required]),
            email: new FormControl('', [Validators.required, emailFormatValidator]),
            siteContactName: new FormControl('', []),
            siteContactEmail: new FormControl('', [emailFormatValidator]),
            sendEmailSiteContact: new FormControl(true, []),
            dealerEmail: new FormControl('', [emailFormatValidator]),
            sendEmailDealer: new FormControl(true, []),
            timezone: new FormControl(getTimezone(), []),
            phone: new FormControl('', [Validators.required]),
            address: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required]),
            state: new FormControl('', [Validators.required]),
            country: new FormControl('USA', [Validators.required]),
            zip: new FormControl('', [Validators.required]),
            emailCredentials: new FormControl(true),
            demoAccount: new FormControl(false),
            // TODO: CEN-857
            site_id: new FormControl({ value: '', disabled: true }),
            username: new FormControl(''),
            user_id: new FormControl(''),
            enabled: new FormControl(true),

            // from the integrators component
            integrators: new FormControl({}),
            hasPointyError: new FormControl(false),
        };
    }

    public static getRawData() {
        const formGroup = new FormGroup(this.Instance);
        return formGroup.getRawValue();
    }
}

@Component({
    selector: 'location-form',
    styleUrls: ['../add-location.component.scss'],
    templateUrl: `./location.form.component.html`,
})
export class LocationFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isNewSite: boolean = true;
    @Input() readonly: boolean = true;
    @Input() pointyEmbedHoursOfOperations: boolean = true;

    @Output() onChanges: EventEmitter<any> = new EventEmitter();
    @Input() integrators: any = {};
    @Input() siteData: any = {};

    @Input() formGroup: FormGroup = new FormGroup(DefaultFormGroup.Instance, {
        updateOn: 'blur',
    });
    public country: any[] = (country as any).default;
    public states: any[] = (states as any).default;

    public supportedTimezones: any[] = [];

    public provinceName;
    public locationResolver;
    public opening_hours;

    private subscriptions = new Subscription();

    constructor(private route: ActivatedRoute, private timezoneService: TimezoneService, public dialog: MatDialog) {}

    public showKeys() {
        const dialogRef = this.dialog.open(KeysComponent, {
            data: {
                locationResolver: this.locationResolver,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((d) => {
                if (!d) return;
                const rawValue = this.formGroup.getRawValue();
                this.locationResolver = merge(rawValue, d);

                this.buildFormGroup(this.locationResolver);
                this.broadcastChange();
            });
    }

    buildFormGroup(params = this.locationResolver) {
        if (!params) return;

        const readonly = this.readonly;

        Object.keys(params).forEach((k) => {
            const value = get(params, k, '');
            const ctrl = this.formGroup.controls[k];
            if (ctrl) {
                ctrl.setValue(value);
            } else {
                this.formGroup.addControl(k, new FormControl({ value, disabled: readonly }));
            }
        });
        this.setFormControlsDisabledState(readonly);
        this.disableController('_id');
    }

    setFormControlsDisabledState(disable: boolean = true, ignoreValues: string[] = []) {
        const params = this.formGroup.getRawValue();
        Object.keys(params)
            .filter((a) => !ignoreValues.includes(a))
            .forEach((k) => this.disableController(k, disable));
    }

    private disableController(name, disable = true) {
        const ctrl = this.formGroup.controls[name];
        if (ctrl) ctrl[disable ? 'disable' : 'enable']({ onlySelf: true, emitEvent: true });
    }

    modelChange(e, formControlName) {
        const rawValue = this.formGroup.getRawValue();
        const value = get(rawValue, formControlName, null);
        const handler = {};
        set(handler, formControlName, value);
        this.broadcastChange();
    }

    private simpleChangesTrigger(changes) {
        return changes ? changes.currentValue !== changes.previousValue : null;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.simpleChangesTrigger(changes['readonly'])) {
            const rawValue = this.formGroup.getRawValue();
            const _disableState = ['site_id'];
            if (rawValue.name) _disableState.push('name');

            this.setFormControlsDisabledState(this.readonly, _disableState);
        }
    }

    onIntegratedApplicationChanges(e: any = {}) {
        delete e.opening_hours;
        delete e.hasPointyError;

        this.buildFormGroup(e);
        this.broadcastChange();
    }

    private broadcastChange() {
        //CEN-124 always send email dealer
        this.formGroup.controls['sendEmailDealer'].setValue(true);
        const rawData = this.formGroup.getRawValue();

        const POS = POS_SYSTEMS.find((p) => p.name === rawData.partner_id);
        if (POS) {
            rawData.partner_id = POS.value;
            this.formGroup.controls['partner_id'].setValue(rawData.partner_id);
        }

        this.onChanges.emit(rawData);
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.data.subscribe(({ LocationResolver }) => {
                this.locationResolver = Object.assign({}, LocationResolver || {}, this.siteData || {});

                this.buildFormGroup(LocationResolver);
                /**
                 * Build inputs for other components
                 */
                const rawValue = this.formGroup.getRawValue();
                this.integrators = rawValue['integrators'] || {};
                this.opening_hours = rawValue['opening_hours'] || {};
                this.setCountrySelect(rawValue.country);
            })
        );
        this.subscriptions.add(
            FormValidityCheck().subscribe((d) => {
                if (d) formValidate(this.formGroup);
            })
        );

        this.subscriptions.add(
            this.timezoneService.getTimezones().subscribe((d) => {
                this.supportedTimezones = d.map(({ description }) => {
                    const selected = getTimezone() === description;
                    return { name: description, value: description, selected };
                });
            })
        );
    }

    private setCountrySelect(e) {
        const rawValue = this.formGroup.getRawValue();
        if (rawValue.country !== e) return;

        switch (e) {
            case 'CA':
                this.provinceName = 'canada';
                this.states = (canada as any).default;
                break;
            default:
                this.provinceName = 'america';
                this.states = (states as any).default;
                break;
        }
    }

    onCountryChange(e) {
        this.setCountrySelect(e);
        this.modelChange(e, 'country');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
