/**
 * PHP version for GET params from url QUERY
 * @param key
 * returns *
 */
export function $_GET(key: string, search = _window().location.search) {
    const parts = search.substr(1).split('&');
    const $_GET = {};
    for (let i = 0; i < parts.length; i++) {
        const temp = parts[i].split('=');
        $_GET[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
    }
    return $_GET[key];
}

function _window(): any {
    return window;
}
