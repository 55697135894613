import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { get } from 'lodash';

class TokenStore {
    private static _instance: TokenStore;

    public token!: string;
    public detectChanges: EventEmitter<any> = new EventEmitter();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class IntegrationService {
    constructor(private http: HttpClient) {}

    public detectChange(key?) {
        TokenStore.Instance.detectChanges.emit(key);
    }

    public getChangeDetection() {
        return TokenStore.Instance.detectChanges;
    }

    private getOptions(token) {
        const options = {
            withCredentials: true,
        };

        if (token)
            Object.assign(options, {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${token}`,
                }),
            });

        return options;
    }

    public isSupport(token?): any {
        return this.http.get('/api/rules?access_levels=USER.SUPPORT', this.getOptions(token)).pipe(
            map((d) => {
                const salesforce = (get(d, 'access_levels', []) || []).includes('SALESFORCE');
                return salesforce ? false : get(d, 'hasRoles', false);
            })
        );
    }

    public sendMMData(params, token?): any {
        const startDate = params['startDate'];
        const endDate = params['endDate'];
        const site_id = params['site_id'];

        return this.http.put(
            `${environment.API_URL}/integrations/mobilemanager/${startDate}/${endDate}/${site_id}`,
            params,
            this.getOptions(token)
        );
    }

    public getSiteBySalesforceID(salesforceID, token?): any {
        return this.http.get(`${environment.API_URL}/salesforce/${salesforceID}`, this.getOptions(token));
    }

    public updateIntegrations(params, token?): any {
        return this.http.put(
            [`${environment.API_URL}/salesforce`, params.salesforceID].join('/'),
            params,
            this.getOptions(token)
        );
    }
}
