import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PosSystemComponent } from './posSystem.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [PosSystemComponent],
    imports: [CommonModule, FormsModule, MatSelectModule],
    exports: [PosSystemComponent, FormsModule, MatSelectModule],
    providers: [],
    entryComponents: [PosSystemComponent],
})
export class PosSystemModules {}
