import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';

import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [HeaderComponent],
    imports: [CommonModule, RouterModule],
    exports: [HeaderComponent],
    providers: [],
    entryComponents: [HeaderComponent],
})
export class HeaderModule {}
