<mat-form-field class="w-100">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select
        [(ngModel)]="_model"
        (ngModelChange)="modelChanged($event)"
        [placeholder]="'Select POS System'"
        [required]="required"
        [disabled]="readonly"
    >
        <mat-option *ngFor="let x of POSSystem" [value]="x.value">
            {{ x.name }}
        </mat-option>
    </mat-select>
</mat-form-field>

