import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarComponent } from './progress-bar.component';
import { DzProgressBarDirective } from './dz-progress-bar.directive';

@NgModule({
    declarations: [ProgressBarComponent, DzProgressBarDirective],
    imports: [CommonModule],
    exports: [ProgressBarComponent, DzProgressBarDirective],
    providers: [
        // UploadService,
        // {provide: UploadLoader, useClass: UploadFakeLoader},
        // {provide: USE_DEFAULT_SRC_TRANSFORM, useValue: (data: any) => data},
        // {provide: USE_DEFAULT_SRC, useValue: 'api/upload'},
    ],
    entryComponents: [ProgressBarComponent],
})
export class DzProgressBarModule {}
