import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'contact-hours-xeditor',
    templateUrl: `./hours.xeditor.component.html`,
    styleUrls: ['./hours.xeditor.component.scss'],
})
export class HoursXeditorComponent {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

    public params;

    submit(e) {
        this.onSubmit.emit(this.params);
    }

    close(e) {
        this.onClose.emit(true);
    }
}
