import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatesComponent } from './states.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [StatesComponent],
    imports: [CommonModule, FormsModule, MatSelectModule],
    exports: [StatesComponent, FormsModule, MatSelectModule],
    providers: [],
    entryComponents: [StatesComponent],
})
export class StatesModules {}
