<div class="modal-header">
    <h5 class="modal-title">Pointy Configurations</h5>
</div>
<div class="modal-body" #modalBody>
    <form [formGroup]="formGroup">
        <fieldset>
            <p class="sub-header">Default time that the POS is going to send the inventory data.</p>

            <section class="mb-0" *ngIf="!this.isNewSite">
                <gl-input [label]="'Retail Auth Key'" name="retailer_auth_key" formControlName="retailer_auth_key">
                </gl-input>
            </section>
            <section class="mb-0">
                <label class="label">Send Time:</label>
                <gl-timepicker id="sendTime" formControlName="sendTime"></gl-timepicker>
            </section>
        </fieldset>
        <span *ngIf="pointyEmbedHoursOfOperations">
            <hr />
            <fieldset>
                <hoursofoperations
                    [opening_hours]="opening_hours"
                    [sendTime]="sendTime"
                    (onChanges)="hrsOfOperationChanges($event)"
                ></hoursofoperations>
            </fieldset>
        </span>
    </form>
</div>
<div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-success ripple text-left text-capitalize py-1" (click)="submit($event)">
        Submit
    </button>
</div>

