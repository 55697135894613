import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Content, LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { CirclesComponent } from './components/circle-toggle/circles.component';
import { AppDrawerComponent, IAppDrawerComponent } from './components/app-drawer/app-drawer.component';
import { SITE_HEADER_INITIALIZER, APP_DRAWER_INITIALIZER, LayoutServices } from './layout.services';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserComponent } from './components/user/user.component';
import { AppHeaderbarComponent } from './components/app-headerbar/app-headerbar.component';
import { EmptyComponent } from './empty.component';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { ValidateUser } from '../auth/validate.user.service';
import { ChevronModule } from '../components/chevron/chevron.module';
import { ToasterModule } from '../components/toast/toast.module';

const PROVIDERS = [LayoutServices, ValidateUser];

@NgModule({
    declarations: [
        LayoutComponent,
        CirclesComponent,
        AppHeaderbarComponent,
        AppDrawerComponent,
        UserComponent,
        EmptyComponent,
    ],
    imports: [CommonModule, ToasterModule, RouterModule, HeaderModule, ChevronModule, FooterModule, ModalModule.forRoot()],
    exports: [LayoutComponent],
    providers: [
        { provide: SITE_HEADER_INITIALIZER, useValue: EmptyComponent },
        { provide: APP_DRAWER_INITIALIZER, useValue: EmptyComponent },
        ...PROVIDERS,
    ],
    entryComponents: [LayoutComponent, AppHeaderbarComponent, AppDrawerComponent, EmptyComponent],
})
export class LayoutModule {
    static withComponents<T>(components: {
        appDrawer?: IAppDrawerComponent | Content<T>;
        siteHeader?: any | Content<T>;
    }): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule,
            providers: [
                {
                    provide: SITE_HEADER_INITIALIZER,
                    useValue: components.siteHeader || EmptyComponent,
                },
                {
                    provide: APP_DRAWER_INITIALIZER,
                    useValue: components.appDrawer || EmptyComponent,
                },
                ...PROVIDERS,
            ],
        };
    }
}
