/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddLocationComponent } from './add-location.component';

import { LocationFormModule } from './location-form/location.form.module';

import { PageFooterModule } from 'src/app/components/pageFooter/footer.module';
import { NewSiteRootEntryResolver } from './root.entry.resolver';
import { HoursOfOperationsModule } from '../hoursOfOperations/hoursofoperations.module';
import { SubmitModule } from 'src/app/components/submit/submit.module';

@NgModule({
    declarations: [AddLocationComponent],
    imports: [CommonModule, LocationFormModule, HoursOfOperationsModule, SubmitModule, PageFooterModule],
    exports: [AddLocationComponent, LocationFormModule],
    providers: [NewSiteRootEntryResolver],
    entryComponents: [AddLocationComponent],
})
export class UserModule {}
