<div class="d-flex flex-grow-1 flex-column">
    <app-header></app-header>
    <div class="d-flex h-100">
        <div class="login-left-inner">
            <div #ref>
                <ng-content></ng-content>
            </div>
        </div>
        <div class="login-container">
            <div class="position-relative">
                <div class="login-box widget-box no-border" id="login-box">
                    <div class="widget-body">
                        <div class="toolbar clearfix position-relative">
                            <div class="signin-text">Sign In</div>
                        </div>

                        <div class="widget-main">
                            <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate="">
                                <fieldset class="">
                                    <div [ngSwitch]="fragment">
                                        <div *ngSwitchCase="'password'">
                                            <div class="field form-group">
                                                <gl-input
                                                    formControlName="email"
                                                    autofocus
                                                    [label]="'Enter your email address or site ID'"
                                                    [placeholder]="'Email'"
                                                >
                                                    <prefix class="icon-prepend">
                                                        <i class="fa fa-envelope"></i>
                                                    </prefix>
                                                    <suffix
                                                        class="icon-append"
                                                        [class.text-danger]="getInvalidState('email')"
                                                        [class.text-success]="getValidState('email')"
                                                    >
                                                        <i
                                                            class="fa"
                                                            [ngClass]="{
                                                                'fa-check-circle': getValidState('email'),
                                                                'fa-circle-notch': !getValidState('email')
                                                            }"
                                                        ></i>
                                                    </suffix>
                                                </gl-input>
                                            </div>
                                            <div
                                                class="alert alert-info"
                                                *ngIf="emailMessage"
                                                [innerHTML]="emailMessage"
                                            ></div>
                                            <a class="forgotpassword cursor-pointer" [routerLink]="['/signon']">
                                                <i class="fa fa-arrow-left"></i>
                                                Sign In
                                            </a>
                                        </div>
                                        <div *ngSwitchCase="'validate'">
                                            <div class="field form-group">
                                                <gl-input
                                                    name="password"
                                                    type="password"
                                                    [label]="'Enter your password'"
                                                    [placeholder]="'Password'"
                                                    formControlName="password"
                                                >
                                                    <prefix class="icon-prepend">
                                                        <i class="fa fa-lock"></i>
                                                    </prefix>
                                                    <suffix
                                                        class="icon-append"
                                                        [class.text-danger]="getInvalidState('password')"
                                                        [class.text-success]="getValidState('password')"
                                                    >
                                                        <i
                                                            class="fa"
                                                            [ngClass]="{
                                                                'fa-check-circle': getValidState('password'),
                                                                'fa-circle-notch': !getValidState('password')
                                                            }"
                                                        ></i>
                                                    </suffix>
                                                </gl-input>
                                            </div>
                                            <div class="field form-group">
                                                <gl-input
                                                    name="password"
                                                    type="password"
                                                    [label]="'Confirm password'"
                                                    [placeholder]="'Confirm password'"
                                                    validateEqual="password"
                                                    formControlName="passwordCopy"
                                                >
                                                    <prefix class="icon-prepend">
                                                        <i class="fa fa-lock"></i>
                                                    </prefix>
                                                    <suffix
                                                        class="icon-append"
                                                        [class.text-danger]="getInvalidState('passwordCopy')"
                                                        [class.text-success]="getValidState('passwordCopy')"
                                                    >
                                                        <i
                                                            class="fa"
                                                            [ngClass]="{
                                                                'fa-check-circle': getValidState('passwordCopy'),
                                                                'fa-circle-notch': !getValidState('passwordCopy')
                                                            }"
                                                        ></i>
                                                    </suffix>
                                                </gl-input>

                                                <a class="forgotpassword cursor-pointer" [routerLink]="['/signon']">
                                                    <i class="fa fa-arrow-left"></i>
                                                    Sign In
                                                </a>
                                            </div>
                                        </div>
                                        <div *ngSwitchDefault>
                                            <div class="field form-group">
                                                <gl-input
                                                    data-val="true"
                                                    formControlName="username"
                                                    autofocus
                                                    [label]="'Username'"
                                                    [placeholder]="'Username'"
                                                >
                                                    <prefix
                                                        class="icon-prepend"
                                                        [class.text-danger]="getInvalidState('username')"
                                                    >
                                                        <i class="fa fa-user"></i>
                                                    </prefix>
                                                    <suffix
                                                        class="icon-append"
                                                        [class.text-danger]="getInvalidState('username')"
                                                        [class.text-success]="getValidState('username')"
                                                    >
                                                        <i
                                                            class="fa"
                                                            [ngClass]="{
                                                                'fa-check-circle': getValidState('username'),
                                                                'fa-circle-notch': !getValidState('username')
                                                            }"
                                                        ></i>
                                                    </suffix>
                                                </gl-input>
                                            </div>
                                            <div class="field form-group">
                                                <gl-input
                                                    data-val="true"
                                                    name="password"
                                                    type="password"
                                                    [label]="'Password'"
                                                    [placeholder]="'Password'"
                                                    formControlName="password"
                                                >
                                                    <prefix
                                                        class="icon-prepend"
                                                        [class.text-danger]="getInvalidState('password')"
                                                    >
                                                        <i class="fa fa-lock"></i>
                                                    </prefix>

                                                    <suffix
                                                        class="icon-append"
                                                        [class.text-danger]="getInvalidState('password')"
                                                        [class.text-success]="getValidState('password')"
                                                    >
                                                        <i
                                                            class="fa"
                                                            [ngClass]="{
                                                                'fa-check-circle': getValidState('password'),
                                                                'fa-circle-notch': !getValidState('password')
                                                            }"
                                                        ></i>
                                                    </suffix>
                                                </gl-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field clearfix" style="margin-top: 25px">
                                        <button class="button btn-block ripple text-capitalize py-1">Submit</button>
                                    </div>
                                    <div class="mt-4 text-center" *ngIf="nextPage">
                                        <a
                                            class="forgotpassword cursor-pointer"
                                            [routerLink]="['/signon']"
                                            queryParamsHandling="merge"
                                            [fragment]="whereto"
                                            ><span>Forgot password?</span></a
                                        >
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer></footer>
</div>
<ng-template #template>
    <div class="modal-header bg-info radius-0">
        <button type="button" class="close" (click)="moveForward($event)"></button>
        <h5 class="modal-title text-white">Notification</h5>
    </div>
    <div class="modal-body">{{ notificationMessage }}</div>
    <div class="modal-footer d-flex justify-content-end">
        <button
            type="button"
            class="btn btn-block btn-outline-secondary ripple py-1 text-capitalize"
            (click)="moveForward($event)"
        >
            Continue
        </button>
    </div>
</ng-template>

<ng-template #redirectIframe>
    <iframe
        #iframeContainer
        [src]="notificationLink"
        height="100%"
        width="height%"
        style="margin: 0px; padding: 0px"
    ></iframe>
</ng-template>

