import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditLocationComponent } from './editlocation.component';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { ReactiveFormsModule } from '@angular/forms';

import { LocationService } from '../../../services/location.service';

import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LocationFormModule } from '../../location-data/location-form/location.form.module';
import { HoursOfOperationsModule } from '../../hoursOfOperations/hoursofoperations.module';
import { EditlocationService } from './editlocation.service';
import { SubmitModule } from 'src/app/components/submit/submit.module';

@NgModule({
    declarations: [EditLocationComponent],
    imports: [
        CommonModule,

        HttpClientModule,

        ReactiveFormsModule,
        LocationFormModule,
        SubmitModule,
        HoursOfOperationsModule,
        ModalModule.forRoot(),
        InputModule,
    ],
    exports: [EditLocationComponent],
    providers: [LocationService, EditlocationService],
    entryComponents: [EditLocationComponent],
})
export class EditLocationModules {}
