<span #vc></span>
<mat-form-field class="w-100" [class.d-none]="popup">
    <mat-label [innerHTML]="label"></mat-label>
    <mat-date-range-input
        [formGroup]="campaignOne"
        [max]="maxDate"
        [min]="minDate"
        [required]="required"
        [dateFilter]="datepickerFilter"
        [rangePicker]="datepicker"
    >
        <input formControlName="start" matStartDate />
        <input formControlName="end" matEndDate />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="datepicker">
        <i class="fa fa-calendar fa-2x" matDatepickerToggleIcon></i>
    </mat-datepicker-toggle>
    <mat-date-range-picker #datepicker (opened)="datepickerOpened($event)">
        <mat-date-range-picker-actions>
            <div class="d-flex justify-content-end w-100">
                <button
                    type="button"
                    class="cancelBtn btn btn-sm btn-light ripple py-1 text-left mr-2"
                    matDateRangePickerCancel
                    (click)="closeDatepicker($event)"
                >
                    {{ cancelLabel }}
                </button>
                <button
                    type="button"
                    class="applyBtn btn btn-sm btn-primary ripple py-1 text-left"
                    matDateRangePickerApply
                    (click)="setDatepicker($event)"
                >
                    {{ applyLabel }}
                </button>
            </div>
        </mat-date-range-picker-actions>
    </mat-date-range-picker>
</mat-form-field>

