import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { get } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/components/toast/toaster.service';
import { NewLocationToastComponent } from './newlocation.toast.component';
import { POS_SYSTEMS } from '../../components/pos-system/pos.systems.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { LocationService } from '../../services/location.service';
import { DefaultFormGroup } from './location-form/location.form.component';
import {
    buildFormGroupController,
    formValidate,
    FormValidityCheck,
    getFormGroupRawValue,
} from '../../services/form.validation.service';

@Component({
    selector: 'add-location',
    styleUrls: ['./add-location.component.scss'],
    templateUrl: `./add-location.component.html`,
})
export class AddLocationComponent implements OnInit, OnDestroy {
    @Input() opening_hours: any = {};
    @Input() sendTime: any = '04:00';

    public formGroup: FormGroup = new FormGroup({
        demoAccount: new FormControl(false),
    });

    private subscription = new Subscription();
    public hasPointyError: boolean = false;
    public isExistingUser: boolean = false;
    public submitted: boolean = false;

    constructor(
        private locationService: LocationService,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToasterService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnInit(): void {
        this.subscription.add(
            this.route.data.subscribe((d) => {
                if (d.NewSiteRootEntryResolver) {
                    this.formGroup = new FormGroup(DefaultFormGroup.Instance);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onChanges(e): void {
        this.buildFormGroup(e);
        this.isExistingUser = getFormGroupRawValue(this.formGroup, '_id');
    }

    buildFormGroup(params) {
        buildFormGroupController(this.formGroup, params);
    }

    hrsOfOperationChanges = (e): void => {
        const ctrl = this.formGroup.controls['opening_hours'];
        if (ctrl) ctrl.setValue(e);
        else this.formGroup.addControl('opening_hours', new FormControl(e));
    };

    demoChange(e) {
        this.formGroup.controls.demoAccount.setValue(e);
    }

    onSaveChange(e) {
        FormValidityCheck(true);
        if (!formValidate(this.formGroup)) {
            this.toasterService.error('Failed', 'Please fill out missing information', {
                delay: 3000,
            });

            return;
        }

        const rawData = this.formGroup.getRawValue();
        const POS = POS_SYSTEMS.find((p) => p.name === rawData.partner_id);
        if (POS) rawData.partner_id = POS.value;

        if (rawData.hasPointyError) {
            this.hasPointyError = rawData.hasPointyError;
            return;
        }

        const isUpdate = !!rawData.site_id;
        if (!isUpdate) delete rawData.site_id;

        if (!rawData.salesforceID) delete rawData.salesforceID;

        const observer: Observable<any> = isUpdate
            ? this.locationService.updateLocation(rawData.site_id, rawData)
            : this.locationService.addLocation(rawData);

        observer.subscribe(
            (data: any) => {
                const rawData = DefaultFormGroup.getRawData();
                delete rawData['operatingHrs'];

                // this.formGroup.reset(rawData);
                if (isUpdate) {
                    this.toasterService.success('Updated Successfully', `Name: ${data.name}`, {
                        delay: 3000,
                    });
                } else {
                    const factory = this.componentFactoryResolver.resolveComponentFactory(NewLocationToastComponent);
                    const componentRef = this.viewContainerRef.createComponent(factory);
                    componentRef.instance.params = data;
                    componentRef.instance.buildNotification();
                    this.toasterService.success('Add New Location', componentRef.instance.LayoutElement, {
                        onClose: () => {
                            return componentRef.instance.onDestroy;
                        },
                    });
                }

                /**
                 * Route back to site
                 */
                this.router
                    .navigate(['capi', 'admin-privileges', 'site-id', data['site_id']], {
                        queryParamsHandling: 'preserve',
                    })
                    .then();
            },
            (err) => {
                this.toasterService.error('Error', get(err, 'error.detail', 'Oops, something went wrong'), {
                    delay: 3000,
                });
            },
            () => (this.submitted = false)
        );
    }
}
