import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress.bar.component';
import { LoadingComponent } from './loading.component';
import { ProgressbarService } from './progressbar.service';

@NgModule({
    declarations: [ProgressBarComponent, LoadingComponent],
    imports: [CommonModule],
    providers: [ProgressbarService],
    entryComponents: [ProgressBarComponent, LoadingComponent],
    exports: [ProgressBarComponent, LoadingComponent],
})
export class ProgressbarModule {
    constructor() {}
}
