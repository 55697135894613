<header>
    Hours of Operations
    <a
        (click)="addRule($event)"
        *ngIf="!readonly"
        style="float: right"
        class="btn btn-sm btn-light ripple text-capitalize cursor-pointer"
    >
        <i class="fa fa-plus mr-1"></i>
        Add Rule
    </a>
</header>
<p class="sub-header">
    Provide locations's operating hrs. Add a new rule to customize different operating hrs. The most current rule will take
    precedence over the previous rule.
</p>
<div class="row form-horizontal">
    <section class="col col-12">
        <div class="table-responsive">
            <table class="table table-bordered border-right">
                <thead>
                    <tr>
                        <th class="rule-icon border-right-0"></th>
                        <th class="border-left-0">
                            <x-editor
                                [title]="'Day/Date Selection'"
                                [config]="{
                                    bodyRendererFramework: localBodyRendererFramework,
                                    params: selectionString,
                                    position: 'top'
                                }"
                                [(ngModel)]="selectionString.text"
                                [ngModelOptions]="{ standalone: true }"
                            ></x-editor>
                        </th>
                        <th>
                            <x-editor
                                [title]="'Hours of Operations'"
                                [config]="{
                                    bodyRendererFramework: localBodyRendererFramework,
                                    params: hrsString,
                                    position: 'top'
                                }"
                                [(ngModel)]="hrsString.text"
                                [ngModelOptions]="{ standalone: true }"
                            ></x-editor>
                        </th>
                        <th class="rule-control text-center">Closed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let rule of rules; let i = index; let f = first; let l = last">
                        <td class="rule-icon border-right-0 vertical-align vertical-middle text-center">
                            <div class="flex" *ngIf="!readonly">
                                <a class="icon-rule delete-icon" (click)="deleteRule($event, i)" *ngIf="i > 0">
                                    <i class="fa fa-trash-alt"></i>
                                </a>
                            </div>
                        </td>
                        <td class="rule-locale border-left-0 vertical-align vertical-middle text-center">
                            <div [ngSwitch]="i">
                                <div *ngSwitchCase="0" class="text-capitalize">Default</div>
                                <div *ngSwitchDefault>
                                    <mat-form-field class="w-100">
                                        <mat-select
                                            [(ngModel)]="rule.command"
                                            [placeholder]="'Day of week'"
                                            [ngModelOptions]="{ standalone: true }"
                                            [required]="true"
                                            [disabled]="readonly"
                                            (change)="onSelectChanges($event)"
                                        >
                                            <mat-option *ngFor="let x of rulesSelections" [value]="x.value">
                                                {{ x.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <td class="rule-text vertical-align vertical-middle text-center">
                            <div class="flex flex-column">
                                <div class="row">
                                    <section class="col col-6 m-0">
                                        <div class="row">
                                            <label class="control-label col col-md-5 m-0">Opening Time</label>
                                            <div class="col col-md-7">
                                                <x-editor
                                                    [title]="'Opening Time'"
                                                    [config]="{
                                                        bodyRendererFramework: xEditorHourComponent,
                                                        position: 'top'
                                                    }"
                                                    [(ngModel)]="rule.hrs.open"
                                                    [readonly]="readonly"
                                                    (ngModelChange)="onSelectChanges($event)"
                                                    [ngModelOptions]="{ standalone: true }"
                                                ></x-editor>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="col col-6 m-0">
                                        <div class="row">
                                            <label class="control-label col col-md-5 m-0">Closing Time</label>
                                            <div class="col col-md-7">
                                                <x-editor
                                                    [title]="'Closing Time'"
                                                    [config]="{
                                                        bodyRendererFramework: xEditorHourComponent,
                                                        position: 'top'
                                                    }"
                                                    [(ngModel)]="rule.hrs.close"
                                                    [readonly]="readonly"
                                                    (ngModelChange)="onSelectChanges($event)"
                                                    [ngModelOptions]="{ standalone: true }"
                                                ></x-editor>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </td>
                        <td class="rule-control vertical-align vertical-middle text-center">
                            <span *ngIf="i > 0">
                                <checkbox
                                    class="checkbox-gray pt-0"
                                    style="display: inline-block"
                                    [(ngModel)]="rule.closed"
                                    [readonly]="readonly"
                                    (ngModelChange)="onSelectChanges($event)"
                                    [ngModelOptions]="{ standalone: true }"
                                ></checkbox>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>
<ng-content select="[hours-of-operations]"></ng-content>

