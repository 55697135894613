import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationService } from '../services/location.service';

@Injectable()
export class LocationsResolver implements Resolve<any> {
    constructor(private locationService: LocationService) {}

    resolve(): Observable<any> | Promise<any> | any {
        return this.locationService.getLocations();
    }
}
