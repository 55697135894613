import { Component, ElementRef, EventEmitter, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { getTimefromString } from '../../../../utilities/getTimefromString';
import { formValidate } from '../../../../services/form.validation.service';
import { get } from 'lodash';

@Component({
    selector: 'add-pointy',
    templateUrl: './pointy.component.html',
    styleUrls: ['./pointy.component.scss'],
})
export class PointyComponent {
    @ViewChild('modalBody', { static: true }) modalBody!: ElementRef;
    @Input() isNewSite: boolean = true;
    @Input() pointyEmbedHoursOfOperations: boolean = true;
    @Input() opening_hours: any = {};
    @Input() sendTime: any = '04:00';

    onClose: EventEmitter<any> = new EventEmitter();
    subscriptions = new Subscription();

    public formGroup: FormGroup = new FormGroup({
        retailer_auth_key: new FormControl('', []),
        sendTime: new FormControl('04:00 AM', [Validators.required]),
    });

    constructor(public bsModalRef: BsModalRef) {}

    hrsOfOperationChanges = (e): void => {
        const ctrl = this.formGroup.controls['opening_hours'];
        if (ctrl) ctrl.setValue(e);
        else this.formGroup.addControl('opening_hours', new FormControl(e));
    };

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    buildFormData(params) {
        this.sendTime = get(params, 'sendTime', this.sendTime) || this.sendTime;
        this.opening_hours = get(params, 'opening_hours', this.opening_hours) || this.opening_hours;

        Object.keys(params).forEach((k) => {
            const control = this.formGroup.controls[k];
            if (control && params[k]) {
                if (k === 'sendTime') {
                    control.setValue(this.formatSendTime(params[k]));
                } else control.setValue(params[k]);
            } else {
                this.formGroup.addControl(k, new FormControl(params[k]));
            }
        });

        if (!this.isNewSite) {
            this.formGroup.controls['retailer_auth_key'].setValidators([Validators.required]);
        }
    }

    private formatSendTime(time) {
        return getTimefromString(time).date?.format('hh:mm A');
    }

    submit(e) {
        const rawData = this.formGroup.getRawValue();
        if (!formValidate(this.formGroup)) return;
        delete rawData.operatingHrs;

        this.onClose.emit(rawData);
        this.bsModalRef.hide();
    }

    closeMe(e) {
        const rawData = this.formGroup.getRawValue();
        delete rawData.operatingHrs;

        this.onClose.emit(rawData);
        this.bsModalRef.hide();
    }
}
