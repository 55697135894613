<form [formGroup]="formGroup">
    <header class="form-group">Contact Information</header>
    <div class="row">
        <div class="col-12 col-sm-6">
            <section class="form-group">
                <gl-input [label]="'Site Name'" [readonly]="readonly" [required]="true" formControlName="name"></gl-input>
            </section>
        </div>
        <div class="col-12 col-sm-6">
            <div class="row">
                <div class="col-12">
                    <section class="form-group">
                        <gl-input [label]="'CAPI Site ID'" [readonly]="true" formControlName="site_id">
                            <suffix>
                                <button
                                    style="float: right"
                                    (click)="showKeys()"
                                    class="btn btn-light btn-sm ripple text-capitalize radius-0"
                                >
                                    <i class="fa fa-key ml-1"></i>
                                </button>
                            </suffix>
                        </gl-input>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-6">
            <section class="form-group">
                <gl-input
                    [label]="'Site Contact Name'"
                    [readonly]="readonly"
                    (ngModelChange)="modelChange($event, 'siteContactName')"
                    formControlName="siteContactName"
                >
                </gl-input>
            </section>
            <section class="form-group icon-prepend">
                <gl-input
                    [label]="'Site Contact Email'"
                    [readonly]="readonly"
                    [required]="true"
                    (ngModelChange)="modelChange($event, 'email')"
                    formControlName="email"
                >
                    <small class="note">Check the box to send the on-boarding email to recipient</small>

                    <suffix>
                        <checkbox
                            [readonly]="readonly"
                            (change)="modelChange($event, 'emailCredentials')"
                            formControlName="emailCredentials"
                        >
                        </checkbox>
                    </suffix>
                </gl-input>
            </section>
            <section class="form-group">
                <gl-input
                    [label]="'Dealer Email'"
                    [readonly]="readonly"
                    (ngModelChange)="modelChange($event, 'dealerEmail')"
                    formControlName="dealerEmail"
                >
                </gl-input>
            </section>
        </div>

        <div class="col-12 col-sm-6">
            <section class="form-group">
                <pos-system
                    [readonly]="readonly"
                    formControlName="partner_id"
                    [label]="'POS System'"
                    (ngModelChange)="modelChange($event, 'partner_id')"></pos-system>
            </section>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-6">
            <section class="form-group">
                <gl-input
                    [label]="'Phone'"
                    [readonly]="readonly"
                    [required]="true"
                    (ngModelChange)="modelChange($event, 'phone')"
                    formControlName="phone"
                ></gl-input>
            </section>
        </div>
        <div class="col-12 col-sm-6 col-md-4">
            <section class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>Timezone</mat-label>
                    <mat-select formControlName="timezone" (ngModelChange)="modelChange($event, 'timezone')">
                        <mat-option *ngFor="let x of supportedTimezones" [value]="x.value">
                            {{ x.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </section>
        </div>
        <div class="col-12">
            <section class="form-group">
                <gl-input
                    [label]="'Address'"
                    [readonly]="readonly"
                    [required]="true"
                    (ngModelChange)="modelChange($event, 'address')"
                    formControlName="address"
                ></gl-input>
            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <section class="form-group">
                <gl-input
                    [label]="'City'"
                    [required]="true"
                    [readonly]="readonly"
                    (ngModelChange)="modelChange($event, 'city')"
                    formControlName="city"
                ></gl-input>
            </section>
        </div>
        <div class="col-12 col-md-3">
            <section class="form-group">
                <select-state
                    label="'State/Province'"
                    [required]="true"
                    [states]="provinceName"
                    [readonly]="readonly"
                    (ngModelChange)="modelChange($event, 'state')"
                    formControlName="state"
                ></select-state>
            </section>
        </div>
        <div class="col-12 col-md-3">
            <section class="form-group">
                <select-state
                    [required]="true"
                    [label]="'Country'"
                    [placeholder]="'Select Country'"
                    [readonly]="readonly"
                    (ngModelChange)="onCountryChange($event)"
                    [options]="country"
                    formControlName="country"
                ></select-state>
            </section>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-3">
            <section class="form-group">
                <gl-input
                    [label]="'Zip Code'"
                    [required]="true"
                    [readonly]="readonly"
                    (change)="modelChange($event, 'zip')"
                    formControlName="zip"
                ></gl-input>
            </section>
        </div>
        <div class="col-12 col-md-9 d-flex justify-content-end">
            <section class="form-group align-items-center d-flex">
                <checkbox [readonly]="readonly" (change)="modelChange($event, 'demoAccount')" formControlName="demoAccount">
                    This is a demo account
                </checkbox>
            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <section class="form-group">
                <integrated-applications
                    [site_id]="locationResolver['site_id']"
                    [partner_id]="locationResolver['partner_id']"
                    [opening_hours]="opening_hours"
                    [integrators]="integrators"
                    [readonly]="readonly"
                    [pointyEmbedHoursOfOperations]="pointyEmbedHoursOfOperations"
                    (onChanges)="onIntegratedApplicationChanges($event)"
                >
                </integrated-applications>
            </section>
        </div>
    </div>
</form>

