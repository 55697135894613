<app-header>
    <div class="d-none d-sm-flex header-toolbar-right primary-header-height">
        <app-headerbar class="d-none d-md-flex"></app-headerbar>
        <div class="btn-group">
            <a data-test="app-drawer" class="btn ripple box-60 d-flex align-items-center">
                <circle-toggle [status]="status" (onToggle)="toggleAppDrawer($event)"></circle-toggle>
            </a>
            <a test-data="logout-btn" class="btn ripple" (click)="signOut($event)">
                <i class="fa fa-lg fa-power-off"></i> <br />
                Logout
            </a>
        </div>
    </div>
</app-header>
<div #appdrawer></div>
<div #vc></div>
<main role="main" class="h-100 position-relative d-flex w-100" (scroll)="onScroll($event)" #maincontainer>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer>

