import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmitComponent } from './submit.component';

@NgModule({
    declarations: [SubmitComponent],
    imports: [CommonModule],
    exports: [SubmitComponent],
    entryComponents: [SubmitComponent],
})
export class SubmitModule {}
