<div class="app-header">Site Selection</div>

<div class="d-flex justify-content-start p-1">
    <search-bar
        [filterParams]="filterParams"
        [(ngModel)]="filterParams.name.value"
        [typedaheadDatasets]="typedaheadDatasets"
        (onChange)="onSearch($event)"
        (onClearFilter)="onClearFilter($event)"
    ></search-bar>
</div>

<div class="d-flex flex-grow-1 w-100 p-1">
    <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        (columnResized)="onColumnResized($event)"
    ></ag-grid-angular>
</div>

<div class="width-100 p-1">
    <button
        type="button"
        class="btn btn-success btn-sm ripple text-capitalize btn-block"
        [disabled]="!selectedRows.length"
        (click)="changeSite($event)"
    >
        Select
    </button>
</div>

