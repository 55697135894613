import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationService } from '../services/location.service';
import { get, set } from 'lodash';
import { map } from 'rxjs/operators';
import { AppState } from '../app.service';

@Injectable()
export class LocationResolver implements Resolve<any> {
    constructor(private locationService: LocationService, private appState: AppState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const site_id = get(route, 'params.cssite', get(route, 'queryParams.cssite', null));
        return this.locationService.getLocation(site_id).pipe(
            map((d) => {
                const isdefault = get(d, 'isdefault', !site_id);
                const payrollConnector = get(d, 'integrators.payrollConnector', {
                    active: false,
                });

                const hasPayrollConnector =
                    typeof payrollConnector === 'boolean' ? payrollConnector : get(payrollConnector, 'active', false);
                const locations = this.appState.get('locations', {});
                set(locations, `${d['site_id']}.hasPayrollConnector`, hasPayrollConnector);
                if (isdefault) set(locations, `${d['site_id']}.default`, isdefault);

                this.appState.set('locations', locations);
                return d;
            })
        );
    }
}
