<div class="modal-header {{ headerClass }} radius-0">
    <button type="button" class="close" (click)="decline($event)"></button>
    <h5 class="modal-title text-white">Confirm</h5>
</div>
<div class="modal-body">
    <p [innerHTML]="messageString"></p>
    <gl-input [placeholder]="confirmString" [(ngModel)]="comparableString"></gl-input>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button
        type="button"
        class="btn btn-outline-secondary ripple py-1 text-left float-left text-capitalize"
        (click)="decline($event)"
    >
        Cancel
    </button>

    <button
        type="button"
        class="btn btn-success ripple py-1 text-left text-capitalize d-flex align-items-end"
        (click)="confirm($event)"
    >
        Submit
    </button>
</div>

