import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { get } from 'lodash';

export interface ICheckbox {
    label: string;
    checked: boolean;
    children: ICheckbox[];
    HTMLElement?: HTMLElement;
    parent?: ICheckbox;
    indeterminate?: boolean;
    update?: EventEmitter<ICheckbox>;
}

class ObjectModel implements ICheckbox {
    label: string = '';
    checked: boolean = false;
    children: ICheckbox[] = [];
    update: EventEmitter<ICheckbox> = new EventEmitter();

    constructor(value) {
        this.checked = value;
    }
}

@Component({
    selector: 'checkbox[formControlName],checkbox[formControl],checkbox[ngModel]',
    styleUrls: ['./checkbox.component.scss'],
    templateUrl: `./checkbox.component.html`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CheckboxComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: CheckboxComponent,
            multi: true,
        },
    ],
})
export class CheckboxComponent {
    @ViewChild('checkboxRef', { static: true }) checkboxRef!: ElementRef;

    @Input() color: any = 'primary';
    @Input() readonly: boolean = false;
    @Input() id: string = this.uniqueID;
    @Input() name: string = '';
    @Input() required: boolean = false;
    @Input() indeterminate: boolean = false;
    @Input() checked: boolean = false;
    @Input() ngModelObject: ICheckbox = new ObjectModel(this.ngValue);
    @Input() ngParentObject!: ICheckbox;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() ready: EventEmitter<any> = new EventEmitter<any>();

    public _model: boolean = false;
    public _disabled: boolean = false;
    public ngModelCtrl: FormControl = new FormControl(false, []);

    constructor(private elementRef: ElementRef) {}

    private updateChildren(children, status) {
        if (children && children.length)
            children.map((c) => {
                if (c.hasOwnProperty('disabled') && c.disabled) {
                } else {
                    c.checked = !!status;
                    c.indeterminate = false;
                }

                if (c.children) this.updateChildren(c.children, status);
            });
    }

    private getChildren(obj) {
        return obj && obj.children && obj.children.length ? obj.children.filter((c) => c) : [];
    }

    private checkSiblings() {
        const parent = this.ngParentObject || this.ngModelObject;
        const siblings = this.getChildren(parent);

        const checked = siblings.filter((s: any) => s.checked);
        const indeterminate = siblings.filter((s) => s.indeterminate);
        const all = checked.length === siblings.length;

        this.setParent(parent, checked.length ? !all : !!indeterminate.length, all);
    }

    private setParent(parent, indeterminate: boolean = true, checked: boolean = false) {
        parent.indeterminate = indeterminate;
        parent.checked = checked;
    }

    get uniqueID() {
        const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
    }

    modelChanged({ checked, source }) {
        this.ngValue = checked;
        if (this.ngModelObject && this.ngModelObject.children) this.updateChildren(this.ngModelObject.children, checked);
        this.checkSiblings();
        this.change.emit(this.ngValue);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    set ngValue(bool: boolean) {
        this.ngModelObject.checked = bool;
        this._model = bool;
    }

    set disabled(v: boolean) {
        this._disabled = v;
    }

    get disabled() {
        return this._disabled;
    }

    get ngValue() {
        return this._model;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.indeterminate) {
            const { currentValue, previousValue } = changes.indeterminate;
            if (!!currentValue !== !!previousValue) this.checkSiblings();
        }

        if (changes.readonly) {
            const { currentValue, previousValue } = changes.readonly;
            if (!!currentValue !== !!previousValue) {
                this.setDisabledState(currentValue);
                this.setIfDisableRequired();
            }
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(obj: boolean): void {
        if (!!obj !== this.ngValue) {
            this.ngValue = !!obj;
        }
    }

    validate(ctrl: AbstractControl): ValidationErrors | null {
        let invalid = null;
        this.required = get(ctrl, 'errors.required', this.required || false);

        setTimeout((d) => {
            (this.ngModelCtrl as any) = ctrl ? ctrl : this.ngModelCtrl;
            this.setDisabledState(this.readonly);
            this.setIfDisableRequired();
        });

        return invalid;
    }

    private setIfDisableRequired() {
        if (this.disabled && this.ngModelCtrl) this.ngModelCtrl.disable({ onlySelf: true, emitEvent: true });
        else if (this.ngModelCtrl) this.ngModelCtrl.enable({ onlySelf: true, emitEvent: true });
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement as Element as HTMLElement;
    }
}
