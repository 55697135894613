import { Component } from '@angular/core';

/**
 * Created by Ramor11 on 3/31/2017.
 */

@Component({
    selector: 'create-location',
    template: ` <router-outlet></router-outlet> `,
    styleUrls: ['./root.entry.component.scss'],
})
export class RootEntryComponent {}
