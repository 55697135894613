import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { groupBy } from '../../../utilities/groupBy';
import { map, mergeMap } from 'rxjs/operators';
import { get } from 'lodash';
import { saveAs } from 'file-saver';

declare const moment: any;
import { environment } from 'src/environments/environment';
import { $_GET } from '../../../utilities/$_GET';
import { object2queryString } from '../../../utilities/object2queryString';
import { JwtService } from 'src/app/services/local_storage.service';
import { ValidateResponse } from './validate_response';
import { Observable } from 'rx';

@Injectable()
export class PayrollService {
    private totalCount: number = 0;
    private startdate!: string;
    private endDate!: string;
    private headers = new HttpHeaders();
    constructor(private http: HttpClient, jwtService: JwtService) {
        this.headers = this.headers.append('Authorization', `Bearer ${jwtService.getJwt()}`);
    }

    get WeekBeforeToday(): any {
        return environment.isProduction ? moment().subtract(7, 'days').format('MM-DD-YYYY') : '01-01-1970';
    }

    getClient(): any {
        return this.http.get(`${environment.API_BASE}/pos/client`, { headers: this.headers });
    }

    dataDump(staffmember, startdate: string = this.WeekBeforeToday, enddate: string = ''): any {
        let filename = 'payroll_';
        filename += '_' + moment(this.startdate).format('YYYYMMDDHHmm');
        filename += '_' + moment(this.endDate).format('YYYYMMDDHHmm');
        if (staffmember) filename += '_' + staffmember;
        filename = `${filename}.csv`;

        const url = [
            `${environment.API_BASE}/payroll/export`,
            'right/now',
            staffmember ? staffmember : '',
            `?${this.getQueryString({
                startdate,
                enddate,
            })}`,
        ]
            .filter((a) => a)
            .join('/');

        return this.http
            .get(url, {
                responseType: 'blob',
                headers: this.headers,
            })
            .subscribe((blob) => saveAs(blob, filename));
    }

    getMapValidate() {
        return this.http.get<ValidateResponse>(`${environment.API_URL}/maps/validate`, { headers: this.headers });
    }

    get Count() {
        return this.totalCount;
    }

    getPayroll(
        startdate: string = this.WeekBeforeToday,
        endDate: string = '',
        skip: number = 0,
        limit: number = 5,
        aggregate = null
    ): any {
        const fields: any[] = [];

        if (this.startdate !== startdate || this.endDate !== endDate) {
            this.startdate = startdate;
            this.endDate = endDate;
            this.totalCount = 0;
        }
        if (!this.totalCount) fields.push('count');

        const options = { headers: this.headers };
        if (aggregate)
            Object.assign(options, {
                params: {
                    aggregate: JSON.stringify(aggregate),
                },
            });

        const url = (count: boolean = false) => {
            return [
                environment.API_URL,
                'payroll',
                count ? 'count' : '',
                startdate,
                endDate,
                `?${this.getQueryString({
                    skip: `${skip}`,
                    limit: `${limit}`,
                })}`,
            ]
                .filter((a) => a)
                .join('/');
        };

        return this.http.get(url(true), options).pipe(
            mergeMap((totalCount) =>
                this.http.get(url(), options).pipe(
                    map((d) => ({
                        documents: d,
                        totalCount,
                    }))
                )
            ),
            map((d) => {
                this.totalCount = d['totalCount'] as number;
                const documents = get(d, 'documents', []).filter((d) => d.amounttype === 'H');

                const _users = groupBy(documents, 'staffmember');
                const _user: any[] = [];

                Object.keys(_users).forEach((staffserver) => {
                    const departments = _users[staffserver];
                    const employeename = get(departments, '0.staffserver_mapped.staffmember_name', '');
                    /**********************************************************************
                     * ADDING THE TOTAL ROW FOR EACH DEPARTMENTS
                     */
                    const _grandkai: any = {
                        departmentdesc: 'Total',
                    };
                    const grouped = {
                        employeesalestotal: {
                            name: 'employeesalestotal',
                            toFixed: 2,
                        },
                        totalhours: {
                            name: 'hours',
                            toFixed: 2,
                        },
                        totaltips: {
                            name: 'totaltips',
                            toFixed: 2,
                        },
                        totalwages: {
                            name: 'totalwages',
                            toFixed: 2,
                        },
                        cashtips: {
                            name: 'cashtips',
                            toFixed: 2,
                        },
                        creditcardtips: {
                            name: 'creditcardtips',
                            toFixed: 2,
                        },
                    };

                    // const time_attendance = get(departments, '0.time_attendance', []);
                    let time_attendance = [];
                    const _departments = {};

                    departments.forEach((d) => {
                        _departments[d.staffdepartment] = d.time_attendance;
                        Object.keys(grouped).forEach((k) => {
                            const _add = d[k] ? (typeof d[k] === 'string' ? parseFloat(d[k]) : d[k]) : 0;
                            if (_grandkai.hasOwnProperty(k)) {
                                _grandkai[k] += _add;
                            } else _grandkai[k] = _add;
                        });
                    });

                    Object.keys(_departments)
                        .map((_d) => _departments[_d])
                        .forEach((t) => {
                            time_attendance = time_attendance.concat(t);
                        });

                    Object.keys(_grandkai).forEach((_g) => {
                        if (grouped.hasOwnProperty(_g)) _grandkai[_g] = _grandkai[_g].toFixed(grouped[_g].toFixed);
                    });

                    departments.push(_grandkai);
                    //ADDING THE TOTAL ROW FOR EACH DEPARTMENTS

                    const handler = Object.assign(
                        {},
                        {
                            staffserver,
                            employeename,
                            departments,
                            time_attendance,
                            ..._grandkai,
                        }
                    );

                    _user.push(handler);
                });
                return _user;
            })
        );
    }

    uploadPayroll(startdate: string = this.WeekBeforeToday, endDate: string = ''): any {
        return this.http.post(`${environment.API_URL}/pos/timeclock/${startdate}/${endDate}`, {}, { headers: this.headers });
    }

    getQueryString(obj = {}) {
        obj['hrpos'] = ($_GET('hrpos') || '').toLowerCase() === 'true';
        return object2queryString(obj);
    }

    exportPayroll(startdate: string = this.WeekBeforeToday, endDate: string = ''): any {
        const url = [
            environment.API_URL,
            '/p/export',
            startdate,
            endDate,
            `?${this.getQueryString({
                format: 'json',
                fields: 'textfile,filename,textToJson,uploadsite',
            })}`,
        ]
            .filter((a) => a)
            .join('/');

        return this.http.get(url, { headers: this.headers });
    }
}
