import { Injectable } from '@angular/core';

export type InternalStateType = {
    [key: string]: any;
};

class AppData {
    private static _instance: AppData;
    public _data: InternalStateType = {};

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class AppState {
    // @ts-ignore
    constructor() {
        (window as any).getAppState = () => {
            return this.get();
        };
    }

    // already return a clone of the current state
    public get state() {
        return AppData.Instance._data;
    }

    // never allow mutation
    public set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }

    public reset() {
        AppData.Instance._data = this._clone({});
    }

    public flush = () => this.reset();

    /**
     * Delete the key from memory, this will not search within nested key reference, only the parent key
     * @param key
     */
    public remove(key: string, _state = AppData.Instance._data): any {
        if (typeof key !== 'string') return false;
        const jsonParts = this.getJSONParts(key);
        let result: any;

        if (jsonParts.length > 0 && _state.hasOwnProperty(jsonParts[0])) {
            result = _state[jsonParts[0]];
            jsonParts.splice(0, 1);
            if (jsonParts.length > 0) {
                this.remove(jsonParts.join('.'), result);
            } else delete _state[key];
        } else {
            result = _state[jsonParts[0]] = {};
            jsonParts.splice(0, 1);

            if (jsonParts.length > 0) {
                this.remove(jsonParts.join('.'), result);
            } else {
                delete _state[key];
            }
        }

        delete _state[key];
        AppData.Instance._data = _state;
    }

    /**
     * Get the key value from the Object path.
     *
     * @example
     *
     *  return get('first.second[index].param')
     *
     * @param prop
     * @param {any} obj
     * @returns {any}
     */
    public get(prop?: any, defaultValue: any = null, mutate: boolean = true, obj = AppData.Instance._data) {
        if (typeof prop !== 'string') return obj;

        const re = /\[|\]|\./g;
        let jsonParts = prop.trim().split(re);
        let result: any = defaultValue;

        jsonParts = jsonParts.filter(function (v) {
            return v !== '';
        });

        if (obj && jsonParts.length > 0 && obj.hasOwnProperty(jsonParts[0])) {
            result = obj[jsonParts[0]];
            jsonParts.splice(0, 1);
            if (jsonParts.length > 0) result = this.get(jsonParts.join('.'), defaultValue, true, result);
        }

        if (!mutate)
            try {
                result = JSON.parse(JSON.stringify(result));
            } catch (e) {}

        return typeof result === 'undefined' ? defaultValue : result;
    }

    private getJSONParts(string) {
        const re = /\[|\]|\./g;
        let jsonParts = string.trim().split(re);

        jsonParts = jsonParts.filter(function (v) {
            return v !== '';
        });

        return jsonParts;
    }

    /**
     * Set the key value from the Object path. If the object does not exist, it will
     * generate the path based on the key string reference
     *
     * @example
     *
     *  return set('first.second[0].param', string) =
     *  {
     *    first:{
     *      second:[
     *        {
     *          params: 'string'
     *        }
     *      ]
     *    }
     *  }
     *
     * @param prop
     * @param {any} value
     * @returns {any}
     */

    public set(prop: string, value: any, _state = AppData.Instance._data) {
        // internally mutate our state

        if (typeof prop !== 'string') return false;
        const jsonParts = this.getJSONParts(prop);
        let result: any;

        if (jsonParts.length > 0 && _state.hasOwnProperty(jsonParts[0])) {
            result = _state[jsonParts[0]];
            jsonParts.splice(0, 1);
            if (jsonParts.length > 0) {
                this.set(jsonParts.join('.'), value, result);
            } else {
                _state[prop] = typeof value !== 'undefined' ? value : result;
            }
        } else {
            result = _state[jsonParts[0]] = {};

            jsonParts.splice(0, 1);

            if (jsonParts.length > 0) {
                this.set(jsonParts.join('.'), value, result);
            } else {
                _state[prop] = value;
            }
        }

        AppData.Instance._data = _state;
        return _state[prop];
    }

    private _clone(object: InternalStateType) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    }
}
