import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LocationFormComponent } from './location.form.component';

import { InputModule } from 'src/app/components/Inputs/input.module';
import { PointyModule } from '../../integrations/modals/pointy/pointy.module';
import { KeysModule } from './keys/keys.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckboxModule } from 'src/app/components/checkbox/checkbox.module';
import { PosSystemModules } from 'src/app/components/pos-system/posSystem.modules';
import { StatesModules } from 'src/app/components/select-states/states.modules';
import { Select2Module } from 'src/app/components/select2/select2.module';
import { IntegrationsModule } from '../../integrations/integrations.module';
import { WelcomeEmailModule } from '../../welcomeEmail/welcome.email.module';

@NgModule({
    declarations: [LocationFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        Select2Module,
        PosSystemModules,
        InputModule,
        PointyModule,
        IntegrationsModule,
        WelcomeEmailModule,
        StatesModules,
        KeysModule,
        MatInputModule,
        CheckboxModule,
        MatSelectModule,
        MatDialogModule,
    ],
    exports: [
        LocationFormComponent,
        ReactiveFormsModule,
        Select2Module,
        MatInputModule,
        CheckboxModule,
        MatSelectModule,
        MatDialogModule,
    ],
    entryComponents: [LocationFormComponent],
})
export class LocationFormModule {}
