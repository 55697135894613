import { Injectable, InjectionToken } from '@angular/core';

class AccessLevelClass {
    private static _instance: AccessLevelClass;
    private $accesslevels: any[] = [];

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    get AccessLevel() {
        return this.$accesslevels;
    }

    /**
     * Set the Access Level in a sigleton
     * @param access_level
     * @constructor
     */
    set AccessLevel(access_levels: any) {
        access_levels = (Array.isArray(access_levels) ? access_levels : [access_levels]).map((k) =>
            !isNaN(Number(k)) ? Number(k) : k
        );
        const defaultRoutes = access_levels.filter((k) => k && `${k}`.toUpperCase().indexOf('_DEFAULT') > 0);
        const _access_permissions = defaultRoutes.map((a) => a.replace('_DEFAULT', ''));
        this.$accesslevels = access_levels.concat(_access_permissions);
    }
}

export const AUTH_SERVICE = new InjectionToken<any>('AUTH_SERVICE');

@Injectable()
export class AuthService {
    get AccessLevel(): any {
        return AccessLevelClass.Instance.AccessLevel;
    }

    set AccessLevel(access_level: any) {
        AccessLevelClass.Instance.AccessLevel = access_level;
    }
}
