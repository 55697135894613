<mat-form-field class="w-100" [attr.id]="id">
  <mat-label *ngIf="label" [innerHTML]="label"></mat-label>
  <span matPrefix><ng-content select="prefix"></ng-content></span>
  <input [type]="type" matInput
         [attr.name]="name"
         [placeholder]="placeholder"
         [errorStateMatcher]="errorStateMatcher"
         [pattern]="pattern"
         #inputRef
         class="truncate"
         [(ngModel)]="_model"
         [required]="required"
         [formControl]="ngModelCtrl"
         [matAutocomplete]="matAutocomplete"
         data-test="input-field"
  >


  <span matSuffix><ng-content select="suffix"></ng-content></span>

  <mat-hint>
    <ng-content select="small"></ng-content>
  </mat-hint>
  <mat-error>
    <ng-content select="gl-error"></ng-content>
  </mat-error>
  <ng-content></ng-content>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of mtAutoCompleteOptions" [value]="option">
    {{option}}
  </mat-option>
</mat-autocomplete>
