import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { SITE_HEADER_INITIALIZER } from '../../layout.services';
import { Content } from '../../layout.component';

@Component({
    selector: 'app-headerbar',
    template: ` <div #vc></div> `,
})
export class AppHeaderbarComponent<T> implements OnInit {
    @ViewChild('vc', { read: ViewContainerRef, static: true })
    private vc!: ViewContainerRef;

    constructor(
        @Inject(SITE_HEADER_INITIALIZER) private siteHeader: Content<T>,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        this.vc.clear();
        const factory = this.componentFactoryResolver.resolveComponentFactory(this.siteHeader as any);
        this.vc.createComponent(factory);
    }
}
