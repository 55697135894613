import { EventEmitter, Injectable } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { $_GET } from './utilities/$_GET';
import { get } from 'lodash';
import { Router } from '@angular/router';
import { JwtService } from './services/local_storage.service';
import { ProgressbarService } from './components/mdProgressBar/progressbar.service';
import { ToasterService } from './components/toast/toaster.service';
export class XHRStatusEmitter {
    private static _instance: XHRStatusEmitter;

    onStatusChange: EventEmitter<any> = new EventEmitter<any>();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class HttpClientModuleInterceptor implements HttpInterceptor {
    constructor(
        private toasty: ToasterService,
        private router: Router,
        private progressbar: ProgressbarService,
        private jwtServive: JwtService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const site_id = $_GET('cssite');
        const partner = $_GET('partner');

        const headers = {
            'Content-Type': 'application/json',
        };
        let token = this.jwtServive.getJwt();
        if (token) headers['Authorization'] = `Bearer ${token}`;

        //for any json files bypass the header request and just handle it
        if (req.url.endsWith('.json')) return next.handle(req);
        if (req.method === 'JSONP') return next.handle(req);

        const reqHeaders = req.headers;
        const headHandlers = {};

        try {
            const keys = req.headers.keys();
            keys.forEach((k) => (headHandlers[k] = reqHeaders.get(k)));
        } catch (e) {}

        if (!!site_id) {
            Object.assign(headers, { 'CS-SiteId': `${site_id}` });
        }

        if (!!partner) {
            Object.assign(headers, { 'CS-Partner': `${partner}` });
        }

        Object.assign(headers, headHandlers);
        Object.keys(headers).forEach((k) => {
            if (headers[k] === 'DELETE') delete headers[k];
        });

        const suppress = req.params.get('suppress') || false;
        const newReq = req.clone({
            headers: new HttpHeaders(headers),
        });

        let ok: string;

        XHRStatusEmitter.Instance.onStatusChange.emit('REQUEST');

        this.progressbar.start();

        return next.handle(newReq).pipe(
            tap(
                (e: any) => {
                    ok = e instanceof HttpResponse ? 'succeeded' : '';
                },
                (err) => {
                    ok = 'failed';
                }
            ),
            // Log when response observable either completes or errors
            finalize(() => {
                XHRStatusEmitter.Instance.onStatusChange.emit(ok.toUpperCase());
                setTimeout((d) => this.progressbar.stop(), 1000);
            }),
            catchError((error: HttpErrorResponse) => {
                if (suppress === 'notification') return throwError(error);

                XHRStatusEmitter.Instance.onStatusChange.emit('CATCHERROR');
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    this.errorParser(error.error);
                } else {
                    const hasDetails = !!get(error, 'error.detail', '');
                    const message = get(error, 'error.detail', get(error, 'error.message', error.message));

                    if (hasDetails) {
                        this.errorParser(error);
                    } else if (error.status && message) {
                        errorMessage = `Error Code: ${error.status}\nMessage: ${message}`;
                        this.toasty.error('Error', errorMessage, {
                            delay: 10000,
                        });
                    }
                }

                const status = error['status'] || 500;
                switch (status) {
                    case 404:
                        this.router
                            .navigate(['oops', '404'], {
                                skipLocationChange: true,
                            })
                            .then((d) => {});

                        break;
                    default:
                        break;
                }

                return throwError(error);
            })
        );
    }

    private errorParser(e) {
        const isError = (error) => {
            const _defaultMessageString = 'Server Error';
            const _message = get(error, 'message', get(error, 'detail', _defaultMessageString));
            const message = typeof _message === 'string' ? _message : _defaultMessageString;
            const _defaultMessageTitle = get(error, 'title', 'Error');
            const detail = get(error, 'detail');
            const title = get(detail, 'title', get(error, 'statusText', _defaultMessageTitle));

            return {
                title,
                message,
            };
        };

        const errorObj = e.hasOwnProperty('error') ? isError(e['error']) : isError(e);

        this.toasty.error(errorObj.title, errorObj.message, {
            delay: 10000,
        });

        return `Error: ${errorObj.message}`;
    }
}

export let HttpClientModuleInterceptorProvider: any = [
    ProgressbarService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpClientModuleInterceptor,
        multi: true,
    },
];

