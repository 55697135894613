export function getStatus(status) {
    const isReading = status.indexOf('READING') > -1;
    const isWriting = status.indexOf('WRITING') > -1;
    const isDone = status.indexOf('DONE') > -1;
    const isProcessing = status.indexOf('PROCESSING') > -1;
    const isDeleted = status.indexOf('DELETED') > -1;
    const isError = status.indexOf('ERROR') > -1;
    const isFailed = status.indexOf('FAILED') > -1;

    let _status;
    let _color;

    if (isReading) {
        _status = 'READING';
        _color = 'badge-warning';
    }
    if (isProcessing) {
        _status = 'PROCESSING';
        _color = 'badge-warning';
    }
    if (isDone) {
        _status = 'DONE';
        _color = 'badge-success';
    }
    if (isDeleted) {
        _status = 'DELETED';
        _color = 'badge-secondary';
    }
    if (isReading) {
        _status = 'READING';
    }
    if (isWriting) {
        _status = 'WRITING';
        _color = 'badge-info';
    }

    if (isError || isFailed) {
        _status = 'FAILED';
        _color = 'badge-danger';
    }

    return {
        status: _status,
        color: _color,
    };
}
