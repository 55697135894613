import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { DropzoneComponent } from './dropzone.component';
import { DzPreviewComponent } from './preview/dz-preview.component';
import { DropzoneDirective } from './dropzone.directive';
import { DropzoneService } from './services/dropzone.service';

import { UploadService, USE_DEFAULT_SRC, USE_DEFAULT_SRC_TRANSFORM } from './services/upload.service';
import { UploadFakeLoader, UploadLoader } from './services/upload.loader';
import { FormsModule } from '@angular/forms';
import { DropzoneUploaderObservableWorker } from './services/dropzone.uploader.observable.worker';
import { DzProgressBarModule } from './progress/dz-progress-bar.module';
import { InputDialogModule } from './input-dialog/input.dialog.module';

export interface IDropzoneModuleConfig {
    useDefaultSrc?: string;
    useDefaultTransform?: (data) => any;
    loader?: Provider;
}

/**
 * Handles the format for each individual file return from the server.
 * Each file is send individual.
 */
export function callbackFactory() {
    return (d) => (Array.isArray(d) && d[0] ? d[0] : Object.keys(d).length ? d : {});
}

@NgModule({
    declarations: [DropzoneComponent, DzPreviewComponent, DropzoneDirective],
    imports: [CommonModule, DzProgressBarModule, InputDialogModule, FormsModule],
    providers: [
        DropzoneService,
        UploadService,
        DropzoneUploaderObservableWorker,
        { provide: UploadLoader, useClass: UploadFakeLoader },
        { provide: USE_DEFAULT_SRC_TRANSFORM, useValue: (data: any) => data },
        { provide: USE_DEFAULT_SRC, useValue: 'api/upload' },
    ],
    exports: [DropzoneComponent, DzProgressBarModule, DzPreviewComponent, DropzoneDirective],
    entryComponents: [DropzoneComponent, DzPreviewComponent],
})
export class DropzoneModule {
    static forRoot(config: IDropzoneModuleConfig): ModuleWithProviders<DropzoneModule> {
        return {
            ngModule: DropzoneModule,
            providers: [
                {
                    provide: USE_DEFAULT_SRC_TRANSFORM,
                    useFactory: config.useDefaultTransform ? config.useDefaultTransform : callbackFactory,
                },
                {
                    provide: USE_DEFAULT_SRC,
                    useValue: config.useDefaultSrc ? config.useDefaultSrc : 'api/upload',
                },
                DropzoneService,
                UploadService,
            ],
        };
    }
}
