import * as moment from 'moment';

export function getTimefromString(time: string = moment().format('HH:mm A')) {
    if (!time)
        return {
            hours: 0,
            minutes: 0,
        };

    //if no time is provided it uses the current time
    const _t = time.split(':').map((f) => parseFloat(f));
    const _z = time.split(' ').pop();

    if (!_z || !_t) throw 'Error getting time from date';

    _t[0] = _z.toUpperCase() === 'PM' && _t[0] !== 12 ? 12 + _t[0] : _t[0];
    _t[0] = _t[0] === 24 ? 0 : _t[0];

    return {
        hours: _t[0],
        minutes: _t[1],
        date: moment().hours(_t[0]).minutes(_t[1]).seconds(0).milliseconds(0),
        getHour: () => _t[0],
        getMinutes: () => _t[1],
    };
}

/**
 * Return String format for
 * @param time
 */
export function getTimeStringFromMidnight(time: number): string {
    let _hr = 12;
    let _minutes = 0;
    let _zone = 'AM';

    const hrs = Math.floor(time / 60); //minutes per hr
    const minutes = time - hrs * 60;

    _hr = hrs > 0 ? hrs : _hr;
    _minutes = minutes > 0 ? minutes : _minutes;

    if (hrs > 11) {
        _zone = 'PM';
        _hr = hrs > 12 ? hrs - 12 : hrs;
    }

    const _timeString = `${('0' + _hr).slice(-2)}:${('0' + _minutes).slice(-2)} ${_zone}`;
    const _fromString: any = getTimefromString(_timeString);
    _fromString.toString = () => _timeString;

    return _fromString;
}
