import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostBinding,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { WindowEvents } from '../window/WindowRef.service';
import { delay } from 'rxjs/operators';
import { Content } from './ng-content-injection.directive';

@Component({
    selector: 'ngcontent-injector',
    template: '<div #injectHere></div>',
})
export class NgContentInjectionComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('injectHere', { read: ViewContainerRef, static: true })
    private injectHere!: ViewContainerRef;

    @HostBinding('class.position-absolute') absolute: boolean = true;
    @HostBinding('style.left') styleLeft: string = '-9999999999px';
    @HostBinding('style.z-index') zIndex: number = 2;
    @Output() onClose: EventEmitter<MouseEvent> = new EventEmitter();

    private target!: HTMLElement;
    private offset = {};
    private subscriptions: Subscription = new Subscription();

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    public CloseDialogBox(e: MouseEvent) {
        this.injectHere.clear();
        this.onClose.emit(e);
    }

    private isLocalNode(node, target) {
        do {
            if (node === target) {
                return true;
            }
            target = target.parentNode;
        } while (target);
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.CloseDialogBox(this.LayoutElement);
    }

    ngAfterContentInit(): void {
        this.renderer.appendChild(document.body, this.LayoutElement);
    }

    ngOnInit(): void {
        const _mouseEvents: any = fromEvent(document, 'click');
        this.subscriptions.add(WindowEvents().OnScrollEvent.subscribe((e) => this.CloseDialogBox(this.LayoutElement)));
        this.subscriptions.add(WindowEvents().OnResizeEvent.subscribe((e) => this.CloseDialogBox(this.LayoutElement)));
        this.subscriptions.add(
            _mouseEvents.pipe(delay(100)).subscribe((e: any) => {
                const notDropdown = !this.LayoutElement.contains(e.target);
                const notTarget = !this.isLocalNode(this.target, e.target);

                if (notTarget && notDropdown) {
                    this.CloseDialogBox(this.LayoutElement);
                }
            })
        );
    }

    get LayoutElement() {
        return this.elementRef.nativeElement;
    }

    buildMenu(target, offset = this.offset) {
        this.target = target;
        this.offset = offset;

        setTimeout((d) => this.setPosition(target));
    }

    private setPosition(target) {
        const offset = Object.assign(
            {
                y: 0,
                x: 0,
            },
            this.offset
        );

        const rect1 = target.getBoundingClientRect();
        const rect2 = this.LayoutElement.getBoundingClientRect();

        const y = rect1.top + rect1.height;
        const x = rect1.left - rect2.width + rect1.width;

        this.renderer.setStyle(this.LayoutElement, 'left', `${x + offset.x}px`);
        this.renderer.setStyle(this.LayoutElement, 'top', `${y + offset.y}px`);
        this.renderer.setStyle(this.LayoutElement, 'visibility', `visible`);
    }

    resolveNgContent<T>(content: Content<T>): HTMLElement {
        /** Otherwise it's a component */
        const factory = this.componentFactoryResolver.resolveComponentFactory(content as any);
        this.injectHere.clear();
        const componentRef = this.injectHere.createComponent(factory);
        return componentRef.location.nativeElement;
    }
}
