import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SiteInformationModalComponent } from './site.information.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SparksModule } from '../../../components/sparks/sparks.module';

@NgModule({
    declarations: [SiteInformationModalComponent],
    imports: [CommonModule, ModalModule.forRoot(), SparksModule],
    exports: [SiteInformationModalComponent],
    entryComponents: [SiteInformationModalComponent],
})
export class SiteInformationModalModule {}
