import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { CheckboxGroupComponent } from './checkbox.group.component';

import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [CheckboxComponent, CheckboxGroupComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatCheckboxModule],
    exports: [CheckboxComponent, CheckboxGroupComponent, FormsModule, ReactiveFormsModule, MatCheckboxModule],
    entryComponents: [CheckboxComponent, CheckboxGroupComponent],
})
export class CheckboxModule {
    constructor() {}
}
