import { ElementRef, EventEmitter, Injectable, TemplateRef, Type } from '@angular/core';
import { of, Subject } from 'rxjs';

type Content<T> = string | TemplateRef<T> | Type<T> | HTMLElement;

export class ToasterEmitterService {
    private static _instance: ToasterEmitterService;

    onShow: EventEmitter<any> = new EventEmitter<any>();
    onShowLocation: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

    onClose: EventEmitter<any> = new EventEmitter<any>();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class ToasterService {
    constructor() {}

    private emit(type, body) {
        const subject = new Subject();
        const toaster = ToasterEmitterService.Instance;

        toaster.onShowLocation.subscribe((d) => {
            setTimeout(() => {
                subject.next(d.nativeElement);
                subject.complete();
            });
        });

        toaster.onShow.emit(
            Object.assign(
                {},
                {
                    type,
                    ...body,
                }
            )
        );

        return subject;
    }

    success(title, message: Content<any>, options = {}) {
        return this.emit('success', {
            title,
            message,
            options,
        });
    }

    info(title, message: Content<any>, options = {}) {
        this.emit('info', {
            title,
            message,
            options,
        });
    }

    warning(title, message: Content<any>, options = {}) {
        this.emit('warning', {
            title,
            message,
            options,
        });
    }

    danger(title, message: Content<any>, options = {}) {
        this.emit('danger', {
            title,
            message,
            options,
        });
    }

    error = (title, message: Content<any>, options = {}) => this.danger(title, message, options);
}
