/**
 * Created by Ramor11 on 3/31/2017.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { emailFormatValidator } from '../../validators/email.validators';
import { get } from 'lodash';
import { AppState } from '../../app.service';
import * as states from './us_states.json';
import * as canada from './canada_states.json';
import * as country from './country.json';
import { Integrators } from '../../constants/integrators';

const FormGroupControlMap = Object.assign(
    {},
    {
        siteName: {
            name: 'name',
            validators: [Validators.required],
        },
        primaryEmail: {
            name: 'email',
            validators: [Validators.required, emailFormatValidator],
        },
        posSystem: {
            name: 'partner_id',
            validators: [Validators.required],
        },
        siteContactEmail: {
            name: 'siteContactEmail',
            validators: [emailFormatValidator],
        },
        dealerEmail: {
            name: 'dealerEmail',
            validators: [emailFormatValidator],
        },
        phone: {
            name: 'phone',
            validators: [Validators.required],
        },
        address: {
            name: 'address',
            validators: [Validators.required],
        },
        city: {
            name: 'city',
            validators: [Validators.required],
        },
        state: {
            name: 'state',
            validators: [Validators.required],
        },
        country: {
            name: 'country',
            validators: [Validators.required],
        },
        zip: {
            name: 'zip',
            validators: [Validators.required],
        },
        timezone: 'timezone',
        emailCredentials: 'emailCredentials',
        demoAccount: 'demoAccount',
    },
    Integrators
);

@Injectable()
export class NewSiteRootEntryResolver implements Resolve<any> {
    constructor(private appState: AppState) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | any {
        const formGroup = {};
        Object.keys(route.queryParams).forEach((k) => {
            let value = route.queryParams[k].trim();
            switch (value.toLowerCase()) {
                case 'true':
                    value = true;
                    break;
                case 'false':
                    value = false;
                    break;
                default:
                    const isnum = !isNaN(Number(value));
                    value = isnum ? Number(value) : value;
                    break;
            }

            if (k === 'posSystem') this.appState.set('PARTNER', value);

            if (k === 'country') {
                const _search = (country as any).default.find((c) => c.abbreviation === value);
                value = get(_search, 'abbreviation', 'USA');
            }

            if (FormGroupControlMap[k]) {
                if (typeof FormGroupControlMap[k] === 'string') {
                    formGroup[FormGroupControlMap[k]] = new FormControl(value, []);
                } else {
                    const ctrl = get(FormGroupControlMap, `${k}`, {
                        name: '',
                        validators: [],
                    });
                    formGroup[ctrl.name] = new FormControl(value, ctrl.validators);
                }
            }
        });

        if (formGroup['state']) {
            const $country = formGroup['country'].value;
            const $state = formGroup['state'].value;
            let _states = (states as any).default;

            switch ($country) {
                case 'CA':
                    _states = (canada as any).default;
                    break;
                default:
                    _states = (states as any).default;
                    break;
            }

            const _search = _states.find((c) => c.abbreviation === $state);
            formGroup['state'].setValue(get(_search, 'abbreviation', ''));
        }

        return formGroup;
    }
}
