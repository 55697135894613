/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimepickerComponent } from './timepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [TimepickerComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [TimepickerComponent, FormsModule, ReactiveFormsModule],
    entryComponents: [TimepickerComponent],
})
export class TimepickerModule {
    constructor() {}
}
