/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserModule } from './user.module';
import { RouterModule } from '@angular/router';
import { AddLocationComponent } from './add-location.component';
import { RootEntryComponent } from './root.entry.component';
import { PartnerGuard } from './partner.guard';
import { NewSiteRootEntryResolver } from './root.entry.resolver';
import { LoggedInGuard } from '../../guards/logged-in.guard';
import { NewSiteEntrycomponent } from './new-site.entrycomponent';
import { PERMISSIONGuard } from 'src/app/auth/PERMISSION.guard';

@NgModule({
    declarations: [RootEntryComponent, NewSiteEntrycomponent],
    imports: [
        CommonModule,

        UserModule,
        RouterModule.forChild([
            {
                path: '',
                component: RootEntryComponent,
                children: [
                    {
                        path: '',
                        component: NewSiteEntrycomponent,
                        canActivate: [LoggedInGuard],
                        children: [
                            {
                                path: '',
                                component: AddLocationComponent,
                                canActivate: [PERMISSIONGuard],
                                data: { PERMISSIONGuard: ['UI.LOCATION.ADD'] },
                                resolve: {
                                    NewSiteRootEntryResolver,
                                },
                            },
                        ],
                        runGuardsAndResolvers: 'always',
                    },
                    {
                        path: 'integrations',
                        loadChildren: () => import('../integrations/root.entry.module').then((mod) => mod.RootEntryModule),
                        resolve: {
                            NewSiteRootEntryResolver,
                        },
                    },
                ],
            },
        ]),
    ],
    exports: [UserModule],
    providers: [PartnerGuard, NewSiteRootEntryResolver],
    entryComponents: [RootEntryComponent, NewSiteEntrycomponent],
})
export class RootEntryModule {}
