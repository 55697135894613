import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';

import { AgInfoModalComponent } from './info.modal.component';
import { AgDeleteConfirmationComponent } from './delete.confirm.component';
import { AgStatusComponent } from './status.component';
import { AclComponent } from './acl.component';
import { LocationComponent } from './location.component';
import { AgLocationNameComponent } from './location.name.component';

@NgModule({
    declarations: [
        AgDeleteConfirmationComponent,
        AgInfoModalComponent,
        LocationComponent,
        AgLocationNameComponent,
        AgStatusComponent,
        AclComponent,
    ],
    imports: [
        CommonModule,

        AgGridModule.withComponents([
            AgDeleteConfirmationComponent,
            AgInfoModalComponent,
            AgLocationNameComponent,
            LocationComponent,
            AgStatusComponent,
            AclComponent,
        ]),
    ],
    exports: [AgGridModule],
    entryComponents: [
        AgDeleteConfirmationComponent,
        AgInfoModalComponent,
        AgLocationNameComponent,
        LocationComponent,
        AgStatusComponent,
        AclComponent,
    ],
})
export class AgComponentsModule {}
