/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDialogComponent } from './input.dialog.component';
import { InputDialogDirective } from './input.dialog.directive';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [InputDialogComponent, InputDialogDirective],
    imports: [CommonModule, FormsModule],
    exports: [InputDialogComponent, InputDialogDirective],
    providers: [],
    entryComponents: [InputDialogComponent],
})
export class InputDialogModule {}
