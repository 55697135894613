<div class="modal-header">
    <button type="button" class="close" (click)="closeMe($event)"></button>
    <h5 class="modal-title">
        Location Details: <span class="italic">{{ siteData?.name }}</span>
    </h5>
</div>
<div class="modal-body" #modalBody>
    <fieldset>
        <location-form
            [pointyEmbedHoursOfOperations]="false"
            [isNewSite]="false"
            [siteData]="siteData"
            [readonly]="false"
            (onChanges)="onLocationFormChanges($event)"
        ></location-form>
    </fieldset>
    <hr />
    <fieldset>
        <hoursofoperations
            [opening_hours]="opening_hours"
            [sendTime]="sendTime"
            (onChanges)="hrsOfOperationChanges($event)"
        ></hoursofoperations>
    </fieldset>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button
        type="button"
        class="btn btn-outline-secondary ripple text-left float-left text-capitalize py-1"
        (click)="closeMe($event)"
    >
        Cancel
    </button>
    <submit-btn (onSubmitPressed)="onSaveChange($event)"></submit-btn>
</div>

