import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { HttpClientModuleInterceptor } from '../../http.interceptor';

import { SiteHeaderComponent } from './site.header.component';
import { AppState } from '../../app.service';
import { UserAppDataService } from '../../services/user.data.service';
import { SiteSelectionGridModules } from './site-selection-grid/site.selection.grid.modules';
import { SiteInformationModalModule } from './site-information-modal/site.information.modules';
import { LocationService } from '../../services/location.service';
import { EditlocationService } from '../modals/editlocation/editlocation.service';
import { EditLocationModules } from '../modals/editlocation/editlocation.modules';
import { AuthModule } from 'src/app/auth/auth.module';
import { ValidateUser } from 'src/app/auth/validate.user.service';
import { ChevronModule } from 'src/app/components/chevron/chevron.module';
import { CollapsibleModule } from 'src/app/components/collapsible/collapsible.module';
import { NgContentInjectionModule } from 'src/app/components/ngContentInjection/ng-content-injection.module';
import { SortableModule } from 'src/app/components/sortable/sortable.module';
import { ToasterService } from 'src/app/components/toast/toaster.service';

@NgModule({
    declarations: [SiteHeaderComponent],
    imports: [
        CommonModule,

        CollapsibleModule,
        SortableModule,
        RouterModule,
        ChevronModule,
        AuthModule,
        NgContentInjectionModule,
        SiteSelectionGridModules,
        SiteInformationModalModule,
        EditLocationModules,
    ],
    exports: [SiteHeaderComponent, NgContentInjectionModule],
    providers: [
        HttpClientModuleInterceptor,
        UserAppDataService,
        EditlocationService,
        ToasterService,
        LocationService,
        ValidateUser,
        AppState,
    ],
    entryComponents: [SiteHeaderComponent],
})
export class SiteHeaderModule {}
