import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAppDataService } from '../services/user.data.service';
import { map, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { AppState } from '../app.service';

@Injectable()
export class UserDataResolver implements Resolve<any> {
    constructor(private userDataService: UserAppDataService, private appState: AppState) {}

    resolve(): Observable<any> | Promise<any> | any {
        return this.userDataService.getUserdata().pipe(
            map((d) => get(d, '0', {})),
            tap((d) => this.appState.set('USER_APP_CONFIG', d))
        );
    }
}
