<gl-input
    type="text"
    [(ngModel)]="_model"
    (ngModelChange)="modelChanged($event)"
    [label]="placeholder"
    [typedaheadDatasets]="typedaheadDatasets"
    [ngModelOptions]="{ standalone: true }"
>
    <suffix class="cursor-pointer" (click)="clearAllFilters()" *ngIf="_model">
        <i class="fa fa-times"></i>
    </suffix>
</gl-input>

