import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { get } from 'lodash';

@Component({
    selector: 'ag-location-name',
    template: `
        <span (click)="openLink($event)">
            <i class="fa fa-external-link-alt"></i>
        </span>
        {{ value }}
    `,
    styles: [
        `
            :host > span {
                color: #cccccc;
                cursor: pointer;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                width: 25px;
            }
        `,
    ],
})
export class AgLocationNameComponent implements AgRendererComponent {
    public params: any;
    public value: string = '';

    constructor(private router: Router, private route: ActivatedRoute) {}

    openLink(e) {
        if (e) e.stopPropagation();
        const data = this.params.data;

        const navigationExtras: NavigationExtras = {
            relativeTo: this.route,
            queryParams: {
                name: data.name,
                cssite: data.site_id,
                partner: data.partner_id,
            },
        };

        this.router.navigate(['.'], navigationExtras);
    }

    refresh(params: any): any {
        return null as any;
    }

    agInit(params: any): void {
        const value = get(params, 'value', null);
        this.value = get(params, 'valueFormatted', value) || value;
        this.params = params;

        this.params = params;
    }
}
