import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from './confirm.component';
import { Observable, Subject } from 'rxjs';

interface IInitialState {
    onCancel?: (e: MouseEvent) => void;
    onConfirm?: (e: MouseEvent) => void;
}

@Injectable()
export class ConfirmService {
    constructor(private modalService: BsModalService) {}

    openModal(initialState: IInitialState | any = {}): Observable<any> {
        const subject = new Subject();
        const modalRef = this.modalService.show(ConfirmComponent, {
            ignoreBackdropClick: true,
            class: 'modal-sm',
            initialState: Object.assign(
                {
                    onCancel: (e) => {
                        subject.complete();
                        modalRef.hide();
                    },
                    onConfirm: (e) => {
                        modalRef.hide();
                        subject.next(e);
                        subject.complete();
                    },
                },
                initialState
            ),
        });

        return subject;
    }
}
