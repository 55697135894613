import { Component, ElementRef, EventEmitter, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultFormGroup } from '../../location-data/location-form/location.form.component';
import { get } from 'lodash';
import { buildFormGroupController, formValidate, FormValidityCheck } from '../../../services/form.validation.service';
import { EditlocationService } from './editlocation.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'add-employee',
    templateUrl: './editlocation.component.html',
})
export class EditLocationComponent {
    @ViewChild('modalBody', { static: true }) modalBody!: ElementRef;
    @Input() opening_hours: any = {};
    @Input() sendTime: any = '04:00';

    public onClose: EventEmitter<any> = new EventEmitter();
    public subscriptions = new Subscription();
    public formGroup: FormGroup = new FormGroup(DefaultFormGroup.Instance);

    public siteData = { name: '' };

    constructor(public bsModalRef: BsModalRef, private editlocationService: EditlocationService) {}

    onLocationFormChanges(e) {
        buildFormGroupController(this.formGroup, e);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    hrsOfOperationChanges = (e): void => {
        const ctrl = this.formGroup.controls['opening_hours'];
        if (ctrl) ctrl.setValue(e);
        else this.formGroup.addControl('opening_hours', new FormControl(e));
    };

    buildFormGroup(params) {
        if (!params) return;
        this.formGroup = new FormGroup(DefaultFormGroup.Instance);
        Object.keys(params).forEach((k) => {
            const value = get(params, k, '');
            const ctrl = this.formGroup.controls[k];
            if (ctrl) {
                ctrl.setValue(value);
                ctrl.enable();
            } else {
                this.formGroup.addControl(k, new FormControl({ value }));
            }
        });

        const rawData = this.formGroup.getRawValue();
        this.opening_hours = rawData['opening_hours'];
        this.sendTime = get(rawData, 'integrators.pointy.sendTime', this.sendTime);
    }

    onSaveChange(e) {
        FormValidityCheck(true);
        if (!formValidate(this.formGroup)) return;
        const rawData = this.formGroup.getRawValue();
        delete rawData['operatingHrs'];

        this.editlocationService
            .updateLocation(rawData)
            .pipe(finalize(() => this.closeMe(null)))
            .subscribe();
    }

    closeMe(e) {
        this.onClose.emit(null);
        this.bsModalRef.hide();
    }
}
