import { IOperand, TOperator } from './condition.types';
import { Injectable } from '@angular/core';
import { get } from 'lodash';

@Injectable()
export class Condition {
    type: string = 'condition'; // If expression type is "condition", should contain field, operator, and values fields (see description of each below)
    field: IOperand = {
        name: '',
        description: '',
    };

    operator: TOperator = 'equals';
    values: any[] = []; // For LT, LE, GT, GE, EQ, NE, contains -> Single value; For in -> One or more values; For between -> Two values
}

export function getConditions(condition) {
    let query = {};
    let filter = condition.filter;
    let filterType = condition.filterType;
    let value: any = filter === 'false' ? false : filter === 'true' ? true : filter;
    let isObject = false;

    try {
        value = JSON.parse(value);
        isObject = true;
    } catch (e) {
        isObject = false;
    }

    const isString = ['text', 'string'].includes(filterType);
    const isNumber = ['number', 'float'].includes(filterType);

    value = isString ? value.toString() : isNumber ? Number(value) : value;
    const arrValue = isString
        ? value
              .split(',')
              .filter((a) => a)
              .map((a) => a.trim())
        : [];
    const type = get(condition, 'type', 'equals');

    switch (type) {
        case 'equals':
            query = { $eq: value };
            break;
        case 'notEqual':
            query = { $ne: value };
            break;
        case 'lessThan':
            query = { $lt: value };
            break;
        case 'lessThanEqual':
            query = { $lte: value };
            break;
        case 'greaterThan':
            query = { $gt: value };
            break;
        case 'greaterThanEqual':
            query = { $gte: value };
            break;

        case 'in':
            query = { $in: arrValue };
            break;
        case 'between':
            query = {
                $gt: arrValue[0],
                $lt: arrValue[1],
            };
            break;
        case 'object':
        case 'boolean':
            query = value;
            break;
        default:
            const $escapeChars = typeof value === 'string' ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : value;
            let $regex = $escapeChars;
            let $options = 'i';

            switch (type) {
                case 'startsWith':
                    $regex = `^${$escapeChars}`;
                    $options = 'i';
                    break;
                case 'endsWith':
                    $regex = `${$escapeChars}$`;
                    $options = 'i';
                    break;
                case 'notContains':
                    $regex = `^((?!${$escapeChars}).)*$`;
                    $options = 'i';
                    break;
                case 'contains':
                    $regex = `${$escapeChars}`;
                    $options = 'i';
                    break;
            }

            query = Object.assign({}, { $regex: $regex });
            if ($options) Object.assign(query, { $options });

            break;
    }

    return query;
}
