<div class="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" #toasty>
    <div class="toast-header {{ type }} {{ options?.class }}">
        <i class="fa fa-lg" [ngClass]="iconDisplay" style="margin: 2px 4px 0 -4px"></i>
        <strong class="mr-auto" [innerHTML]="title"></strong>
        <small class="text-muted" *ngIf="time"
            ><span [innerHTML]="time"></span>&nbsp;<span [innerHTML]="timeSting"></span>&nbsp;ago
        </small>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="onClose($event)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body text-capitalize">
        <ng-content></ng-content>
    </div>
</div>

