import { Component, ViewEncapsulation } from '@angular/core';
import { ToasterService } from './components/toast/toaster.service';

@Component({
    selector: 'pos-app',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
})
export class PosAppComponent {
    private success = false;

    constructor(private toasterService: ToasterService) {}

    onComplete(e: string) {
        switch (e) {
            case 'ERROR':
                this.toasterService.error('Error', 'Upload Failed', {
                    delay: 3000,
                });

                break;
            case 'SUCCESS':
                this.success = true;
                break;
            case 'COMPLETED':
                if (this.success) {
                    this.success = false;
                    this.toasterService.success('Success', 'File Uploaded', {
                        delay: 3000,
                    });
                }
                break;
        }
    }
}
