import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dz-progress',
    template: `
        <span #anchor></span>
        <div class="__uploader_c">
            <div class="__up_h d-flex justify-content-between">
                <span [innerHTML]="uploadingText"></span>
                <span>{{ displayPercent }}%</span>
            </div>
            <div class="__up_b" #body>
                <div class="progress">
                    <div
                        class="progress-bar bg-success"
                        #bar
                        style="width: 0%;"
                        [attr.aria-valuenow]="percent"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [`./uploader.component.scss`],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @HostBinding('style.visibility') visibility: string = 'visible';
    @ViewChild('anchor', { read: ViewContainerRef, static: true })
    private anchor!: ViewContainerRef;
    @ViewChild('body', { static: true }) private body!: ElementRef;
    @ViewChild('bar', { static: true }) private bar!: ElementRef;

    @Input() percent: number = 0;
    @Input() files_count: number = 0;

    @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

    public uploadingText: string = 'uploading';
    public subscriptions: Subscription = new Subscription();

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    get displayPercent() {
        return Math.floor(this.percent);
    }

    setProgressPercent(percent: number) {
        if (percent <= 100) {
            setTimeout((d) => {
                this.percent = percent;
                this.renderer.setStyle(this.bar.nativeElement, 'width', `${this.percent}%`);
            });
        }
    }

    setProgressComplete() {
        this.setProgressPercent(100);
    }

    setProgressErrorStatus() {
        this.renderer.removeClass(this.bar.nativeElement, 'bg-success');
        this.renderer.addClass(this.bar.nativeElement, 'bg-danger');
        this.setProgressPercent(100);
    }

    ngOnInit(): void {
        this.renderer.listen(this.bar.nativeElement, 'transitionend', () => {
            if (this.percent >= 100) this.onComplete.emit(100);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get LayoutElement(): any {
        return this.elementRef.nativeElement as Element as HTMLElement;
    }
}
