import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionBadgeComponent } from './permission-badge.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [PermissionBadgeComponent],
    imports: [CommonModule, FormsModule],
    providers: [],
    entryComponents: [PermissionBadgeComponent],
    exports: [PermissionBadgeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PermissionBadgeModule {}
