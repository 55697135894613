import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    AbstractControl,
    AbstractControlDirective,
    FormControl,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgForm,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { get } from 'lodash';
import { ErrorStateMatcher } from '@angular/material/core';
import { take } from 'rxjs/operators';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'prefix',
    template: '<ng-content></ng-content>',
})
export class InputPrefix {}

@Component({
    selector: 'suffix',
    template: '<ng-content></ng-content>',
})
export class InputSuffix {}

@Component({
    selector: 'gl-error',
    template: '<ng-content></ng-content>',
})
export class ErrorMess {}

@Component({
    selector: 'gl-input[formControlName],gl-input[formControl],gl-input[ngModel]',
    templateUrl: './input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: InputComponent,
            multi: true,
        },
    ],
})
export class InputComponent {
    @ViewChild('inputRef', { static: true }) inputRef!: ElementRef;
    @ViewChild('ctrl', { static: true }) ctrl!: AbstractControlDirective;
    @ViewChild('auto', { static: true }) auto!: MatAutocomplete;

    @Input('matAutocomplete') matAutocomplete!: MatAutocomplete;
    @Input('mtAutoCompleteOptions') mtAutoCompleteOptions: any = [];

    @Input() readonly: boolean = false;
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() type: string = 'text';
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() pattern: string = '';
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Input() errorState: boolean = false;
    @Input() errorStateMatcher: ErrorStateMatcher = new MyErrorStateMatcher();

    @Input() validators: ValidatorFn | ValidatorFn[] = [];

    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @Output() NgModel: EventEmitter<AbstractControlDirective> = new EventEmitter<AbstractControlDirective>();

    public _model: string = '';
    public ngModelCtrl: FormControl = new FormControl('', []);
    private ngModelCtrlObserver: any = new Subject();
    @Input() typedaheadDatasets: any;

    constructor(private elementRef: ElementRef) {}

    modelChanged(e) {
        if (e === this.ngValue) return;
        this.ngValue = e;
        this.change.emit(this.ngValue);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    set ngValue(v: any) {
        this._model = v;
    }

    get ngValue() {
        return this._model;
    }

    private setIfDisableRequired() {
        if (this.disabled && this.ngModelCtrl) this.ngModelCtrl.disable({ onlySelf: true, emitEvent: true });
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    writeValue(value: string): void {
        this.ngValue = value;
    }

    validate(ctrl: AbstractControl): ValidationErrors | null {
        let invalid: any = null;
        this.required = get(ctrl, 'errors.required', this.required || false);
        const validators = Array.isArray(this.validators) ? this.validators : this.validators ? [this.validators] : [];

        validators.forEach((_f: ValidatorFn) => {
            const validator = _f(ctrl);
            const isvalid = validator === null;
            if (!isvalid) invalid = { invalid: true };
        });

        setTimeout((d) => {
            (this.ngModelCtrl as any) = ctrl ? ctrl : this.ngModelCtrl;

            if (this.readonly) {
                this.setDisabledState(true);
                this.setIfDisableRequired();
            } else {
                if (this.ngModelCtrlObserver) this.ngModelCtrlObserver.unsubscribe();
                this.ngModelCtrlObserver = this.ngModelCtrl.valueChanges.pipe(take(1)).subscribe({
                    next: (d) => this.modelChanged(d),
                });
            }
        });

        return invalid;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};

    get InputElement(): HTMLInputElement {
        return this.inputRef.nativeElement;
    }

    ngAfterContentInit(): void {
        if (!this.matAutocomplete) this.matAutocomplete = this.auto;

        if (this.readonly) {
            this.setDisabledState(true);
            this.setIfDisableRequired();
        }
    }

    private get LayoutElement() {
        return this.elementRef.nativeElement;
    }
}
