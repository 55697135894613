import { Directive, ElementRef, EventEmitter, HostBinding, Input, Output, Renderer2, SimpleChanges } from '@angular/core';
import { Sortable } from '@shopify/draggable';
import { get } from 'lodash';

export interface ISortElements {
    element?: HTMLElement;
    newContainer?: HTMLElement;
    sourceContainer?: HTMLElement;
    newIndex?: number;
    oldIndex?: number;
}

@Directive({
    selector: '[sortable]',
})
export class SortableDirective {
    @Input() sortable: string = '.grid-item';
    @Input() enabled: boolean = true;
    @Input() options: any = {};
    @Output() onDragStart: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDragStop: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSort: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSortCompleted: EventEmitter<ISortElements> = new EventEmitter<ISortElements>();
    @HostBinding('class.is-sorting') isDragging: boolean = this.enabled;

    private $ortable = Sortable;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(changes: SimpleChanges): void {
        const previousValue = get(changes, 'enabled.previousValue', undefined);
        if (changes.enabled && typeof previousValue === 'boolean') {
            this.ngAfterViewInit();
        }
    }

    ngOnDestroy(): void {
        try {
            if (this.$ortable) this.$ortable.destroy();
        } catch (e) {}
    }

    get ListItems(): NodeList {
        return this.LayoutElement.querySelectorAll(this.sortable);
    }

    ngAfterViewInit(): void {
        this.addClassName();
    }

    addClassName() {
        Array.from(this.LayoutElement.children).forEach((i: any, idx) => {
            if (i.dataset && !i.dataset.index) this.renderer.setAttribute(i, 'data-index', idx.toString());
        });
        Array.from(this.ListItems).forEach((i, idx) => {
            this.renderer.addClass(i, 'is-sortable');
        });
    }

    broadCastChanges(sortObjectData) {
        setTimeout((d) => this.onSortCompleted.emit(sortObjectData));
    }

    get Sortable() {
        return this.$ortable;
    }

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
