import { SIDEBAR_ROUTES as payroll_sidebar } from '../../containers/payroll/routes';
import { SIDEBAR_ROUTES as accounts_sidebar } from '../../containers/accounts/routes';
import { SIDEBAR_ROUTES as capi_sidebar } from '../../containers/capi/routes';

export const APPLICATIONS = [
    {
        path: `/payroll`,
        name: 'Payroll',
        routes: payroll_sidebar,
        faIcon: 'fa-user-clock',
        ref: 'payrollConnector',
        permission: ['UI.PAYROLL'],
    },
    {
        path: `/reporting`,
        faIcon: 'fa-chart-pie',
        name: 'Reporting',
        disabled: true,
        permission: ['UI.REPORTING'],
    },
    {
        path: `/capi`,
        name: 'Central API',
        faIcon: 'fa-tools',
        routes: capi_sidebar,
        permission: ['UI.CAPI'],
    },
    {
        path: `/accounts`,
        name: 'Accounts',
        faIcon: 'fa-map-marker-alt',
        routes: accounts_sidebar,
        permission: ['UI.ACCOUNTS'],
    },
];
