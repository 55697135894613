<mat-form-field class="w-100">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-select [(ngModel)]="_model"
             (ngModelChange)="modelChanged($event)"
             [placeholder]="placeholder"
              [required]="required"
              [disabled]="readonly">
    <mat-option  *ngFor="let l of statesArray"
              [value]="l.abbreviation">
        {{l.name}}
    </mat-option >
  </mat-select>
</mat-form-field>
