import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { us_states } from './us_states';
import { canada_states } from './canada_states';
import { AuthService } from 'src/app/auth/auth.service';

type STATES = 'america' | 'canada';

@Component({
    selector: 'select-state[formControlName], select-state[formControl], select-state[ngModel]',
    templateUrl: './states.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StatesComponent),
            multi: true,
        },
    ],
})
export class StatesComponent {
    public _model: string = '';

    @Input() states: STATES = 'america';
    @Input() label: string = '';
    @Input() readonly: boolean = false;
    @Input() required: boolean = true;
    @Input() placeholder: string = 'Select State/Province';
    @Input() options: {
        name: string;
        abbreviation: string;
    }[] = [];

    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public ngModelCtrl!: AbstractControl;
    public statesArray: any = [];

    public access_level = this.authService.AccessLevel;
    public hasRole: boolean = false;
    public hasValue: boolean = true;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.setOptions();
    }

    private setOptions() {
        const hasOptions = !!this.options.length;
        if (hasOptions) {
            this.statesArray = this.options;
        } else {
            if (this.states === 'america') this.statesArray = us_states;
            else if (this.states === 'canada') this.statesArray = canada_states;
        }
    }

    set ngValue(v: any) {
        this._model = v;
        this.hasValueCheck();
    }

    private hasValueCheck() {
        let hasValue = !!this.statesArray.filter((a) => a.abbreviation === this._model).length;
        if (!hasValue && !this.readonly) hasValue = true;
        this.hasValue = hasValue;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.states) this.setOptions();
        this.hasValueCheck();
    }

    get ngValue() {
        return this._model;
    }

    modelChanged(e) {
        this.ngValue = e;
        if (this.ngModelCtrl) this.ngModelCtrl?.setValue(this.ngValue);

        const value = this.statesArray.filter((a) => a.abbreviation === this._model);
        this.change.emit(value);
        this.onChangeCallback(this.ngValue);
        this.onTouchedCallback(this.ngValue);
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        if (obj !== this.ngValue) this.ngValue = obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};
}
