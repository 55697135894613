import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { XHRStatusEmitter } from '../../http.interceptor';

@Component({
    selector: 'submit-btn',
    template: `
        <button
            class="btn-submit btn btn-outline-success ripple text-capitalize py-1 {{ klass }}"
            #btn
            [disabled]="$disabled || submitted"
            type="submit"
            (click)="onSaveChange($event)"
        >
            <ng-content></ng-content>
            <span [innerHTML]="label"></span>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('btn', { static: true }) progress!: ElementRef;

    @Input() label: string = 'Submit';
    @Input() disabled: boolean = false;
    @Input() klass: string = '';

    @Output() onSubmitPressed: EventEmitter<any> = new EventEmitter<any>();

    public submitted: boolean = false;
    public $disabled: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(private changeDetect: ChangeDetectorRef) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            XHRStatusEmitter.Instance.onStatusChange.subscribe((d) => {
                switch (d) {
                    case 'REQUEST':
                        this.submitted = true;
                        break;
                    case 'SUCCEEDED':
                    default:
                        this.submitted = false;
                }
                this.changeDetect.detectChanges();
            })
        );

        if (this.$disabled !== this.disabled) {
            this.$disabled = this.disabled;
            this.changeDetect.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled)
            this.setChangeStatus(changes.disabled, () => {
                this.$disabled = this.disabled;
                this.changeDetect.detectChanges();
            });
    }

    private setChangeStatus(change, callback) {
        const prev = change.previousValue;
        const curr = change.currentValue;
        if (prev !== curr) callback();
    }

    onSaveChange(e) {
        this.onSubmitPressed.emit(e);
    }
}
