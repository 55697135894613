import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class DropzoneService {
    onDrop: EventEmitter<any> = new EventEmitter<any>();
    onRemove: EventEmitter<any> = new EventEmitter<any>();

    onRemoveFile(id: number) {
        this.onRemove.emit(id);
    }
}
