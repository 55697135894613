import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, get, isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { AppState } from '../../app.service';
import { TextXeditorComponent } from '../xEditor/text.xeditor.component';
import { HoursXeditorComponent } from '../xEditor/hours.xeditor.component';
import { FormControl, FormGroup } from '@angular/forms';
import { getTimefromString, getTimeStringFromMidnight } from '../../utilities/getTimefromString';

declare const moment: any;
const openingHrs: string = '08:00 AM';
const closingHrs: string = '10:00 PM';

/**
 * Hours Operating Hours set the default for all days of the week
 * @param e
 * @param formGroup
 */
export function HrsOfOperationDefaultWeek(e, formGroup?: FormGroup): any | FormGroup {
    let opening_hours = get(e, 'opening_hours', {});
    const _default: any = {};
    const operatingHrs = get(e, 'operatingHrs', []) || [];
    if (!operatingHrs.length) {
        operatingHrs.push({
            command: 'default',
            hrs: {
                open: '08:00 AM',
                close: '10:00 PM',
            },
            status: true,
        });
    }

    if (Array.isArray(operatingHrs)) {
        opening_hours = {};
        operatingHrs.forEach((o) => {
            if (o.command === 'default') {
                Object.keys(o.hrs).forEach((k) => {
                    const hrs: any = getTimefromString(o.hrs[k]);
                    _default[k] = hrs.hours * 60 + hrs.minutes;
                });

                ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach((day) => {
                    opening_hours[day] = {
                        opening_mins: _default.open,
                        closing_mins: _default.close,
                    };
                });
            } else {
                const open = getTimefromString(o.hrs['open']);
                const close = getTimefromString(o.hrs['close']);

                if (o.command) {
                    opening_hours[o.command] = {
                        opening_mins: open.hours * 60 + open.minutes,
                        closing_mins: close.hours * 60 + close.minutes,
                    };

                    if (o.hasOwnProperty('closed') && o.closed)
                        Object.assign(opening_hours[o.command], {
                            is_closed: o.closed,
                        });
                }
            }
        });
    }

    if (formGroup) {
        const control = formGroup.controls['opening_hours'];
        if (!control) formGroup.addControl('opening_hours', new FormControl(opening_hours));
        else control.setValue(opening_hours);
    }
    return Object.assign({}, opening_hours);
}

@Component({
    selector: 'hoursofoperations',
    templateUrl: `./hoursofoperations.component.html`,
})
export class HoursofoperationsComponent {
    @Input() readonly: boolean = false;

    @Input() params;
    @Input() formSubmit: boolean = false;
    @Input() requiredFields: string[] = [];
    @Input() opening_hours: any = {};
    @Input() sendTime: any = '04:00';

    @Output() onChanges: EventEmitter<any> = new EventEmitter();
    @Output() onStatusChanges: EventEmitter<any> = new EventEmitter();

    private subscriptions: Subscription = new Subscription();

    public openingHrs: string = openingHrs;
    public openingHrsToMinutes!: number;
    public closingHrs: string = closingHrs;
    public closingHrsToMinutes!: number;
    public xEditorHourComponent: any = HoursXeditorComponent;
    public localBodyRendererFramework: any = TextXeditorComponent;

    public selectionString = {
        text: 'Day/Date Selection',
        description:
            'Select day of week to display different operating hrs.<br> <code>Default</code>: Every Day.<br /> <code>',
        width: 250,
    };

    public hrsString = {
        text: 'Hours of Operations',
        description: `Provide restaurant's operating hrs to be displayed onto the reservation widget page.<br /> <code>Click</code> on the time to change operating hrs.`,
        width: 250,
    };

    rules: any = [{ ...this.getDefaultRule() }];

    rulesSelections: any = [];

    startDateConfig: any = {
        minDate: new Date(),
        maxDate: moment().add(3, 'months').toDate(),
    };

    constructor(private appState: AppState) {}

    private getDefaultRule() {
        return {
            command: 'default',
            hrs: {
                open: this.openingHrs,
                close: this.closingHrs,
            },
            status: true,
        };
    }

    onModelChange(dt) {
        this.rules.forEach((r: any) => {
            if (r.hasOwnProperty('dtObj')) r.value = moment(r.dtObj).format('YYYY-MM-DD');
        });

        this.onSelectChanges(null);
    }

    private buildOperatingHrs(operatingHrs) {
        this.rules = operatingHrs.map((r: any) => {
            if (r.hasOwnProperty('value') && r.command === 'datepicker') r.dtObj = moment(r.value).toDate();
            return r;
        });
    }

    addRule(e) {
        e.preventDefault();
        const rule = JSON.parse(JSON.stringify(this.rules[0]));
        Object.assign(rule, {
            command: '',
            status: false,
        });

        this.rules.push(rule);
        this.onSelectChanges(null);
    }

    move(e, dir, rule, index) {
        e.preventDefault();
        const rules = this.rules.slice(0);
        let _pos = 1 + index; //down
        if (!dir) _pos = index - 1; //up

        rules.splice(index, 1, rules[_pos]);
        rules.splice(_pos, 1, rule);
        this.rules = rules;
        this.onSelectChanges(null);
    }

    deleteRule(e, i) {
        e.preventDefault();
        if (i === 0) return;
        this.rules.splice(i, 1);
        this.onSelectChanges(null);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const cv = get(changes, 'opening_hours.currentValue', null);
        const pv = get(changes, 'opening_hours.previousValue', null);
        if (!isEqual(cv, pv)) this.getOpeningHrsConversionToRule();
    }

    /**
     * Check for comparable times, so we can define what is default
     * It will update this.opening_hours object with just differences
     */
    private getDefaultHrs() {
        const opening_hrs = {};
        const closing_hrs = {};

        Object.keys(this.opening_hours)
            .filter((a) => !!isNaN(Number(a)))
            .forEach((dayOfWeek) => {
                const dow = this.opening_hours[dayOfWeek];
                const opening_mins = dow['opening_mins'];
                const closing_mins = dow['closing_mins'];

                const _opening = opening_hrs.hasOwnProperty(opening_mins) ? opening_hrs[opening_mins] : [];
                const _closing = closing_hrs.hasOwnProperty(closing_mins) ? closing_hrs[closing_mins] : [];

                _opening.push(dayOfWeek);
                _closing.push(dayOfWeek);

                opening_hrs[opening_mins] = _opening;
                closing_hrs[closing_mins] = _closing;
            });

        let largest_opening_times: any = {
            length: 0,
            key: '',
        };
        Object.keys(opening_hrs).map((key) => {
            const length = opening_hrs[key].length;
            if (length > largest_opening_times['length']) Object.assign(largest_opening_times, { key, length });
        });

        let largest_closing_times: any = {
            length: 0,
            key: '',
        };
        Object.keys(closing_hrs).map((key) => {
            const length = closing_hrs[key].length;
            if (length > largest_closing_times['length']) Object.assign(largest_closing_times, { key, length });
        });

        if (!largest_opening_times.length) largest_opening_times['key'] = this.openingHrsToMinutes;
        if (!largest_closing_times.length) largest_closing_times['key'] = this.closingHrsToMinutes;

        const default_opening = largest_opening_times['key'];
        const default_closing = largest_closing_times['key'];

        Object.keys(this.opening_hours).forEach((dayOfWeek) => {
            const dow = this.opening_hours[dayOfWeek];
            const starting = default_opening === dow['opening_mins'];
            const ending = default_closing === dow['closing_mins'];
            const is_closed = dow['is_closed'] || false;
            if (starting && ending && !is_closed) delete this.opening_hours[dayOfWeek];
        });

        const open = getTimeStringFromMidnight(default_opening).toString();
        const close = getTimeStringFromMidnight(default_closing).toString();

        /**
         * Override the defaults with the new defaults
         */
        Object.assign(this.rules[0], {
            hrs: { open, close },
        });

        return this.opening_hours;
    }

    private getOpeningHrsConversionToRule() {
        /**
         * Check for comparable times, so we can define what is default
         */
        const opening_hours = this.getDefaultHrs();
        const open = getTimefromString(this.rules[0]['hrs']['open']);
        const close = getTimefromString(this.rules[0]['hrs']['close']);

        const defaultOpening = open.hours * 60 + open.minutes;
        const defaultClosing = close.hours * 60 + close.minutes;

        if (!Object.keys(opening_hours).length) {
            const _opening_hours = HrsOfOperationDefaultWeek({
                opening_hours: {},
                operatingHrs: [],
            });

            this.onChanges.emit(_opening_hours);

            return;
        }

        /**
         * Build what is not default
         */
        Object.keys(opening_hours)
            .filter((a) => !!isNaN(Number(a)))
            .forEach((dayOfWeek) => {
                const dow = opening_hours[dayOfWeek];
                const opening_time = getTimeStringFromMidnight(dow['opening_mins']);
                const closing_time = getTimeStringFromMidnight(dow['closing_mins']);

                const open = opening_time.toString();
                const close = closing_time.toString();
                const _handler = {
                    command: dayOfWeek,
                    hrs: {
                        close,
                        open,
                    },
                    status: get(dow, 'status', false),
                    closed: get(dow, 'is_closed', false),
                };

                const isSameOpen = defaultOpening !== dow['opening_mins'];
                const isSameClose = defaultClosing !== dow['closing_mins'];
                const isClosed = dow['is_closed'] || false;

                const push = !(!isSameOpen && !isSameClose);
                if (isClosed || push) this.rules.push(Object.assign({}, _handler));
            });

        this.onSelectChanges(null);
    }

    onSelectChanges(e?) {
        const operatingHrs = cloneDeep(this.rules);
        operatingHrs.forEach((r) => {
            if (r.hasOwnProperty('dtObj')) delete r.dtObj;
            if (r.hasOwnProperty('value') && r.command !== 'datepicker') delete r.value;
        });

        const opening_hours = HrsOfOperationDefaultWeek({ operatingHrs });
        this.onChanges.emit(opening_hours);
    }

    /**
     * Build some basic functionality that changes the default hrs
     * @private
     */
    private buildParams() {
        if (!this.params) return;

        const app = this.params;
        const operatingHrs = get(app, 'operatingHrs', null);

        this.closingHrs = get(app, 'closingHrs', this.closingHrs);
        this.openingHrs = get(app, 'openingHrs', this.openingHrs);
        this.rules[0] = { ...this.getDefaultRule() };

        this.getOpeningHrsConversionToRule();
        if (operatingHrs) this.buildOperatingHrs(operatingHrs);
    }

    private getDaysOfWeekToken(token: string = 'ddd') {
        const language = this.appState.get('location.language');
        [7, 1, 2, 3, 4, 5, 6].forEach((k) => {
            const _n = moment().isoWeekday(k).locale(language).format('dddd');
            const _t = moment().isoWeekday(k).format(token);
            this.rulesSelections.push({
                value: _t.toLowerCase(),
                description: _n,
            });
        });
    }

    public ngOnInit(): void {
        const open = getTimefromString(this.openingHrs);
        const close = getTimefromString(this.closingHrs);
        this.openingHrsToMinutes = open.hours * 60 + open.minutes;
        this.closingHrsToMinutes = close.hours * 60 + close.minutes;

        this.getDaysOfWeekToken();
        this.buildParams();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
