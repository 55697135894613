import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { noop } from 'lodash';
import { ConfirmService } from '../modals/confirm-input/confirm.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'delete-confirm-aggrid',
    template: `
        <a class="cursor-pointer text-danger ripple" (click)="openModal($event)">
            <i class="fa fa-trash-alt" #edit></i>
        </a>
    `,
})
export class AgDeleteConfirmationComponent {
    public bsModalRef!: BsModalRef;
    public filename: any = '';

    private params: any;
    private modalRef: any;

    private broadcast = noop;
    private subscriptions: Subscription = new Subscription();

    constructor(private confirmService: ConfirmService) {}

    confirm(e) {
        e.stopPropagation();
        this.broadcast(this.params);
        this.decline();
    }

    openModal(e) {
        let message = `Are you sure you want to delete <code>${this.filename}</code>?<br /><br />This cannot be undone. The file will be irretrievably lost if they are not also stored elsewhere.<br />Please type <code>DELETE</code> to proceed or close this modal to cancel.\n\n`;
        this.confirmService
            .openModal({
                messageString: message,
                confirmString: 'DELETE',
                translate: false,
                headerClass: 'bg-danger',
            })
            .pipe(take(1))
            .subscribe((d) => this.confirm(e));
    }

    decline(): void {
        if (this.modalRef) this.modalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh(params: any): any {
        return null as any;
    }

    agInit(params: any): void {
        this.params = params.data || {};
        this.filename = params.valueFormatted;
        if (params.hasOwnProperty('onClick')) this.broadcast = params.onClick;
    }
}
