import { Pipe, PipeTransform } from '@angular/core';

/**
 * Created by ramor11 on 5/1/2016.
 *  ng-repeat="p in [] | range:3 track by $index"
 */

@Pipe({ name: 'range' })
export class RangePipe implements PipeTransform {
    transform(exponent: number | string, value: any[] = []) {
        if (exponent) for (let i = 0; i < exponent; i++) value.push(i + 1);
        return value;
    }
}
