import { Component, ElementRef, Inject, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControlDirective, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { catchError, delay, take, timeout } from 'rxjs/operators';
import { get } from 'lodash';
import { AppState } from '../app.service';
import { of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppTitle } from '../layout/components/header/header.component';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { formValidate } from '../services/form.validation.service';
import { environment } from 'src/environments/environment';
import { JwtService } from '../services/local_storage.service';
import { ToasterService } from '../components/toast/toaster.service';

@Component({
    selector: 'login',
    templateUrl: `./login.component.html`,
})
export class LoginComponent {
    @ViewChild('template', { static: true }) public template!: TemplateRef<any>;
    @ViewChild('redirectIframe', { static: true })
    public redirectIframe!: TemplateRef<any>;
    @ViewChild('iframeContainer') public iframeContainer!: ElementRef<any>;
    @ViewChild('ref', { static: true }) public ref!: ElementRef;
    @ViewChild('cssiteid', { static: true })
    public cssiteid!: AbstractControlDirective;
    @ViewChild('newSite', { static: true })
    public newSite!: AbstractControlDirective;

    public fragment: string | null = null;
    public loginForm = new FormGroup({});
    public showSiteSelection: boolean = false;
    public emailMessage: string = '';
    public whereto: string = 'password';
    public titlePage: string = 'signon';
    public nextPage: string = 'forgotpassword';
    public notificationMessage: string = ``;
    public notificationLink: any = '';
    public twoFactorCheckbox: string = 'sms';
    public twoFactorTypes: string[] = ['sms', 'email'];
    private username: string = '';
    private password: string = '';
    private subscriptions: Subscription = new Subscription();
    private queryParams;
    public modalRef!: BsModalRef;

    constructor(
        private loginService: LoginService,
        private appState: AppState,
        private dom: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: BsModalService,
        private toasterService: ToasterService,
        private http: HttpClient,
        private jwtService: JwtService
    ) {}

    getInvalidState(name) {
        const control = this.loginForm.controls[name];
        return !!(control && control.invalid && (control.dirty || control.touched));
    }

    getValidState(name) {
        const control = this.loginForm.controls[name];
        return !!(control && control.valid && (control.dirty || control.touched));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private showRedirectAlert() {
        try {
            this.modalRef.hide();
        } catch (e) {}
        if (!this.notificationMessage) {
            this.toasterSuccess();
            return;
        }

        this.modalRef = this.modalService.show(this.template, {
            ignoreBackdropClick: true,
            class: 'modal-sm',
        });
    }

    moveForward(e) {
        try {
            this.modalRef.hide();
        } catch (e) {}

        if (this.notificationLink) {
            this.modalRef = this.modalService.show(this.redirectIframe, {
                ignoreBackdropClick: true,
                class: 'min-height-80 modal-600-px d-flex',
            });
            window.addEventListener('message', (event) => this.windowEventListener(event), false);
        }

        this.modalRef.hide();
    }

    private windowEventListener(e) {
        let data = e.data;
        try {
            data = JSON.parse(data);
        } catch (e) {}

        window.removeEventListener('message', (event) => this.windowEventListener(event), false);
        this.toasterSuccess();
        this.modalRef.hide();
    }

    redirectNotificationLink(url: string) {
        if (url) this.notificationLink = this.dom.bypassSecurityTrustResourceUrl(url);
        return this.notificationLink;
    }

    ngOnInit(): void {
        this.http
            .get(`${environment.API_BASE}/flush`, {
                params: {
                    suppress: 'notification',
                },
            })
            .pipe(take(1))
            .subscribe((d) => {});
        this.jwtService.clearState();

        this.appState.flush();

        let username = '';
        let password = '';

        this.subscriptions.add(
            this.route.queryParams.subscribe((d) => {
                username = d.username || this.username;
                password = d.password || this.password;
            })
        );
        this.subscriptions.add(
            this.route.fragment.subscribe((fragment) => {
                this.fragment = fragment;
                switch (fragment) {
                    case 'password':
                        this.loginForm.removeControl('username');
                        this.loginForm.removeControl('password');
                        this.loginForm.removeControl('passwordCopy');
                        this.loginForm.addControl('email', new FormControl('', [Validators.required]));
                        this.nextPage = ``;
                        this.whereto = '';
                        break;
                    case 'validate':
                        this.loginForm.removeControl('email');
                        this.loginForm.removeControl('username');
                        this.loginForm.addControl(
                            'password',
                            new FormControl('', [Validators.required, Validators.minLength(6)])
                        );
                        this.loginForm.addControl('passwordCopy', new FormControl('', [Validators.required]));
                        this.nextPage = '';
                        break;

                    default:
                        this.loginForm.removeControl('email');
                        this.loginForm.removeControl('passwordCopy');
                        this.loginForm.addControl(
                            'username',
                            new FormControl(username, [Validators.required, Validators.minLength(2)])
                        );
                        this.loginForm.addControl(
                            'password',
                            new FormControl(password, [Validators.required, Validators.minLength(6)])
                        );
                        this.whereto = 'password';
                        this.nextPage = 'forgotpassword';
                        break;
                }
            })
        );

        this.subscriptions.add(
            this.route.queryParams.subscribe((params) => {
                this.queryParams = params;
            })
        );
    }

    ngAfterContentInit(): void {
        setTimeout((d) => AppTitle.Instance.onTitleName.emit('A Global Payments Company'), 100);
    }

    private errorHandling(d: any) {
        let title = get(d, 'title', null);
        let message = get(d, 'detail', null);
        let type = 'success';
        const options = {};
        if (d.status) {
            switch (d.status) {
                case 200:
                    title = title || 'Success';
                    Object.assign(options, {
                        delay: 3000,
                    });
                    break;
                default:
                    title = title || 'Failed';
                    message = message || 'Server Error';
                    type = 'error';
                    break;
            }
        }

        this.toasterService[type](title, message, options);
    }

    public login(): void {
        if (!formValidate(this.loginForm)) {
            return;
        }

        const rawData = this.loginForm.getRawValue();
        Object.keys(rawData).forEach((k) => {
            rawData[k] = encodeURIComponent(rawData[k].trim());
        });

        switch (this.fragment) {
            case 'validate':
                Object.assign(rawData, this.queryParams);
                delete rawData.passwordCopy;
                this.loginService.loginConfirm(rawData).subscribe(
                    (d: any) => this.errorHandling(d),
                    (e) => this.errorHandling(e)
                );
                break;
            case 'password':
                this.loginService.forgetPassword(rawData).subscribe(
                    (d: any) => {
                        if (d.status) this.emailMessage = d.status;
                    },
                    (e) => this.errorHandling(e.error)
                );

                break;
            default:
                this.loginService
                    .login(rawData)
                    .pipe(
                        delay(1),
                        catchError((err) => throwError(err))
                    )
                    .subscribe((data) => {
                        let moveForward = false;
                        this.jwtService.setJwt(data.jwtToken);
                        const notice = get(data, 'notice', false);
                        if (notice) {
                            this.notificationMessage = get(notice, 'message', '');
                            this.redirectNotificationLink(get(notice, 'link', ''));
                            this.showRedirectAlert();
                            if (!this.notificationMessage) moveForward = true;
                        } else moveForward = true;

                        if (moveForward) {
                            const status = get(data, 'status', 301);
                            switch (status) {
                                case 301:
                                    this.toasterSuccess();
                                    break;
                                case 302:
                                    this.toasterService.success('Success', (data as any).title, {
                                        delay: 3000,
                                    });
                                    break;
                            }
                        }
                    });
                break;
        }
    }

    private toasterSuccess() {
        this.toasterService.success('Success', 'Rerouting', {
            delay: 3000,
        });

        return of(true as any)
            .pipe(timeout(1000))
            .subscribe((d) => {
                const command = '/';
                this.router.navigateByUrl(command, {});
            });
    }
}
