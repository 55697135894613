import { Component } from '@angular/core';

@Component({
    selector: 'text-helper-xeditor',
    templateUrl: `./text.xeditor.component.html`,
})
export class TextXeditorComponent {
    params = {
        description: '',
        width: 200,
    };
}
