<mat-checkbox
  [(ngModel)]="_model"
  [formControl]="ngModelCtrl"
  #checkboxRef
  [id]="id"
  [name]="name"
  [color]="indeterminate?null:color"
  [required]="required"
  [indeterminate]="indeterminate"
  [disableRipple]="true"
  (change)="modelChanged($event)"
  (click)="$event.stopPropagation()">
  <ng-content></ng-content>
</mat-checkbox>
