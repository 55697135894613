import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppDrawerComponent, IAppDrawerComponent } from './components/app-drawer/app-drawer.component';
import { APP_DRAWER_INITIALIZER } from './layout.services';
import { AppState } from '../app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WindowEvents } from '../components/window/WindowRef.service';

export type Content<T> = string | TemplateRef<T> | Type<T> | HTMLElement;

@Component({
    selector: 'app-layout',
    templateUrl: `./layout.component.html`,
})
export class LayoutComponent implements OnInit, OnDestroy {
    @ViewChild('maincontainer', { static: true }) maincontainer!: ElementRef;
    @ViewChild('appdrawer', { read: ViewContainerRef, static: true })
    private appdrawer!: ViewContainerRef;
    @ViewChild('vc', { read: ViewContainerRef, static: true })
    private vc!: ViewContainerRef;

    public username: string = '';
    public userMenu: any = [];
    public status: boolean = false;

    private componentRef: any;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appState: AppState,
        @Inject(APP_DRAWER_INITIALIZER) private appDrawerList: any,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef
    ) {}

    @HostListener('scroll', ['$event'])
    onScroll(e) {
        WindowEvents().OnScrollEvent.emit(e);
    }

    signOut(e) {
        this.router.navigate(['/signon'], {
            relativeTo: this.route,
        });
    }

    toggleAppDrawer(status) {
        this.status = status;
        if (status) {
            const ComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AppDrawerComponent);
            const ngContent = this.resolveNgContent(this.appDrawerList);

            this.appdrawer.clear();
            const componentRef = this.appdrawer.createComponent(ComponentFactory, undefined, undefined, ngContent);
            this.componentRef = componentRef;

            componentRef.hostView.detectChanges();
            const instance = componentRef.instance;

            setTimeout(() => instance.triggerClass(status));
            const subscriptions = instance.subscriptions;

            subscriptions.add(
                instance.toggled.subscribe((a) => {
                    this.status = a;

                    if (this.componentRef && a === false) {
                        this.componentRef.instance.animateOut();
                    }
                })
            );
            subscriptions.add(
                instance.onTransitionend.subscribe((a) => {
                    if (this.componentRef && a === false) {
                        this.componentRef.destroy();
                        this.componentRef = null;
                    }
                })
            );
        } else if (this.componentRef && !status) this.componentRef.instance.animateOut();
    }

    get MainElement(): HTMLElement {
        return this.maincontainer.nativeElement;
    }

    ngOnInit(): void {
        this.username = this.appState.get('USERNAME');
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private isHTML(str) {
        const a = document.createElement('div');
        a.innerHTML = str;
        for (let c = a.childNodes, i = c.length; i--; ) {
            if (c[i].nodeType === 1) return true;
        }
        return false;
    }

    private resolveNgContent<T>(content: Content<T>) {
        const ishtml = this.isHTML(content);
        if (!ishtml && typeof content === 'string') {
            const element = document.createTextNode(content);
            return [[element]];
        } else if (ishtml) {
            const a: any = document.createElement('div');
            a.innerHTML = content;

            if (a instanceof HTMLElement) content = a;
        }

        if (content instanceof HTMLElement) {
            return [[content]];
        }

        if (content instanceof TemplateRef) {
            const viewRef: any = this.viewContainerRef.createEmbeddedView(content as any);
            return [viewRef.rootNodes];
        }

        /** Otherwise it's a component */
        const factory = this.componentFactoryResolver.resolveComponentFactory(content as any);
        this.vc.clear();
        const componentRef = this.vc.createComponent(factory);
        this.subscriptions.add(
            (componentRef.instance as IAppDrawerComponent).onModuleClicked.subscribe((d) =>
                this.componentRef.instance.triggerClass(false)
            )
        );
        return [[componentRef.location.nativeElement]];
    }
}
