import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { get } from 'lodash';
import { JwtService } from './local_storage.service';
import { LocationResponse } from './location_response';
import { AllLocations } from './all_locations_response';

@Injectable()
export class LocationService {
    private headers = new HttpHeaders();

    constructor(private http: HttpClient, private jwtService: JwtService) {
        this.headers = this.headers.append('Authorization', `Bearer ${this.jwtService.getJwt()}`);
    }

    public getLocations = (aggregate?, startRow: number = 0, rowCount: number = 60): Observable<any> => {
        const options: any = { headers: this.headers };

        if (aggregate && aggregate.length)
            Object.assign(options, {
                params: {
                    aggregate: JSON.stringify(aggregate),
                },
            });

        return this.http.get<Array<AllLocations>>(`${environment.API_URL}/locations/${startRow}/${rowCount}`, options);
    };

    public getLocation(site_id?): Observable<Array<LocationResponse>> {
        const url = [`${environment.API_URL}/location`, site_id].filter((a) => a).join('/');
        return this.http.get(url, { headers: this.headers }).pipe(map((d) => get(d, '0', {})));
    }

    public updateLocation(site_id, params): Observable<LocationResponse> {
        const url = [`${environment.API_URL}/domain/location`, site_id].filter((a) => a).join('/');
        return this.http.put<LocationResponse>(url, params, { headers: this.headers });
    }

    addLocation(params) {
        return this.http.post<LocationResponse>(`${environment.API_URL}/domain/location`, params, { headers: this.headers });
    }
}
