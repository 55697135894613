import { Injectable } from '@angular/core';
import { CalendarOptions, DaterangeComponent } from './daterange.component';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

export interface DateObject {
    startDate: string;
    endDate: string;
}

@Injectable()
export class DateRangeService {
    private dialogRef;

    constructor(public dialog: MatDialog) {}

    show(options: CalendarOptions = {}): Observable<DateObject> | any {
        const subject = new Subject();

        this.hide();
        this.dialogRef = this.dialog.open(DaterangeComponent, {
            panelClass: 'mat-dialog-datepicker',
            data: {
                options: Object.assign({}, options, {
                    popup: true,
                    type: 'range',
                }),
            },
        });

        this.dialogRef.componentInstance.dialogRef = this.dialogRef;

        this.dialogRef.afterClosed().subscribe((d) => {
            this.dialogRef = null;
            if (d) subject.next(d);
            else subject.error(d);
            subject.complete();
        });

        return subject;
    }

    hide() {
        if (this.dialogRef) this.dialogRef.close();
    }
}
