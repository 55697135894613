import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class ProgressbarEmitterService {
    private static _instance: ProgressbarEmitterService;

    currentState: boolean = false;

    onCurrentState: EventEmitter<any> = new EventEmitter<any>();
    onStateStatus: EventEmitter<any> = new EventEmitter<any>();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Injectable()
export class ProgressbarService {
    private subject = new Subject();

    constructor() {
        this.Instance.onStateStatus.subscribe((d) => {
            this.subject.next(d);
        });
    }

    public start() {
        if (this.CurrentState !== true) {
            this.CurrentState = true;
            this.Instance.onCurrentState.emit(true);
        }
        return this.subject;
    }

    public stop() {
        const subject = new Subject();
        this.Instance.onStateStatus.subscribe((d) => subject.next(d));

        if (this.CurrentState !== false) {
            this.CurrentState = false;
            this.Instance.onCurrentState.emit(false);
        }
        return subject;
    }

    private get Instance() {
        return ProgressbarEmitterService.Instance;
    }

    private get CurrentState() {
        return this.Instance.currentState;
    }

    private set CurrentState(state) {
        this.Instance.currentState = state;
    }
}
