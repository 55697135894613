import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { get } from 'lodash';
import { getStatus } from './status';

@Component({
    selector: 'ag-status-cell',
    template: ` <div class="badge py-1 px-2 text-uppercase {{ color }}" style="min-width: 70px;">
        <span [innerHTML]="status"></span>
    </div>`,
    styles: [
        `
            :host {
                cursor: default;
            }
        `,
    ],
})
export class AgStatusComponent implements AgRendererComponent {
    public error: string = '';
    public hasError: boolean = false;
    public status: string = 'READING';
    public color: string = 'badge-success';

    refresh(params: any): any {
        return null as any;
    }

    agInit(params: any): void {
        if (!params.data) return;
        this.error = get(params, 'data.error', '');
        this.hasError = !!this.error;

        const { status, color } = getStatus(get(params, 'value', 'READING').toUpperCase());
        this.status = status;
        this.color = color;
    }
}
