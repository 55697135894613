import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlHelperService } from './url.helper.service';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'account-invoice',
    styleUrls: ['./invoice.component.scss'],
    templateUrl: `./invoice.component.html`,
})
export class InvoiceComponent implements OnInit, OnDestroy {
    @ViewChild('iframe') iframe!: ElementRef;

    private subscriptions: Subscription = new Subscription();

    public url!: string | null | SafeResourceUrl;

    constructor(private route: ActivatedRoute, private urlHelperService: UrlHelperService, private dom: DomSanitizer) {}

    ngOnInit(): void {
        let format = null;
        this.subscriptions.add(
            this.route.queryParams.subscribe((d) => {
                format = d.format;
            })
        );
        this.subscriptions.add(
            this.route.params.subscribe((d) => {
                if (d.transactionNumber && d.customerId) {
                    const queryString: string[] = [];
                    if (format) queryString.push(`format=${format}`);

                    const pdfurl = `${environment.API_URL}/billing/transaction/${d.transactionNumber}/${d.customerId}`;
                    this.subscriptions.add(
                        this.urlHelperService
                            .getBlob(pdfurl)
                            .pipe(delay(1))
                            .subscribe((data) => {
                                this.url = this.dom.bypassSecurityTrustResourceUrl(data);
                            })
                    );
                } else this.url = null;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    get IframeLayout(): HTMLIFrameElement {
        return this.iframe.nativeElement;
    }
}
