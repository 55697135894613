import { EventEmitter, Injectable, Output } from '@angular/core';
import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { get } from 'lodash';
import { getConditions } from './conditions';

// @dynamic
export class filterObject {
    filter: string = '';
    filterType: string = 'text';
    type: string = 'contains';

    constructor(obj: any) {
        if (!obj) return;

        let value =
            obj.hasOwnProperty('value') && typeof obj.value === 'string'
                ? obj.value.toString()
                : obj.hasOwnProperty('value')
                ? obj.value
                : obj;
        if (obj.hasOwnProperty('type'))
            switch (obj.type) {
                case 'boolean':
                    this.filterType = 'boolean';
                    break;
                case 'number':
                    this.filterType = 'number';
                    value = parseFloat(obj.value);
                    break;
                case 'object':
                    this.type = obj.type;
                    break;
                default:
                    break;
            }

        this.filter = value;
    }
}

@Injectable()
export class DataSourceService implements IDatasource {
    @Output() onDatasourceReady: EventEmitter<any> = new EventEmitter<any>();

    public rowCount: number = 40;
    public condition: boolean = false;
    public startRow: number = 0;
    public gridApi!: GridApi;

    set GridApi(api: GridApi) {
        this.gridApi = api;
    }

    queryDatasource(aggregate, startRow): Observable<any[]> {
        return of([]);
    }

    queryFilter(param) {
        const queryFilterObj = {};
        Object.keys(param).forEach((k) => {
            const value = param[k];
            queryFilterObj[k] = new filterObject(param[k]);
        });

        return queryFilterObj;
    }

    QueryFilterOverride(filterModel: any[] = [], condition: string = '$or'): any[] {
        const hasQuery = !!filterModel.length;
        const $match = {};
        if (hasQuery) $match[condition] = filterModel;
        return !!Object.keys($match).length ? [{ $match }] : [];
    }

    /**
     * https://www.ag-grid.com/documentation/javascript/filter-provided-simple/
     * @param params
     */
    getRows(params: IGetRowsParams): void {
        const filterModel = params.filterModel;
        const sortModel = params.sortModel;

        const query = filterModel
            ? Object.keys(filterModel).map((columnName) => {
                  const _handler = {};
                  const operator = get(filterModel[columnName], 'operator', 'AND').toUpperCase().trim();
                  const isAnd = operator === 'AND';
                  const condition1 = get(filterModel[columnName], 'condition1', filterModel[columnName]);
                  const condition2 = get(filterModel[columnName], 'condition2', null);
                  const conditions = [condition1, condition2].filter((a) => a).map((c) => getConditions(c));
                  _handler[isAnd ? '$and' : '$or'] = conditions.map((c) => {
                      const _p = {};
                      _p[columnName] = c;
                      return _p;
                  });
                  return _handler;
              })
            : [];

        let lastRow = -1;
        const _q = this.QueryFilterOverride(query);

        const $sort = {};
        const aggregate: any = ([] as any).concat(_q);

        if (sortModel)
            sortModel.forEach((s) => {
                if (s.colId === 'staffmember_name') {
                    const lowerCaseId = `${s.colId}LowerCase`;
                    aggregate.push({
                        $addFields: {
                            [lowerCaseId]: {
                                $toLower: '$' + s.colId,
                            },
                        },
                    });
                    $sort[lowerCaseId] = s.sort === 'asc' ? 1 : -1;
                } else {
                    $sort[s.colId] = s.sort === 'asc' ? 1 : -1;
                }
                aggregate.push({ $sort });
            });

        of(aggregate)
            .pipe(
                distinctUntilChanged(),
                switchMap((query) => {
                    return this.queryDatasource(query, params.startRow);
                })
            )
            .subscribe(
                (data) => {
                    const offset = params.endRow - params.startRow;
                    const lastCount = params.startRow + data.length;
                    lastRow = data.length < offset ? lastCount : data.length === offset ? -1 : lastCount;

                    params.successCallback(data, lastRow);

                    if (this.gridApi) {
                        try {
                            if (!!data.length) this.gridApi.hideOverlay();
                            else this.gridApi.showNoRowsOverlay();
                        } catch (e) {}
                    }
                },
                (err) => {
                    console.error(err);
                },
                () => {
                    this.onDatasourceReady.emit({
                        api: this.gridApi,
                        lastRow,
                    });
                }
            );
    }
}

