import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class ValidateUser {
    constructor(private authService: AuthService) {}

    get AccessLevels() {
        return this.authService.AccessLevel.slice();
    }

    private getArray(access_levels) {
        return (Array.isArray(access_levels) ? access_levels : [access_levels]).map((a) =>
            !isNaN(Number(a)) ? parseInt(a, 10) : a && a.toUpperCase()
        );
    }

    contains(request: any[] = [], client_access_levels = this.authService.AccessLevel.slice()) {
        const access_levels = this.getArray(request);
        return access_levels.filter((e) => {
            return client_access_levels.includes(e);
        });
    }

    private validateUserRole(request: any[] = [], client_access_levels = this.authService.AccessLevel.slice()) {
        const access_levels = this.getArray(request);
        const intersection = this.contains(request);
        return intersection.length === access_levels.length;
    }

    hasRole(request: any[] = []): boolean {
        const hasRequestingRole = this.validateUserRole(request);
        /*
		 Checks if the user is a Master or Super User, if true.
		 Regardless the permission, it will return true, or based on the requested parameters
		 */
        const isSuperUser = this.validateUserRole(['USER.SUPER']);
        /**/

        return isSuperUser || hasRequestingRole;
    }
}
