<div class="tip-yellowsimple editable-container editable-popup" #xeditable>
  <div class="tip-inner tip-bg-image">
    <div><label>{{options.title}}</label>
      <div>
        <div class="editableform-loading" style="display: none;"></div>
        <form class="form-inline editableform" style="">
          <div>
            <div #editorBody></div>
            <!--<div class="editable-error-block" style="display: none;"></div>-->
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="tip-arrow" #tipArrow style="visibility: inherit;"></div>
</div>
