<div class="mtr-datepicker" [ngClass]="{'mtr-disabled':_disabled}">
    <div class="mtr-row d-flex flex-row">
        <div class="mtr-input-slider" id="datepicker-demo-custom-input-hours" #inputSliderHrs>
            <div class="mtr-arrow up" (click)="hrsClick($event,'up')"><span></span></div>
            <div class="mtr-content" #mtContentHrs>
                <input type="tel"
                       class="mtr-input hours"
                       [(ngModel)]="hrsModel"
					   #inputHrs
                       data-test="timepicker-hour">
                <div class="mtr-values">
                    <div class="mtr-default-value-holder" [attr.data-value]="hrsModel">
                        <div class="mtr-default-value" [attr.data-value]="hrsModel">{{hrsModel}}</div>
                    </div>
                </div>
            </div>
            <div class="mtr-arrow down" (click)="hrsClick($event,'down')"><span></span></div>
        </div>
        <div class="mtr-input-slider" id="datepicker-demo-custom-input-minutes" #inputSliderMin>
            <div class="mtr-arrow up" (click)="minClick($event, 'up')"><span></span></div>
            <div class="mtr-content" #mtContentMin>
                <input type="tel"
                       class="mtr-input minutes"
                       [(ngModel)]="minModel"
					   #inputMin
                       data-test="timepicker-minutes">
                <div class="mtr-values">
                    <div class="mtr-default-value-holder" [attr.data-value]="minModel">
                        <div class="mtr-default-value" [attr.data-value]="minModel">{{minModel}}</div>
                    </div>
                </div>

            </div>
            <div class="mtr-arrow down" (click)="minClick($event, 'down')"><span></span></div>
        </div>
        <div class="gl-input-radio d-flex flex-column" id="datepicker-demo-custom-input-ampm">
            <div class="custom-control custom-radio">
                <input type="radio"
                       [name]="am_id"
                       [attr.id]="am_id"
                       value="AM"
                       class="custom-control-input"
                       (ngModelChange)="onAMPMChange($event, 'AM')"
                       [(ngModel)]="ampm"
                       [ngModelOptions]="{standalone:true}"
                       data-test="timepicker-am">
                <label class="custom-control-label h-100 d-flex align-items-center"
                       [attr.for]="am_id">AM</label>
            </div>

            <div class="custom-control custom-radio">
                <input type="radio"
                       [name]="pm_id"
                       [attr.id]="pm_id"
                       value="PM"
                       class="custom-control-input"
                       [(ngModel)]="ampm"
                       (ngModelChange)="onAMPMChange($event, 'PM')"
                       [ngModelOptions]="{standalone:true}"
                       data-test="timepicker-pm">
                <label class="custom-control-label h-100 d-flex align-items-center"
                       [attr.for]="pm_id">PM</label>
            </div>

        </div>
    </div>
</div>
