import { EventEmitter, Inject, Injectable, InjectionToken } from '@angular/core';

import { Observable, isObservable } from 'rxjs';

export const APP_DRAWER_INITIALIZER = new InjectionToken<string>('APP_DRAWER_INITIALIZER');
export const SITE_HEADER_INITIALIZER = new InjectionToken<string>('SITE_HEADER_INITIALIZER');

export interface IAPPCONFIG {
    active?: boolean;
    path: string;
    translate: string;
    faIcon?: any;
    disabled?: boolean;
    permission: string[];
}

@Injectable()
export class LayoutServices {
    private onAppDrawerChanges: EventEmitter<any> = new EventEmitter<any>();

    constructor(@Inject(APP_DRAWER_INITIALIZER) private appDrawerList: any) {}

    getAppList(): Observable<IAPPCONFIG[]> {
        return new Observable((obs) => {
            const type = typeof this.appDrawerList;
            if (type === 'function') {
                const startAsync: any = async (callback) => {
                    const _app = this.appDrawerList();
                    if (isObservable(_app)) {
                        await _app.subscribe((d) => callback(d));
                    } else callback(_app);
                };
                startAsync((text) => obs.next(text));
            } else obs.next(this.appDrawerList);
        });
    }

    onBindDraggabillyEvents() {
        return this.onAppDrawerChanges;
    }
}
