import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WelcomeEmailComponent } from './welcome.email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserService } from '../capi/users/services/user.service';
import { ConfirmModules } from '../../modals/confirm-input/confirm.modules';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { ToasterModule } from 'src/app/components/toast/toast.module';

@NgModule({
    declarations: [WelcomeEmailComponent],
    imports: [CommonModule, InputModule, ToasterModule, ConfirmModules, ReactiveFormsModule],
    exports: [WelcomeEmailComponent, FormsModule, ReactiveFormsModule],
    providers: [UserService],
    entryComponents: [WelcomeEmailComponent],
})
export class WelcomeEmailModule {}
