import { Injectable } from '@angular/core';
import { EditLocationComponent } from './editlocation.component';
import { LocationService } from '../../../services/location.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToasterService } from 'src/app/components/toast/toaster.service';
import { get } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EditLocationNotifier } from './editlocation.notifier';

@Injectable()
export class EditlocationService {
    private subscriptions: Subscription = new Subscription();

    constructor(
        private locationService: LocationService,
        private toasterService: ToasterService,
        private modalService: BsModalService
    ) {}

    openEditLocation(site_id) {
        this.locationService.getLocation(site_id).subscribe(
            (locationData: any) => {
                const componentRef: any = this.modalService.show(EditLocationComponent, {
                    ignoreBackdropClick: true,
                    class: 'mw-850-px edit-location-modal',
                    initialState: {
                        subscriptions: this.subscriptions,
                        siteData: locationData,
                    },
                });

                componentRef.content.buildFormGroup(locationData);
            },
            (err) => this.showError(err)
        );
    }

    updateLocation(params, subject = new Subject()) {
        if (!params.site_id) {
            setTimeout((d) => {
                subject.error('missing site_id');
                subject.complete();
            });
            return subject;
        }

        const subscription = this.locationService
            .updateLocation(params.site_id, params)
            .pipe(
                finalize(() => {
                    subscription.unsubscribe();
                })
            )
            .subscribe(
                (data) => {
                    this.toasterService.success(
                        'Updated Successfully',
                        `
          Name: ${data.name}
          `,
                        {
                            delay: 3000,
                        }
                    );
                    subject.next(data);
                },
                (err) => {
                    subject.error(err);
                    this.showError(err);
                },
                () => subject.complete()
            );
        return subject;
    }

    private closeModalEmitter(d?) {
        EditLocationNotifier.Instance.onHide.emit(d);
    }

    unsubscribe() {
        this.subscriptions.unsubscribe();
    }

    private showError(err) {
        const title = get(err, 'error.title', 'Server Error');
        const msg = get(err, 'error.detail', title) || title;
        this.toasterService.error('Error', msg, {
            delay: 5000,
        });
    }
}
