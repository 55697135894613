/**
 * Created by reyra on 5/21/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from './toggle-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [ToggleButtonComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [ToggleButtonComponent, FormsModule, ReactiveFormsModule],
    entryComponents: [ToggleButtonComponent],
})
export class ToggleButtonModule {
    constructor() {}
}
