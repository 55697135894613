import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { get } from 'lodash';

@Component({
    selector: 'ag-acl',
    template: ` <div
        class="badge py-1 px-2 text-uppercase cursor-default"
        style="min-width: 70px;"
        [ngClass]="{ 'badge-danger': !status, 'badge-success': status }"
    >
        <div [ngSwitch]="status">
            <div *ngSwitchCase="false">
                {{ 'private' | uppercase }}
            </div>
            <div *ngSwitchDefault>
                {{ 'public' | uppercase }}
            </div>
        </div>
    </div>`,
    styles: [
        `
            :host {
                cursor: default;
            }
        `,
    ],
})
export class AclComponent implements AgRendererComponent {
    public status: boolean = true;

    refresh(params: any): any {
        return null as any;
    }

    agInit(params: any): void {
        const value = get(params, 'value', 'public-read');
        this.status = value === 'public-read';
    }
}
