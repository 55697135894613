import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageFooterComponent } from './footer.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [PageFooterComponent],
    imports: [CommonModule, FormsModule],
    entryComponents: [PageFooterComponent],
    exports: [PageFooterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PageFooterModule {}
