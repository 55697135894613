import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchBarComponent } from './search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InputModule } from 'src/app/components/Inputs/input.module';

@NgModule({
    declarations: [SearchBarComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, InputModule],
    exports: [SearchBarComponent, FormsModule, ReactiveFormsModule],
    providers: [],
    entryComponents: [SearchBarComponent],
})
export class SearchBarModules {}
