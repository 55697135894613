import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { filterObject } from '../agGridDatasource/data.source.service';

@Component({
    selector: 'search-bar[formControlName], search-bar[formControl], search-bar[ngModel]',
    templateUrl: './search-bar.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchBarComponent),
            multi: true,
        },
    ],
})
export class SearchBarComponent implements OnInit, ControlValueAccessor {
    public _model: string = '';

    @Input() filterParams: any = {
        name: {
            type: 'string',
            value: '',
        },
    };

    @Input() position: string = 'left';
    @Input() placeholder: string = 'Search';
    @Input() typedaheadDatasets: any;

    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onClearFilter: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public ngModelCtrl!: AbstractControl;
    public typedahead: boolean = false;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    ngOnInit(): void {
        if (this.typedaheadDatasets) this.typedahead = !!Object.keys(this.typedaheadDatasets).length;
        this.renderer.addClass(this.LayoutElement, `pos-${this.position}`);
    }

    get LayoutElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    set ngValue(v: any) {
        this._model = v;
    }

    get ngValue() {
        return this._model;
    }

    modelChanged(e) {
        this.ngValue = e;
        if (this.ngModelCtrl) this.ngModelCtrl?.setValue(this.ngValue);

        if (!e.length) this.clearAllFilters();

        if (e.length >= 3) {
            const filtersObj: any = {};

            Object.keys(this.filterParams).forEach((k) => {
                const value = (this.filterParams[k].value = e.trim());
                if (value != null) filtersObj[k] = new filterObject(this.filterParams[k]);
            });

            of(filtersObj as any)
                .pipe(debounceTime(300), distinctUntilChanged())
                .subscribe((d) => {
                    this.onChange.emit(d);
                    this.onChangeCallback(d);
                    this.onTouchedCallback(d);
                });
        }
    }

    clearAllFilters() {
        this.ngValue = '';
        this.onClearFilter.emit(null);
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        if (obj !== this.ngValue) this.ngValue = obj;
    }

    private onTouchedCallback = (v: any) => {};

    private onChangeCallback = (v: any) => {};
}
