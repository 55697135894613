import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {
    private readonly jwt_key = 'capi_token';
    private readonly selected_site_id_key = 'capi_selected_site_id';

    getJwt() {
        return localStorage.getItem(this.jwt_key);
    }

    clearState() {
        localStorage.clear();
    }

    setJwt(jwt: string) {
        localStorage.setItem(this.jwt_key, jwt);
    }

    setCurrentSiteId(site_id: string) {
        localStorage.setItem(this.selected_site_id_key, site_id);
    }

    getCurrentSiteId() {
        return localStorage.getItem(this.selected_site_id_key);
    }
}
