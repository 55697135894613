import { Injectable } from '@angular/core';
import { IDatasource } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { LocationService } from './location.service';
import { map } from 'rxjs/operators';
import { DataSourceService } from '../components/agGridDatasource/data.source.service';

@Injectable()
export class LocationDataSourceService extends DataSourceService implements IDatasource {
    constructor(private locationService: LocationService) {
        super();
    }

    queryDatasource(aggregate, startRow): Observable<any[]> {
        aggregate = aggregate.concat([
            {
                $match: {
                    $and: ([] as any).concat([
                        {
                            enabled: {
                                $exists: true,
                            },
                        },
                        {
                            enabled: true,
                        },
                    ]),
                },
            },
        ]);

        return this.locationService.getLocations(aggregate, startRow, 60).pipe(
            map((data) => {
                const locations = cloneDeep(data).map((d: any, index) => {
                    if (!d.$$index_ap) d.$$index_ap = d._id;
                    return d;
                });
                return locations;
            })
        );
    }
}
