import { ColumnApi, GridApi, GridOptions, IDatasource } from 'ag-grid-community';
import { Observable, Subject, Subscription } from 'rxjs';
import { EventEmitter, Output, Directive } from '@angular/core';
import { get } from 'lodash';
import { WindowEvents, WindowRefService } from '../window/WindowRef.service';

interface IGridDataSource extends IDatasource {
    GridApi: GridApi;
}

class GridRefEvents {
    private static _instance: GridRefEvents;

    public OnScrollEvent: EventEmitter<HTMLElement> = new EventEmitter();
    public OnResizeEvent: EventEmitter<any> = new EventEmitter();

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

export abstract class OnAdminInfiniteGridClass {
    gridOptions!: GridOptions;
    gridApi!: GridApi;

    abstract getColumnDefs(): any[];
}

export function GridEvents() {
    return GridRefEvents.Instance;
}

class DefaultGridOptions {
    protected windowRef: WindowRefService = WindowRefService.Instance;

    get GridOptions(): any {
        return {
            suppressCellSelection: true,
            suppressMovableColumns: true,
            rowModelType: 'infinite',
            cacheBlockSize: 60,
            debug: false,
            headerHeight: 30,
            rowSelection: 'single',
            onBodyScroll: (e) => {
                WindowEvents().OnScrollEvent.emit(e);
                GridEvents().OnScrollEvent.emit(e);
            },
            onGridSizeChanged: (e) => {
                GridEvents().OnResizeEvent.emit(e);
            },
        };
    }
}

@Directive()
export class AdminInfiniteGridClass implements OnAdminInfiniteGridClass {
    @Output() onGridReady: EventEmitter<any> = new EventEmitter<any>();

    public gridOptions: GridOptions = {};
    public columnDefs!: any[];
    public gridApi!: GridApi;
    public columnApi!: ColumnApi;
    public subscriptions: Subscription = new Subscription();

    constructor(public dataSource: IGridDataSource) {}

    getColumnDefs(): any[] {
        return [];
    }

    onColumnResized(event) {
        if (event.finished) this.gridApi.resetRowHeights();
    }

    setGridOptions(): Observable<any> {
        const subject = new Subject();

        const gridOpts = new DefaultGridOptions();

        const _onGridReady = this.gridOptions.hasOwnProperty('onGridReady') ? this.gridOptions.onGridReady : noop;

        this.subscriptions.add(
            WindowEvents().OnResizeEvent.subscribe((data) => {
                if (this.gridApi) {
                    this.gridApi.sizeColumnsToFit();
                    this.gridApi.doLayout();
                }
            })
        );

        const enableServerSideSorting = get(this.gridOptions, 'enableServerSideSorting', true);
        const enableServerSideFilter = get(this.gridOptions, 'enableServerSideFilter', true);

        this.gridOptions = Object.assign({}, gridOpts.GridOptions, this.gridOptions, {
            onGridReady: (params) => {
                this.gridApi = params.api;

                this.columnApi = params.columnApi;

                this.gridApi.setColumnDefs(this.columnDefs);

                this.dataSource.GridApi = this.gridApi;

                this.gridApi.setDatasource(this.dataSource);

                this.gridApi.sizeColumnsToFit();

                this.gridApi.doLayout();

                subject.next(params);

                subject.complete();
                if (!_onGridReady) return;
                _onGridReady(params);

                this.onGridReady.emit(params);
            },
        });

        delete this.gridOptions['enableServerSideSorting'];
        delete this.gridOptions['enableServerSideFilter'];

        this.columnDefs = this.getColumnDefs().map((k) => {
            k['sortable'] = k.hasOwnProperty('sortable') ? k['sortable'] : enableServerSideSorting;
            k['filter'] = k.hasOwnProperty('filter') ? k['filter'] : enableServerSideFilter;
            return k;
        });

        return subject;
    }
}

function noop() {}
