import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmComponent } from './confirm.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ConfirmService } from './confirm.service';
import { InputModule } from 'src/app/components/Inputs/input.module';

@NgModule({
    declarations: [ConfirmComponent],
    imports: [CommonModule, ClipboardModule, ModalModule.forRoot(), InputModule],
    exports: [ConfirmComponent],
    providers: [ConfirmService],
    entryComponents: [ConfirmComponent],
})
export class ConfirmModules {}
