import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToastComponent } from './toast.component';
import { ToasterService } from './toaster.service';
import { TastyComponent } from './tasty.component';

@NgModule({
    declarations: [ToastComponent, TastyComponent],
    imports: [CommonModule, RouterModule],
    exports: [ToastComponent],
    providers: [ToasterService],
    entryComponents: [ToastComponent, TastyComponent],
})
export class ToasterModule {}
