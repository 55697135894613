import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserAppDataService {
    constructor(private http: HttpClient) {}

    getUserdata() {
        return this.http.get(`${environment.API_URL}/userdata`);
    }

    putUserdata(params) {
        return this.http.put(`${environment.API_URL}/userdata`, params);
    }
}
