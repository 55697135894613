export const SIDEBAR_ROUTES = [
    {
        path: `/payroll`,
        name: 'Payroll',
        faIcon: 'fa-user-cog',
        permission: ['UI.PAYROLL', 'API.PAYROLL'],
        queryParamsHandling: 'merge',
        routerLinkActiveOptions: { exact: false },
        children: [
            {
                path: '/payroll',
                name: 'Dashboard',
                queryParamsHandling: 'merge',
                permission: ['UI.PAYROLL.DASHBOARD'],
                showui: false,
                sitemap: true,
            },
            {
                path: '/payroll/batches',
                name: 'Batches',
                queryParamsHandling: 'merge',
                permission: ['API.PAYROLL'],
                children: [],
            },
            {
                path: '/payroll/configuration',
                showui: false,
                name: 'Configuration',
                queryParamsHandling: 'merge',
                permission: ['UI.PAYROLL.CONFIGURATION'],
                sitemap: false,
                children: [
                    {
                        path: '/payroll/configuration/site',
                        name: 'Site Information',
                        queryParamsHandling: 'merge',
                        showui: false,
                        permission: ['UI.PAYROLL.SITE.CONFIGURATION'],
                    },
                    {
                        path: '/payroll/configuration/employee',
                        name: 'Employee',
                        queryParamsHandling: 'merge',
                        showui: false,
                        permission: ['UI.PAYROLL.EMPLOYEE.CONFIGURATION'],
                    },
                ],
            },
        ],
    },
    {
        path: '/payroll/configuration',
        faIcon: 'fa-cogs',
        name: 'Configuration',
        sitemapTranslate: 'payrollConfiguration',
        queryParamsHandling: 'merge',
        permission: ['UI.PAYROLL.CONFIGURATION'],
        routerLinkActiveOptions: { exact: false },
        children: [
            {
                path: '/payroll/configuration/site',
                name: 'Site Information',
                notifications: ['invalidPayrollID', 'invalidDepartmentCodes'],
                queryParamsHandling: 'merge',
                permission: ['UI.PAYROLL.SITE.CONFIGURATION'],
                children: [],
            },
            {
                path: '/payroll/configuration/employee',
                name: 'Employee',
                notifications: ['invalidEmployeePayrollIDs'],
                queryParamsHandling: 'merge',
                permission: ['UI.PAYROLL.EMPLOYEE.CONFIGURATION'],
                children: [],
            },
        ],
    },
];
