import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HoursofoperationsComponent } from './hoursofoperations.component';
import { XEditorComponentsModule } from '../xEditor/xEditor.module';
import { MatSelectModule } from '@angular/material/select';
import { CheckboxModule } from 'src/app/components/checkbox/checkbox.module';
import { XeditorModule } from 'src/app/components/xEditor/xeditor.module';

@NgModule({
    declarations: [HoursofoperationsComponent],
    imports: [CommonModule, FormsModule, XeditorModule, XEditorComponentsModule, MatSelectModule, CheckboxModule],
    exports: [FormsModule, HoursofoperationsComponent],
    entryComponents: [HoursofoperationsComponent],
})
export class HoursOfOperationsModule {}
