import { EventEmitter, Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { IWindowReference } from './window.service';

function _window(): any {
    return window;
}
class WindowRefEvents {
    static _instance: WindowRefEvents;
    public OnScrollEvent: EventEmitter<HTMLElement> = new EventEmitter();
    public OnResizeEvent: EventEmitter<any> = new EventEmitter();
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

export function WindowEvents() {
    return WindowRefEvents.Instance;
}

@Injectable()
export class WindowRefService {
    static _instance: WindowRefService;
    public OnScrollEvent: EventEmitter<HTMLElement> = new EventEmitter();
    public _docOrientation!: Observable<any>;
    public _windowObjectReferences: IWindowReference[] = [];

    constructor() {
        fromEvent(document.body, 'scroll').subscribe((e: any) => {
            WindowRefEvents.Instance.OnScrollEvent.emit(e);
            this.OnScrollEvent.emit(e);
        });

        WindowRefEvents.Instance.OnScrollEvent.subscribe((e) => this.OnScrollEvent.emit(e));
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    get nativeWindow(): any {
        return _window();
    }

    public setWindowObjectReference(opts: IWindowReference) {
        this._windowObjectReferences.push(opts);
    }

    public CloseAllWindowReferences() {
        const meta: IWindowReference[] = this._windowObjectReferences;
        for (let i = 0, item; (item = meta[i]) && i < meta.length; i++) {
            const windowRef = item.windowRef;
            if (windowRef) windowRef.close();
        }
    }
}

export interface IWindowObjectReference {
    PostMessenger?: any;
    postMessage?: (message: string, targetOrigin: any, transfer?: any) => void;
}

export interface IStrWindowFeatures {
    left?: number;
    top?: number;
    height?: number;
    width?: number;
    centerscreen?: string;
    outerHeight?: number;
    outerWidth?: number;
    innerHeight?: number;
    innerWidth?: number;
    menubar?: boolean;
    toolbar?: boolean;
    location?: boolean;
    personalbar?: boolean;
    directories?: boolean;
    status?: boolean;
    dependent?: boolean;
    minimizable?: boolean;
    dialog?: boolean;
    resizable?: boolean;
    scrollbars?: boolean;
}
