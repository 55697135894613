<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-12">
            <header>Integrations</header>
        </div>
    </div>
    <ng-content></ng-content>
    <div class="row mt-3 mb-0">
        <section class="col-6 col-lg-4 radio-col form-group" *ngIf="isSupport || (showPayrolConnector && !isSupport)">
            <label class="label nowrap truncate">Payroll Connector</label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="payrollConnectorChange($event)"
                    formControlName="payrollConnector.active"
                >
                </toggle-button>
                <div class="" *ngIf="showPayrollConnectorEdit">
                    <button
                        [disabled]="!formGroup.controls['payrollConnector.active'].value"
                        type="button"
                        class="edit-payroll-connector-btn btn btn-sm btn-outline-secondary btn-block"
                        (click)="editPayrollConnector($event)"
                    >
                        <i class="fa fa-fw fa-edit"></i>
                        Edit
                    </button>
                </div>
            </div>
        </section>
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('checkmate') && !isSupport)"
        >
            <label class="label nowrap truncate">Checkmate</label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="checkmate.active"
                >
                </toggle-button>
                <div class=""></div>
            </div>
        </section>
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('techPortal') && !isSupport)"
        >
            <label class="label nowrap truncate">Tech Portal</label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="techPortal.active"
                >
                </toggle-button>
                <div class=""></div>
            </div>
        </section>
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('pointy') && !isSupport)"
        >
            <label class="label nowrap truncate d-flex justify-content-between">
                <x-editor
                    [title]="'Pointy'"
                    [config]="{
                        bodyRendererFramework: localBodyRendererFramework,
                        params: pointyDescription,
                        position: 'top'
                    }"
                    [(ngModel)]="pointyDescription.text"
                    [ngModelOptions]="{ standalone: true }"
                >
                    <span class="pl-1">
                        <i class="fa fa-fw fa-question-circle"></i>
                    </span>
                </x-editor>
            </label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onPointyChange($event)"
                    formControlName="pointy.active"
                >
                </toggle-button>
                <div class="">
                    <button
                        [disabled]="!formGroup.controls['pointy.active'].value"
                        type="button"
                        class="edit-pointy-btn btn btn-sm btn-outline-secondary"
                        (click)="editPointy($event)"
                    >
                        <i class="fa fa-fw fa-edit"></i>
                        Edit
                    </button>
                </div>
            </div>
        </section>
    </div>
    <div class="row">
        <div class="col-12">
            <header>Subscriptions</header>
        </div>
    </div>
    <div class="row mt-3">
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('mobileManager') && !isSupport)"
        >
            <div
                [attr.aria-label]="'Roll Up'"
                data-balloon-pos="right"
                (click)="clickRollUpModifierPrice($event)"
                class="hover-rollupmodifier"
            ></div>
            <label class="label nowrap truncate">
                <checkbox
                    [attr.id]="'rollUpModifierPrice'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="mobileManager.rollUpModifierPrice"
                >
                    <span>Mobile Manager</span>
                </checkbox>
            </label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="mobileManager.active"
                >
                </toggle-button>
                <div class="">
                    <button
                        type="button"
                        [disabled]="!formGroup.controls['mobileManager.active'].value"
                        class="edit-mm-btn btn text-nowrap btn-sm btn-outline-secondary"
                        (click)="triggerMobileManager($event)"
                    >
                        <i class="fa fa-fw fa-cloud-upload-alt"></i>
                        Send Data
                    </button>
                </div>
            </div>
        </section>
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('mobileInventory') && !isSupport)"
        >
            <label class="label nowrap truncate">Mobile Inventory</label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="mobileInventory.active"
                >
                </toggle-button>
                <div class=""></div>
            </div>
        </section>
        <section
            class="col-6 col-lg-4 radio-col form-group"
            *ngIf="isSupport || (showIntegrators.includes('como') && !isSupport)"
        >
            <label class="label nowrap truncate">COMO</label>
            <div class="d-flex flex-row flex-wrap">
                <toggle-button
                    class="mr-2 mb-2"
                    [LabelOffTxt]="'Disabled'"
                    [LabelOnTxt]="'Enabled'"
                    [readonly]="readonly"
                    (ngModelChange)="onModelChange($event)"
                    formControlName="como.active"
                >
                </toggle-button>
                <div class=""></div>
            </div>
        </section>
    </div>
</form>
