import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResponse } from './login_response';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient) {}

    login(params): Observable<LoginResponse> {
        return this.http.post<LoginResponse>(`${environment.API_BASE}/login`, params);
    }

    forgetPassword(params) {
        return this.http.post(`${environment.API_BASE}/login/reset`, params);
    }

    loginConfirm(params) {
        return this.http.post(`${environment.API_BASE}/login/confirm?`, params);
    }
}
