import { AgRendererComponent } from 'ag-grid-angular';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'ag-location',
    template: ` <i class="fa fa-info-circle"></i> `,
    styles: [
        `
            :host:hover {
                color: #1d6f42;
            }

            i.fa {
                color: #cccccc;
                cursor: pointer;
                font-size: 14px;
            }
        `,
    ],
})
export class LocationComponent implements AgRendererComponent {
    public params: any;

    constructor(private router: Router) {}

    @HostListener('click', ['$event'])
    onClickDash(e) {
        if (e) e.stopPropagation();
        const data = this.params.data;

        this.router.navigate(['capi', 'admin-privileges', 'site-id', data.site_id], {
            queryParamsHandling: 'merge',
        });
    }

    refresh(params: any): any {
        return null as any;
    }

    agInit(params: any): void {
        this.params = params;
    }
}
