import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SiteSelectionGridComponent } from './site.selection.grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { LocationDataSourceService } from '../../../services/location.data.source.service';
import { LocationService } from '../../../services/location.service';
import { NgContentInjectionService } from '../../../components/ngContentInjection/ng-content-injection.service';

import { AgComponentsModule } from '../../../agComponents/ag.components.module';
import { AuthModule } from 'src/app/auth/auth.module';
import { InputModule } from 'src/app/components/Inputs/input.module';
import { SearchBarModules } from 'src/app/components/search-bar/search-bar.modules';
import { ToasterModule } from 'src/app/components/toast/toast.module';

@NgModule({
    declarations: [SiteSelectionGridComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        ToasterModule,

        ModalModule.forRoot(),
        InputModule,
        SearchBarModules,
        AgComponentsModule,
        AuthModule,
        AgGridModule.withComponents([]),
    ],
    exports: [SiteSelectionGridComponent],
    providers: [NgContentInjectionService, LocationDataSourceService, LocationService],
    entryComponents: [SiteSelectionGridComponent],
})
export class SiteSelectionGridModules {}
