import { set } from 'lodash';

export function flattenObject(obj, key: any[] = [], keep: boolean = false) {
    const objectKeys = {};
    for (const i in obj) {
        const data = obj[i];
        const isObject = typeof data === 'object';
        key.push(i);

        if (isObject) {
            Object.assign(objectKeys, flattenObject(data, [key], true)['objectKeys']);
        } else {
            const _params: any = [];
            const _search = (keys: any[]) => {
                _params.push(
                    (keys
                        ? keys
                              .map((k) => {
                                  if (Array.isArray(k)) {
                                      _search(k);
                                      return null;
                                  } else return k;
                              })
                              .filter((a) => a)
                        : []
                    ).pop()
                );
            };
            _search(key);
            objectKeys[
                _params
                    .map((a) => (!isNaN(Number(a)) ? null : a))
                    .filter((a) => a)
                    .join('.')
            ] = data;
        }

        if (!keep) key = [];
    }

    return {
        objectKeys,
        obj,
    };
}

export function expandObject(obj, key = [], keep: boolean = false) {
    const objectKeys = {};
    Object.keys(obj).forEach((k) => set(objectKeys, k, obj[k]));
    return objectKeys;
}
