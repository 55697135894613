import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
export class AppTitle {
    private static _instance: AppTitle;
    onTitleName: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() appTitle: string = 'A <span class="font-weight-bold">Global Payments</span> Company';

    private subscriptions: Subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            AppTitle.Instance.onTitleName.subscribe((d) => {
                document.title = `Heartland | ${d}`;
                this.appTitle = `<span class="app-name">${d}</span>`;
            })
        );
    }
}
