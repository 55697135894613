/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InvoiceComponent } from './invoice.component';
import { UrlHelperService } from './url.helper.service';
import { HttpClientModule } from '@angular/common/http';
import { ProgressbarModule } from 'src/app/components/mdProgressBar/progressbar.module';

@NgModule({
    declarations: [InvoiceComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        ProgressbarModule,
        RouterModule.forChild([
            {
                path: '',
                component: InvoiceComponent,
            },
        ]),
    ],
    exports: [InvoiceComponent],
    providers: [UrlHelperService],
    entryComponents: [InvoiceComponent],
})
export class InvoiceModule {}
