import { AfterContentInit, Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { formValidate } from '../../../../services/form.validation.service';
import { get, set } from 'lodash';
import { EditlocationService } from '../../../modals/editlocation/editlocation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidateUser } from 'src/app/auth/validate.user.service';

@Component({
    selector: 'location-keys',
    templateUrl: './keys.component.html',
})
export class KeysComponent implements OnDestroy, AfterContentInit, OnChanges {
    @Input() readonly: boolean = true;
    subscriptions = new Subscription();

    public isSupport: boolean = false;
    public formControlKeyParams = [
        'hrposid',
        'salesforceID',
        'dealerCode',
        'retailID',
        'retailTenantID',
        'mobilemanagerID',
        'MID',
        'integrators.pointy.retailer_auth_key',
        'integrators.mobileManager.registrationNumber',
    ];

    public formGroup: FormGroup = new FormGroup({}, { updateOn: 'blur' });
    public locationResolver: any;

    constructor(
        private editlocationService: EditlocationService,
        private validateUser: ValidateUser,
        public dialogRef: MatDialogRef<KeysComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    toggleEditRule() {
        this.readonly = !this.readonly;
        this.changeFormState(this.readonly);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private simpleChangesTrigger(changes) {
        return changes.currentValue !== changes.previousValue;
    }

    private changeFormState(disabled) {
        this.formGroup[disabled ? 'disable' : 'enable']();

        this.disableController('_id');
        this.disableController('site_id');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.simpleChangesTrigger(changes['readonly'])) {
            this.changeFormState(this.readonly);
        }
    }

    private disableController(name, disable = true) {
        const ctrl = this.formGroup.controls[name];
        if (ctrl)
            ctrl[disable ? 'disable' : 'enable']({
                onlySelf: true,
                emitEvent: true,
            });
    }

    buildFormData(params) {
        this.formControlKeyParams.concat(['_id', 'site_id', 'domain', 'name']).forEach((k) => {
            const value = get(params, k, '');
            const ctrl = this.formGroup.controls[k];
            if (ctrl) {
                ctrl.setValue(value);
                ctrl[this.readonly ? 'disable' : 'enable']();
            } else this.formGroup.addControl(k, new FormControl({ value, disabled: this.readonly }));
        });

        this.changeFormState(this.readonly);
    }

    submit(e) {
        const rawData = this.formGroup.getRawValue();
        if (!formValidate(this.formGroup)) return;
        const params = {};
        Object.keys(rawData).forEach((k) => set(params, k, rawData[k]));

        this.editlocationService.updateLocation(params).subscribe((d) => this.dialogRef.close(params));
    }

    closeMe(e) {}

    ngAfterContentInit(): void {
        this.locationResolver = this.data['locationResolver'];
        this.isSupport = this.validateUser.hasRole(['API.LOCATION.EDIT']);
        this.buildFormData(this.locationResolver);
    }
}
