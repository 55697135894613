import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChevronDownComponent } from './chevron.down.component';
import { ChevronUpComponent } from './chevron.up.component';
import { ChevronLeftComponent } from './chevron.left.component';
import { ChevronRightComponent } from './chevron.right.component';

@NgModule({
    declarations: [ChevronDownComponent, ChevronRightComponent, ChevronLeftComponent, ChevronUpComponent],
    imports: [CommonModule],
    exports: [ChevronDownComponent, ChevronRightComponent, ChevronLeftComponent, ChevronUpComponent],
    providers: [],
    entryComponents: [ChevronDownComponent, ChevronRightComponent, ChevronLeftComponent, ChevronUpComponent],
})
export class ChevronModule {}
