import { Injectable } from '@angular/core';

@Injectable()
export class SortableService {
    setDragEvents(sortable: any, callback: (data) => void, _this) {
        let _sortEventsObject: any = {};

        sortable.on('drag:start', (e) => {
            _this.isDragging = true;
            const data: any = e.data;
            _sortEventsObject = {
                oldIndex: [],
            };

            Object.assign(_sortEventsObject, {
                sourceContainer: e.sourceContainer,
            });

            _this.onDragStart.emit(data);
        });

        sortable.on('sortable:sort', (e) => {
            _this.isDragging = true;
            const data: any = e.data;
            _sortEventsObject.oldIndex.push(data.currentIndex);
            _this.onSort.emit(data);
        });

        sortable.on('sortable:sorted', (e) => {
            _this.isDragging = false;
            const data: any = e.data;

            Object.assign(_sortEventsObject, {
                newContainer: e.newContainer,
                newIndex: data.newIndex,
            });
        });

        sortable.on('drag:stop', (e) => {
            _this.isDragging = false;
            const data: any = e.data;

            _sortEventsObject.oldIndex = _sortEventsObject.oldIndex[0];
            Object.assign(_sortEventsObject, {
                element: e.originalSource,
            });

            if (_sortEventsObject.newContainer && _sortEventsObject.sourceContainer)
                if (_sortEventsObject.newContainer.isEqualNode(_sortEventsObject.sourceContainer))
                    delete _sortEventsObject.newContainer;

            _this.onDragStop.emit(data);
            callback(_sortEventsObject);
        });
    }
}
